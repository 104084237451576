import { useState, useEffect, useCallback } from 'react'

// ----------------------------------------------------------------------

export default function useOffSetTop(top: number) {
	const [offsetTop, setOffSetTop] = useState(false)
	const isTop = top || 100

	const fn = useCallback(() => {
		if (window.pageYOffset > isTop) {
			setOffSetTop(true)
		} else {
			setOffSetTop(false)
		}
	}, [isTop])

	useEffect(() => {
		window.addEventListener('scroll', fn)
		return () => {
			window.removeEventListener('scroll', fn)
		}
	}, [fn, isTop])

	return offsetTop
}

// Usage
// const offset = useOffSetTop(100);
