// i18n
import './locales/i18n'

// hide firebase emulator
import './styles/firebase.css'

// highlight
// import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css'

// lightbox
// import 'react-image-lightbox/style.css';

// map
// import './utils/mapboxgl';
// import 'mapbox-gl/dist/mapbox-gl.css';

// editor
// import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import '@fontsource/averia-serif-libre' // Defaults to weight 400

import ReactDOM from 'react-dom/client'
// import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { Provider as ReduxProvider } from 'react-redux'
// import { PersistGate } from 'redux-persist/lib/integration/react'
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'

// redux
import { CustomTokensProvider } from 'src/contexts/CustomTokensContext'

import App from 'src/App'
import { CustomTokenProvider } from 'src/contexts/PfpContextSimple'

import { WalletsProvider } from 'src/contexts/WalletsContext'
import { PresentationContext, PresentationProvider } from 'src/contexts/PresentationContext'
import { SoundProvider } from 'src/Providers/SoundProvider'

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { store } from './redux/store'
// contexts
import { SettingsProvider } from './contexts/SettingsContext'
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext'

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

// import { AuthProvider } from './contexts/JWTContext'
// import { AuthProvider } from './contexts/Auth0Context';
import { AuthProvider } from './contexts/FirebaseContext'
// import { AuthProvider } from './contexts/AwsCognitoContext';

//
// import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { OurWeb3Provider } from './contexts/Web3Context'
import { Web3AuthProvider } from './Providers/Web3AuthProvider'
import { MixpanelProvider } from './contexts/MixpanelContext'
import ThemeProvider from './theme'
// components
import ThemeSettings from './components/settings'
// import { ChartStyle } from './components/chart'
// import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
import NotistackProvider from './components/NotistackProvider'
import MotionLazyContainer from './components/animate/MotionLazyContainer'
// ----------------------------------------------------------------------

// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

ReactDOM
	.createRoot(document.getElementById('root') as HTMLElement)
	.render(
		<MixpanelProvider>
			{/* <Web3AuthProvider> */}
			{/* <OurWeb3Provider> */}
			<AuthProvider>
				<HelmetProvider>
					<ReduxProvider store={store}>
						{/* <PersistGate loading={null} persistor={persistor}> */}
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<SettingsProvider>
								{/* <WalletsProvider> */}
								{/* <CustomTokensProvider> */}
								{/* <CustomTokenProvider> */}
								<CollapseDrawerProvider>
									{/* <BrowserRouter> */}

									<MotionLazyContainer>
										<ThemeProvider>
											<ThemeSettings>
												<PresentationProvider>
													{/* <AudioPlayerProvider> */}
													<SoundProvider>
														<NotistackProvider>
															<ProgressBarStyle />
															{/* <ChartStyle /> */}
															{/* <ScrollToTop /> */}
															<App />
														</NotistackProvider>
													</SoundProvider>
													{/* </AudioPlayerProvider> */}
												</PresentationProvider>
											</ThemeSettings>
										</ThemeProvider>
									</MotionLazyContainer>

									{/* </BrowserRouter> */}
								</CollapseDrawerProvider>
								{/* </CustomTokenProvider> */}
								{/* </CustomTokensProvider> */}
								{/* </WalletsProvider> */}
							</SettingsProvider>
						</LocalizationProvider>
						{/* </PersistGate> */}
					</ReduxProvider>
				</HelmetProvider>
			</AuthProvider>
			{/* </OurWeb3Provider> */}
			{/* </Web3AuthProvider> */}
		</MixpanelProvider>
		,
	)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
