import {
	enUS, frFR, zhCN, viVN, arSD,
} from '@mui/material/locale'
import { TFtueConfig } from 'src/types/Ftue'
import { SettingsValueProps } from './components/settings/type'
import { SocialLinks } from './components/SocialsButton'
import { PATH_DASHBOARD, PATH_PAGE } from './routes/paths'

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || ''

// export const localhostAlias = '192.168.7.47' // this does not work
// export const localhostAlias = 'localhost'
// export const localhostAlias = '127.0.0.1'

export const localhostAlias = 'localhost'
// export const localhostAlias = 'unreal.local' // this is the name of Jeff's development machine

// const preface = 'NFTs powered by ChatGPT'

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	// databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	// measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const HOME_ICON = 'mdi:cards'
export const PEOPLE_ICON = 'fluent:people-24-filled'

/* export const cognitoConfig = {
	userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
	clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
}

export const auth0Config = {
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
} */

// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.nfts // as '/dashboard/app'
// export const PATH_AFTER_LOGIN = '/dashboard/app'
export const PATH_AFTER_LOGIN = '/dashboard/nfts/browse'
// export const PATH_AFTER_LOGIN = '/nfts/browse'

// export const WHITELIST_CTA = 'Get Whitelisted'
export const BRAND = 'Dropify'
export const COMPANY = 'Dropify, Inc.'
export const WHITELIST_CTA = 'Whitelist My NFT'
export const START_CTA = 'Get Started'
export const TRY_NOW_CTA = 'Get the App'
export const SIGN_UP_NOW_CTA = 'Sign Up'
export const PREVIEW_STORY_ID_FOR_CHARACTER = 'UJwX0Fw6WxcT5M26Y350-6jchGuKCrjuOCH7daaJM?ftue=true'
export const PREVIEW_STORY_ID_FOR_NFT = 'UJwX0Fw6WxcT5M26Y350-6jchGuKCrjuOCH7daaJM?ftue=true'
export const SIGNIN_CTA = 'Sign In'
export const CONNECT_CTA = 'Connect Wallet'
export const CONNECT_NFT_CTA = 'Connect Your NFT'
// export const FOOTER_TEXT = 'Magically socialize with friends in imaginative ways 🙌'
// export const FOOTER_TEXT = 'Audio dramas with friends'
export const FOOTER_TEXT = 'Star in Epic Audio Dramas'

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX

export const googleAnalyticsConfig = process.env.REACT_APP_GOOGLE_ANALTYICS_ID

export const DEFAULT_STORY_SCHEMA = 'primitive-1'

export const FTUE_CONFIGS: Record<TFtueConfig, boolean> = {
	'ftue-isnew': true,
	'ftue-show-welcome-dialogue': true,
	'ftue-show-createavatar': true,
	'ftue-show-updateprofile': true,
	'ftue-show-welcome-card': true,
}

export const HEADER = {
	MOBILE_HEIGHT: 64,
	MAIN_DESKTOP_HEIGHT: 88,
	DASHBOARD_DESKTOP_HEIGHT: 92,
	DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
}

export const BACK_BAR = {
	MAIN_DESKTOP_HEIGHT: 40,
}

export const NAVBAR = {
	BASE_WIDTH: 260,
	DASHBOARD_WIDTH: 280,
	DASHBOARD_COLLAPSE_WIDTH: 88,
	//
	DASHBOARD_ITEM_ROOT_HEIGHT: 48,
	DASHBOARD_ITEM_SUB_HEIGHT: 40,
	DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
}

export const DRAWER_PULLER = {
	MARGIN_TOP: 8,
	HEIGHT: 6,
}

export const drawerHeightAdjustment = DRAWER_PULLER.HEIGHT + DRAWER_PULLER.MARGIN_TOP
export const geoPaternBaseColor = '#CA9FFD' // '#7118E2'// '#A35BFF'
export const promptChoiceHeight = 120
export const promptChoiceControlsHeight = 56
export const promptChoiceHeaderHeight = 56

export const ICON = {
	NAVBAR_ITEM: 22,
	NAVBAR_ITEM_HORIZONTAL: 20,
}

export const DESKTOP_DEMO_DEVICE = {
	width: 375,
	height: 812,
	// borderWidth: 8,
	borderWidth: 0,
	borderRadius: 40,
}

export const BOTTOM_NAVBAR = {
	// BASE_WIDTH: 260,
	// DASHBOARD_WIDTH: 280,
	// DASHBOARD_COLLAPSE_WIDTH: 88,
	//
	HEIGHT: 7 * 8,
}

export const THREADS_HEADER_NAV_BAR = {
	HEIGHT: 72,
}

export const INIT = {
	HEIGHT: 856,
	WIDTH: 375,
}

export const MESSAGES_BOX = {
	BOTTOM: 130,
}

export const HASHTAG_BOX = {
	BOTTOM: 62,
	HEIGHT: 24,
	LEFT: 16,
	RIGHT: 16,
}

export const STORIES = {
	MONIKER_HEIGHT: 68,
	SPINNER_SIZE: 74,
	SPINNER_ML: 16,
	SPINNER_MT: 0,
	MONIKER_BOX_BOTTOM: HASHTAG_BOX.BOTTOM + HASHTAG_BOX.HEIGHT,
	ACTIONS_PALETTE: {
		ACTION_ICON_SIZE: 64,
	},
}

export const smallIPhonePreview = 0.95

export const WATERMARK = {
	top: 24,
	left: 16,
	// top: SEARCH_BAR.top + SEARCH_BAR.height + 16,
	height: 20,
}

export const USERACTIONSPANEL = {
	top: WATERMARK.top + WATERMARK.height, // + 10,
	zIndex: 2000,
}

export const INFO_BUTTON_PANEL = {
	bottom: 100,
	zIndex: 1001,
}

export const SEARCH_BAR = {
	top: WATERMARK.top + WATERMARK.height,
	height: 50,
	zIndex: 1000,
}

export const STARTER = {
	top: SEARCH_BAR.top + SEARCH_BAR.height + WATERMARK.height + 16,
}

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
	// themeMode: 'dark',
	themeMode: 'light',
	themeDirection: 'ltr',
	themeContrast: 'default',
	themeLayout: 'horizontal',
	// themeColorPresets: 'default',
	themeColorPresets: 'airbnb',
	themeStretch: false,
}

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
	{
		label: 'English',
		value: 'en',
		systemValue: enUS,
		icon: '/assets/icons/flags/ic_flag_en.svg',
	},
	{
		label: 'French',
		value: 'fr',
		systemValue: frFR,
		icon: '/assets/icons/flags/ic_flag_fr.svg',
	},
	{
		label: 'Vietnamese',
		value: 'vn',
		systemValue: viVN,
		icon: '/assets/icons/flags/ic_flag_vn.svg',
	},
	{
		label: 'Chinese',
		value: 'cn',
		systemValue: zhCN,
		icon: '/assets/icons/flags/ic_flag_cn.svg',
	},
	{
		label: 'Arabic (Sudan)',
		value: 'ar',
		systemValue: arSD,
		icon: '/assets/icons/flags/ic_flag_sa.svg',
	},
]

export const defaultLang = allLangs[0] // English

export const siteData = {
	twitterHandle: 'dropify_app',
	twitterTitle: 'Dropify',
	twitterDescription: `${FOOTER_TEXT}.`, // 'An Interactive NFT',
	// twitterImageUrl: 'https://unreal-prod.imgix.net/www/ogImagev3.png?fm=jpg&q=75',
	// twitterImageUrl: `https://${process.env.REACT_APP_IMGIX_CDN_ROOT_URL}/static/brand/app_icon.png`,
	// twitterImageUrl: 'https://stickiesfundev.imgix.net/bodacious-bears/website/ogimage-twitter.png?fm=jpg&q=75',
	// twitterImageUrl: 'https://stickiesfundev.imgix.net/bodacious-bears/website/ogimage_2.png?fm=jpg&q=75',
	browserTabTitle: 'Dropify',
	siteTitle: 'Dropify',
	// collectionEzName: 'Bodacious Bear',
	// siteDescription: 'An Interactive NFT',
	// siteDescription: 'Nurture. Compete. Earn.',
	// siteDescription: 'NFTs that compete for fame and fortune.',
	// siteDescription: 'Say Hello to a New Breed of NFTs.',
	// siteDescription: `${preface}.`,
	// siteLongDescription: 'This collection of 10,000 stylistically curated, interactive NFTs delivers an elevated experience, powered by AI and a whole new level of social interactivity.',
	// siteLongDescription: '10,000 algorithmically generated NPC NFTs with needs, desires and personalities.',
	// siteLongDescription: 'A family of 10,000 algorithmically generated NFTs. When you own a Bodacious Bear, you\'ll have fun customizing your bear\'s interests and personality, while you raise it and watch your bear compete for fame and fortune.',
	// siteLongDescription: 'Say Hello to a New Generation of NFTs. Artificially Intelligent Virtual Pets that Entertain, Grow and Reproduce. Now Minting.',
	// siteLongDescription: 'Say Hello to a New Generation of NFTs. Artificially Intelligent Virtual Pets that Entertain, Grow and Reproduce. Now Minting.',
	// siteLongDescription: `${preface}. Now Minting.`,
	// npcShareDescription: 'I\'m one in a collection of 10k unique, algorithmically generated NPC NFTs with needs, desires and personalities.',
	// npcShareDescription: 'A New Breed of NFT. An Artificially Intelligent Pet. Come Train Me!',
	// npcShareDescription: 'An Elite NFT.',
	// npcShareDescription: '',
	// individualStickyDescription: 'Virtual Items with Value! By stickies.fun',
	// individualStickyDescription: 'NPC NFT',
	// Stickies are a great way to express yourself.
	// ogImageUrl: 'https://stickiesfundev.imgix.net/bodacious-bears/website/ogimage-sms.png?fm=jpg&q=75',
	// ogImageUrl: 'https://stickiesfundev.imgix.net/bodacious-bears/website/ogimage-twitter.png?fm=jpg&q=75',
	// ogImageUrl: 'https://stickiesfundev.imgix.net/bodacious-bears/website/ogimage_2.png?fm=jpg&q=75',
	// ogImageUrl: 'https://unreal-prod.imgix.net/www/ogImage.png?fm=jpg&q=75',
	// ogImageUrl: 'https://unreal-prod.imgix.net/www/ogImage.png',
	// ogImageUrl: 'https://unreal-prod.imgix.net/www/ogImagev3.png?fm=jpg&q=75',
	// ogImageUrl: 'https://dropify.fun/assets/images/og/ogImage.png?id=2',
	ogImageUrl: `https://${process.env.REACT_APP_IMGIX_CDN_ROOT_URL}/static/brand/og_default.png`,
	twitterImageUrl: `https://${process.env.REACT_APP_IMGIX_CDN_ROOT_URL}/static/brand/twitter_default.png`,
	howToVideo: 'https://youtube.com/shorts/xskBIewpswA?feature=share',
	collection: 'bb1',
	dAppUrlNoHttp: `${process.env.REACT_APP_DAPP_ROOT_URL_NO_HTTP}`,
	iOSAppUrl: '',
	iOSTestFlightUrl: 'https://testflight.apple.com/join/9o6qocZe',
	linkSentSignUpMessage: 'We sent a one-click sign-up link to',
	linkSentSignInMessage: 'We sent a one-click sign-in link to',
	bidLinkSentMessage: 'Check your email to secure your place in line.',
	finishPath: '/account/finish',
	finishBidPath: '/account/finishbid',
	finishSubscriptionPath: '/account/finishsubscription',
	shareButtonText: 'Share',
	bidButtonText: 'Make Bid',
	ownersDashboardUrl: 'https://my.bodaciousbears.com/',
	openSeaMarketplace: 'https://opensea.io/collection/bodaciousbears',
	twitterPage: 'https://twitter.com/unreal_fun',
	whitepaper: 'https://docs.unreal.fun/whitepaper',
	wikiForCreators: 'https://docs.unreal.fun/creators',
	wikiForCollectors: 'https://docs.unreal.fun/collectors',
	wikiNotion: 'https://unrealfun.notion.site',
	hubspotForm: 'https://share.hsforms.com/1bLdnUVt9SeawrmgL9kUITwdvm52',
	instagramPage: 'https://instagram.com/bodacious_bears',
	discordPage: 'https://discord.gg/BJRjEVMdZ9',
	// telegramPage: 'https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAxNzg3MTE3NjYy',
	// telegramPage: 'https://t.me/unrealfun',
	telegramPage: 'https://t.me/+nsz6hcYk03JlMmVh',
	wikiUrl: 'https://bodaciousbears.notion.site/Getting-Started-6b2e227b9f9e4e3fa63f85ca33046d66',
	wikiUrlRoot: 'https://bodaciousbears.notion.site',
	itemPrice: `${process.env.REACT_APP_BB1_PRICE} ETH`,
	siteRootUrl: `${process.env.REACT_APP_ROOT_HTTPS}`,
	avatarPath: 'contracts/bb1/avatar',
	contractAssetPath: 'contracts/bb1',
	// NFT_ROOT_ASSET_URL: 'https://stickiesfundev.imgix.net/bodacious-bears/avatars',
	NFT_ROOT_ASSET_URL: 'https://stickiesfundev.imgix.net', /// contracts/bb1/avatars',
	presaleSweepstakes: 'https://gleam.io/veawO/the-bodacious-bears-whitelist',
	partnershipForm: 'https://docs.google.com/forms/d/e/1FAIpQLSdBmQ8bqNcEEuhPL_5-nvIfvX36FnCFbRjTi5-brLNOTzGbcw/viewform',
	collectionsWhitepaper: 'https://1900445244-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2Fwb2szYC6EHwAERl7a2bz%2Fuploads%2FhJw0NS2Z2ZxEtOMk7xa0%2FCollectionCreators.pdf?alt=media&token=78b8363a-b7e5-4c72-a550-6c4b9607c139',
	smartContractUrl: 'https://etherscan.io',
	contracts: {
		bb1: process.env.REACT_APP_BB1_CONTRACT_ADDRESS,
	},
	/* api: {
		getNonceToSign: 'https://us-central1-ionic-angular-web3.cloudfunctions.net/getNonceToSign'
	} */
	appleAppStoreId: '1645581738',
	appleAppStoreUrl: 'https://apps.apple.com/us/app/id1645581738',
}

export const SOCIAL_LINKS:SocialLinks = {
	twitter: siteData.twitterPage,
	// telegram: siteData.telegramPage,
	// discord: siteData.discordPage,
}

export const algoliaConfigs = {
	apiKey: process.env.REACT_APP_ALGOLIA_API_KEY as string,
	appId: process.env.REACT_APP_ALGOLIA_APP_ID as string,
	primaryIndex: 'stories_feed_v1',
	byRankIndex: 'rank_desc',
	byNewestIndex: 'created_desc',
}

export const CharacterEditorForm = {
	TOOLTIP: {
		BIO: 'This is the public bio that appears on this character\'s profile page. Tip: Keep it short and powerful.',
		BIO_LONG: 'This is the bio that will be used when helping to generate this character\'s personality. Be as detailed as you wish or suggest that the information come from the internet or a certain tv show, etc.',
	},
	MAX_CHAR: {
		BIO: 200,
		BIO_LONG: 1000,
	},
}

export const StoryEditorForm = {
	HELP_TEXT: 'Tips',
	MAX_CHAR: {
		STARTER: 75,
		PLACEHOLDER: 28,
		ON_COMPLETION: 140,
		INFO_BOX: 250,
	},
	TOOLTIP: {
		ChatPrefill: 'Optional... instruct a first-time guest what they should type as a very first response. This text will appear in the chat box, as a suggestion. Pro Tip: Keep it very short!',
		MaxTriviaQuestions: 'Required... indicate how many questions this character should ask.',
		OnCompletion: 'Optional... indicate what the character should say on completion.',
	},
}

const SeeExamples = `Click "${StoryEditorForm.HELP_TEXT}" for examples.`

export const StoryEditorTrivia1 = {
	MAX_CHAR: {
		DATA_SOURCE: 5000,
	},
	TOOLTIP: {
		Starter: `A starter will help inform the guest, about the topic of this trivia game or can be a prompt to invite the guest to start the game. Get creative! ${SeeExamples}`,
		Information: `Optionally provide some information, to better help your guests understand the topic or anything else you'd like them to know. ${SeeExamples}`,
		DataSource: `This is the source of the trivia data. You can instruct your character to use known internet data and facts or provide the facts yourself. ${SeeExamples}`,
	},
}

export const StoryEditorTrivia2 = {
	MAX_CHAR: {
		INSTRUCTION: 10000,
	},
	TOOLTIP: {
		Starter: 'A starter will help inform the guest, about the topic of this trivia game or can be a prompt to invite the guest to start the game. Get creative!',
		SystemMessage: 'Insert your entire game logic and data source in here.',
		Information: 'Optionally provide some information about this trivia game, to better help your guests understand the topic or anything else you\'d like them to know.',
	},
}
