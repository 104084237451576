import { createSlice } from '@reduxjs/toolkit'
import {
	Unsubscribe,
} from 'firebase/firestore'
import { logger } from 'src/services/logger'
import { IThreadMessage, TThreadMessagesState } from 'src/types/ThreadMessages'
import { getDatabase, onValue, ref } from 'firebase/database'
import { dispatch } from '../store'
// ----------------------------------------------------------------------

// type TSetChoicePayload = {
// 	id: string
// 	status: 'PENDING' | 'COMPLETE' | 'IGNORED'
// 	selectedChoice: TThreadChoiceWithConfig
// }

type TSuccessPayload = {
	messages: Array<IThreadMessage>
	threadId: string
}

const initialState: TThreadMessagesState = {
	messagesByThreadId: {},
}

const slice = createSlice({
	name: 'thread-messages',
	initialState,
	reducers: {

		getSuccess(state, action) {
			const { messages, threadId }:TSuccessPayload = action.payload

			state.messagesByThreadId = {
				...state.messagesByThreadId,
				[threadId]: messages,
			}
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetThreadMessagesProps {
	threadId: string
}

export const listenForThreadMessages = ({ threadId }:GetThreadMessagesProps) => (): Unsubscribe | undefined => {
	try {
		logger.log('listenForThreadMessages')
		const db = getDatabase()
		const collectionRef = ref(db, `threads/${threadId}/thread_messages`)

		const unsubscribe: Unsubscribe = onValue(collectionRef, (querySnapshot) => {
			const messages:Array<IThreadMessage> = []
			if (querySnapshot.exists()) {
				logger.log('querySnapshot=', querySnapshot)
				querySnapshot.forEach((childSnapshot) => {
					const childKey = childSnapshot.key
					const message: Omit<IThreadMessage, 'id'> = childSnapshot.val() as Omit<IThreadMessage, 'id'>
					messages.push({ id: childKey, ...message })
				})

				dispatch(slice.actions.getSuccess({
					messages,
					threadId,
				}))
			}
		})

		return unsubscribe
	} catch (error) {
		logger.error(error)
	}
	return undefined
}
