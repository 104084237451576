import { Dispatch, createSlice } from '@reduxjs/toolkit'
import { IPromotion } from 'src/types/IPromotion'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ICustomTokenSpaceResponse, ISpacePublicProfile } from 'src/@types/space'
import {
	Timestamp, Unsubscribe, collection, getFirestore, limit, onSnapshot, query, where,
} from 'firebase/firestore'
import { logger } from 'src/services/logger'
import { SpacesState } from 'src/types/Spaces'
import { store } from '../store'
// @types

// ----------------------------------------------------------------------

type TSuccessPayload = {
	spaces: Array<ISpacePublicProfile>
}

const initialState: SpacesState = {
	isLoading: false,
	error: false,
	spaces: [],
	irlSpaces: [
		{
			id: '1',
			name: 'Home',
			iconifyIcon: 'solar:home-bold',
		},
		{
			id: '2',
			name: 'School',
			iconifyIcon: 'material-symbols:school',
		},
		{
			id: '3',
			name: 'Gym',
			iconifyIcon: 'mdi:gym',
		},
		{
			id: '4',
			name: 'Work',
			iconifyIcon: 'material-symbols:work',
		},
	],
}

const slice = createSlice({
	name: 'spaces',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		// GET PROMOTIONS
		getSpacesSuccess(state, action) {
			const { spaces }:TSuccessPayload = action.payload
			state.isLoading = false
			state.spaces = spaces
		},
	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getSpaces = () => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoading())
	try {
		logger.log('getSpaces')
		const db = getFirestore()
		const collectionRef = collection(db, 'space_public_profiles')
		const q = query(collectionRef, where('isReleased', '==', true), where('isPublic', '==', true), limit(100))
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			const spaces = querySnapshot.docs.map((doc) => {
				const space:ISpacePublicProfile = {
					id: doc.id,
					...doc.data() as Omit<ISpacePublicProfile, 'id'>,
				}

				return space
			})

			dispatch(slice.actions.getSpacesSuccess({
				spaces,
			}))
		})

		return unsubscribe
	} catch (error) {
		logger.error(error)
		dispatch(slice.actions.hasError(error))
	}
	return undefined
}
