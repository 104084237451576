/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, ElementType } from 'react'
import {
	Navigate, useRoutes, useLocation, createBrowserRouter, RouterProvider,
} from 'react-router-dom'
import { lazy } from 'src/utils/componentLoader'
import RNAuthGuard from 'src/guards/RNAuthGuard'
import GuestGuard from '../guards/GuestGuard'
import LoadingScreen from '../components/LoadingScreen'
import MainLayout from '../layouts/main'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
import useAuth from '../hooks/useAuth'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation()

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { isAuthenticated, user } = useAuth()
	const isAnonymous: boolean = isAuthenticated && !!user && user.isAnonymous

	const isDashboard = pathname.includes('/dashboard') && isAuthenticated && !isAnonymous

	return (
		<Suspense fallback={<LoadingScreen showProgressBar isDashboard={isDashboard} />}>
			<Component {...props} />
		</Suspense>
	)
}

// const CompanyAboutPage = Loadable(lazy(() => import('../pages/about/AboutGeneral')))

// const NftsAboutPage = Loadable(lazy(() => import('../pages/about/nfts/User')))
// const NftsCreatorsAboutPage = Loadable(lazy(() => import('../pages/about/nfts/Creator')))

// const CharactersAboutPage = Loadable(lazy(() => import('../pages/about/characters/User')))
const CharactersAboutPage = Loadable(lazy(() => import('../pages/Home')))
// const CharactersCreatorsAboutPage = Loadable(lazy(() => import('../pages/about/characters/Creator')))

// const CollectionsAboutPage = Loadable(lazy(() => import('../pages/CollectionsAboutPage')))

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')))
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')))
// const PushNotificationTester = Loadable(lazy(() => import('../pages/PushNotifs')))

// Authentication

// const AuthCallbackHandler = Loadable(lazy(() => import('../pages/auth/AuthCallbackHandler')))
// const ConnectWallet = Loadable(lazy(() => import('../pages/auth/ConnectWallet')))
// const ConnectWalletForImports = Loadable(lazy(() => import('../pages/dashboard/ConnectWalletForImports')))
// const GeneralWalletsPage = Loadable(lazy(() => import('../pages/dashboard/GeneralWalletsPage')))

// const DemosOverview = Loadable(lazy(() => import('../pages/DemosOverview')))

// const Chats = Loadable(lazy(() => import('../pages/demos-overview/foundations/FoundationChats')))
// const Spawns = Loadable(lazy(() => import('../pages/demos-overview/foundations/FoundationSpawns')))
// const Spawn = Loadable(lazy(() => import('../pages/demos/spawn')))

// const FeedMain = Loadable(lazy(() => import('../pages/feed/FeedMain2')))
// const FeedProfile = Loadable(lazy(() => import('../pages/feed/Profile_deprecate')))

// const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')))
// const Train = Loadable(lazy(() => import('../pages/dashboard/Train')))
// const Team = Loadable(lazy(() => import('../pages/Team')))
// const Try = Loadable(lazy(() => import('../pages/Try')))
// const Try2 = Loadable(lazy(() => import('../pages/Try2')))
// const Interact = Loadable(lazy(() => import('../pages/Interact')))
// const AiDemoOutside = Loadable(lazy(() => import('../pages/pfp/demoOutside')))
// const AiDemoInside = Loadable(lazy(() => import('../pages/pfp/demoInside')))

// const WebAppOutside = Loadable(lazy(() => import('../pages/webapp/outside')))
// const WebAppInside = Loadable(lazy(() => import('../pages/webapp/inside')))
// const WebAppStandAlone = Loadable(lazy(() => import('../pages/webapp/standAlone')))
// const TinderSwipeTest = Loadable(lazy(() => import('../pages/webapp/tinderSwipeTest')))
// const StoriesInsidePreview = Loadable(lazy(() => import('../pages/stories/preview')))

// const PlayTestsLearn = Loadable(lazy(() => import('../pages/playtests/Learn')))//
// const PlayTests = Loadable(lazy(() => import('../pages/playtests/PlayTests')))
// const PlayTest = Loadable(lazy(() => import('../pages/playtests/PlayTest')))

const Terms = Loadable(lazy(() => import('../pages/legal/Terms')))
const Privacy = Loadable(lazy(() => import('../pages/legal/Privacy')))
const UserProfile = Loadable(lazy(() => import('../pages/users/Profile')))
const StoryDetail = Loadable(lazy(() => import('../pages/stories/Detail')))

// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')))

// Defaults
const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const Page403 = Loadable(lazy(() => import('../pages/Page403')))
const Page404 = Loadable(lazy(() => import('../pages/Page404')))

// const Pricing = Loadable(lazy(() => import('../pages/PricingPage')))
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')))
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')))
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')))
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')))
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')))

// const MyNFTsBrowse = Loadable(lazy(() => import('../pages/dashboard/Browse')))
// const StoriesBrowse = Loadable(lazy(() => import('../pages/stories/inside')))
// const MyNFTsNFTConfigure = Loadable(lazy(() => import('../pages/nfts/BuildNFTsGeneral')))

// const BuildNFTGeneral = Loadable(lazy(() => import('../pages/nfts/BuildNFTsGeneral')))
// const BuildNFTGeneral2 = Loadable(lazy(() => import('../pages/nfts/BuildNFTsGeneral2')))
// const KanbanDemo = Loadable(lazy(() => import('../pages/about/nfts/KandanDemo')))
// const NFTEdit = Loadable(lazy(() => import('../pages/dashboard/CustomTokenEdit')))
// const StoriesListPage = Loadable(lazy(() => import('../pages/dashboard/StoriesListPage')))
// const StoriesCreatePage = Loadable(lazy(() => import('../pages/dashboard/StoriesCreatePage')))
// const StoriesEditPage = Loadable(lazy(() => import('../pages/dashboard/StoriesEditPage')))
const Contact = Loadable(lazy(() => import('../pages/support/Contact')))
const Guidelines = Loadable(lazy(() => import('../pages/support/Guidelines')))
// const Main:React.FC<{children:any}> = ({ children }) => <>{children}</>

export const router = createBrowserRouter([

	// {
	// 	path: 'auth',
	// 	children: [
	// 		{
	// 			path: 'connect', element: <Navigate to="/coming-soon" replace />, index: true,
	// 		},
	// 	],
	// },
	// Demo Routes
	/* 	{
			path: 'demos',
			element: (
				<AuthGuard>
					<MainLayout />
				</AuthGuard>
			),
			children: [
				// { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				// { path: PATH_DEMOS.root, element: <DemosOverview /> },
				// { path: PATH_DEMOS.general.chats, element: <Chats /> },
				// { path: PATH_DEMOS.general.spawns, element: <Spawns /> },
				// { path: PATH_DEMOS.spawn.root, element: <Spawn /> },
				// { path: 'booking', element: <GeneralBooking /> },
			],
		}, */

		    // Dashboard Routes

	// {
	// 	path: 'browse',
	// 	element: (
	// 		<AuthGuard>
	// 			<MainLayout>
	// 				<MyNFTsBrowse />
	// 			</MainLayout>
	// 		</AuthGuard>
	// 	),
	// },
	/* 	{
		path: 'nfts',
		element: (
			<AuthGuard>
				<BuildNoNavLayout />
			</AuthGuard>
		),
		children: [
			{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
			// { path: 'browse', element: <MyNFTsBrowse />, index: true },
			// { path: ':id/general', element: <BuildNFTGeneral /> },
			// { path: 'configure', element: <BuildNFTGeneral /> },
			// { path: 'personalize', element: <BuildNFTAboutMe /> },
			// { path: 'mods', element: <BuildNFTGeneral3 /> },
			// { path: 'stories/:customTokenId', element: <StoriesListPage /> },
			{ path: 'kanban', element: <KanbanDemo /> },
			// { path: ':id/configure', element: <BuildNFTGeneral /> },
		],
	}, */

	/* {
		path: 'user',
		element: (
			<AuthGuard>
				<BuildNoNavLayout />
			</AuthGuard>
		),
		children: [
			{ path: 'account', element: <UserAccount /> },
		],
	}, */
	/* {
		path: 'nfts/kanban',
		element: (
			<BuildNFTGeneral2 />
		),

	}, */
	// {
	// 	path: 'nfts/browse',
	// 	element: (
	// 		<AuthGuard>
	// 			<BuildNoNavLayout />
	// 		</AuthGuard>
	// 	),
	// 	children: [
	// 		{ element: <MyNFTsBrowse />, index: true },
	// 	],
	// },

	// Main Routes
	{
		path: '*',
		element: <LogoOnlyLayout />,
		children: [
			{ path: 'coming-soon', element: <ComingSoon /> },
			{ path: 'maintenance', element: <Maintenance /> },
			// { path: 'tos', element: <Terms /> },
			// { path: 'pricing', element: <Pricing /> },
			// { path: 'payment', element: <Payment /> },
			// { path: '500', element: <Page500 /> },
			  { path: '500', element: <Page500 /> },
			{ path: '404', element: <Page404 /> },
			{ path: '403', element: <Page403 /> },
			// { path: '403', element: <Page403 /> },
			{ path: '*', element: <Navigate to="/404" replace /> },
		],
	},
	 	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ element: <CharactersAboutPage />, index: true },
			// { path: 'team', element: <Team />, index: true },
			{ path: ':handle', element: <UserProfile />, index: true },
			{ path: 's/:storyId', element: <StoryDetail />, index: true },
		],
	},
	// {
	// 	path: '/:id',
	// 	element: <AIDemoOutsideLayout />,
	// 	children: [
	// 		{ element: <AiDemoOutside />, index: true },
	// 		// { path: ':id/render', element: <AiDemoInside /> },
	// 	],
	// },
	// {
	// 	path: '/',
	// 	element: <AIDemoOutsideLayout />,
	// 	children: [
	// 		// { element: <WebAppOutside />, index: true },
	// 		{ element: <PushNotificationTester />, index: true },
	// 		// { path: ':id/render', element: <AiDemoInside /> },
	// 	],
	// },
	// {
	// 	path: '/:storyId',
	// 	element: <AIDemoOutsideLayout />,
	// 	children: [
	// 		{ element: <WebAppOutside />, index: true },
	// 		// { path: ':id/render', element: <AiDemoInside /> },
	// 	],
	// },
	{
		path: 'legal',
		element: <MainLayout />,
		children: [
			{ path: 'terms', element: <Terms /> },
			{ path: 'privacy', element: <Privacy /> },
		],
	},

	// {
	// 	path: 'team',
	// 	element: <MainLayout />,
	// 	children: [
	// 		{ element: <Team />, index: true },
	// 	],
	// },
	// {
	// 	path: 'import',
	// 	element: <LowChromeLayout />,
	// 	children: [
	// 		{ element: <Try />, index: true },
	// 	],
	// },
	// {
	// 	path: 'try2',
	// 	element: <LowChromeLayout />,
	// 	children: [
	// 		{ element: <Try2 />, index: true },
	// 	],
	// },
	// {
	// 	path: 'try',
	// 	element: <MainLayout />,
	// 	children: [
	// 		{ element: <WhitelistSignup />, index: true },
	// 	],
	// },
	{
		path: 'support',
		element: <MainLayout />,
		children: [
			{ path: 'contact', element: <Contact /> },
			{ path: 'guidelines', element: <Guidelines /> },
		],
	},
	// {
	// 	path: '/',
	// 	element: <NewsLayout />,
	// 	children: [
	// 		{ element: <FeedMain />, index: true },
	// 		{ path: '/:handle', element: <FeedMain /> },
	// 		{ path: '/:handle/try', element: <Interact /> },
	// 	],
	// },
	// {
	// 	path: 'ai',
	// 	element: <AIDemoOutsideLayout />,
	// 	children: [
	// 		{ path: ':id', element: <AiDemoOutside /> },
	// 		// { path: ':id/render', element: <AiDemoInside /> },
	// 	],
	// },
	// {
	// 	path: 'ai-inside/:id',
	// 	element: <AiDemoInside />,
	// },
	/* {
		path: 'ai-inside',
		element: <AIDemoInsideLayout />,
		children: [
			{ path: ':id', element: <AiDemoInside /> },
		],
	}, */

	// { path: '/', element: <Navigate to="/maintenance" replace /> },
	{ path: '*', element: <Navigate to="/404" replace /> },
])

// 	return (
// 		<Routes>
// 			<Route path="/" element={<MainLayout />}>
// 				<Route index element={<LandingPage />} />
// 			</Route>
// 			<Route path="/" element={<LogoOnlyLayout />}>
// 				<Route path={PATH_PAGE.comingSoon} element={<ComingSoon />} />
// 				<Route path={PATH_PAGE.maintenance} element={<Maintenance />} />
// 			</Route>
// 			{/*  <Route path={PATH_AUTH.connectWallet}
//             element={
// 						<>
//               <GuestGuard>
//                 <ConnectWallet />
//               </GuestGuard>
// 							</>
//             }
//           />
//         </Route> */}
// 			<Route path="/*" element={<LogoOnlyLayout />}>
// 				<Route path="*" element={<NotFound />} />
// 			</Route>
// 		</Routes>
// 	)
// }

// IMPORT COMPONENTS

// Authentication

// const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')))
// Dashboard
// const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')))
// const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')))
// const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')))
// const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')))

// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')))

// const EcommerceProductList = Loadable(
// 	lazy(() => import('../pages/dashboard/EcommerceProductList')),
// )
// const EcommerceProductCreate = Loadable(
// 	lazy(() => import('../pages/dashboard/EcommerceProductCreate')),
// )
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')))
// const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')))
// const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')))
// const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')))
// const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')))
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')))
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')))
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')))
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')))
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')))

// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')))
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')))
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')))
// Main

// const Faqs = Loadable(lazy(() => import('../pages/Faqs')))
//

// const Pricing = Loadable(lazy(() => import('../pages/PricingPage')))
// const Payment = Loadable(lazy(() => import('../pages/Payment')))
// const Page500 = Loadable(lazy(() => import('../pages/Page500')))
// const NotFound = Loadable(lazy(() => import('../pages/Page404')))
// Components
// const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')))
// const Color = Loadable(
// 	lazy(() => import('../pages/components-overview/foundations/FoundationColors')),
// )
// const Typography = Loadable(
// 	lazy(() => import('../pages/components-overview/foundations/FoundationTypography')),
// )
// const Shadows = Loadable(
// 	lazy(() => import('../pages/components-overview/foundations/FoundationShadows')),
// )
// const Grid = Loadable(
// 	lazy(() => import('../pages/components-overview/foundations/FoundationGrid')),
// )
// const Icons = Loadable(
// 	lazy(() => import('../pages/components-overview/foundations/FoundationIcons')),
// )
// const Accordion = Loadable(
// 	lazy(() => import('../pages/components-overview/material-ui/Accordion')),
// )
// const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')))
// const Autocomplete = Loadable(
// 	lazy(() => import('../pages/components-overview/material-ui/Autocomplete')),
// )
// const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')))
// const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')))
// const Breadcrumb = Loadable(
// 	lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')),
// )
// const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')))
// const Checkbox = Loadable(
// 	lazy(() => import('../pages/components-overview/material-ui/Checkboxes')),
// )
// const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')))
// const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')))
// const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')))
// const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')))
// const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')))
// const Pagination = Loadable(
// 	lazy(() => import('../pages/components-overview/material-ui/Pagination')),
// )
// const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')))
// const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')))
// const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')))
// const RadioButtons = Loadable(
// 	lazy(() => import('../pages/components-overview/material-ui/RadioButtons')),
// )
// const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')))
// const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')))
// const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')))
// const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')))
// const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')))
// const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')))
// const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')))
// const Textfield = Loadable(
// 	lazy(() => import('../pages/components-overview/material-ui/textfield')),
// )
// const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')))
// const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')))
// const TransferList = Loadable(
// 	lazy(() => import('../pages/components-overview/material-ui/transfer-list')),
// )
// const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')))
// const DataGrid = Loadable(lazy(() => import('../pages/components-overview/material-ui/data-grid')))
// //
// const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')))
// const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')))
// const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')))
// const CopyToClipboard = Loadable(
// 	lazy(() => import('../pages/components-overview/extra/CopyToClipboard')),
// )
// const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')))
// const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')))
// const MultiLanguage = Loadable(
// 	lazy(() => import('../pages/components-overview/extra/MultiLanguage')),
// )
// const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')))
// const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')))
// const FormValidation = Loadable(
// 	lazy(() => import('../pages/components-overview/extra/form-validation')),
// )

// const NPCSettings = Loadable(lazy(() => import('../pages/dashboard/NPCSettings')))
// const NPCAnalytics = Loadable(lazy(() => import('../pages/dashboard/NPCAnalytics')))
// const NPCSocial = Loadable(lazy(() => import('../pages/dashboard/npc/NPCSocial')))
// const NPCAspirations = Loadable(lazy(() => import('../pages/dashboard/npc/Aspirations')))
// const NPCShopGifts = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')))
// const NPCUsage = Loadable(lazy(() => import('../pages/dashboard/NPCUsage')))
// const NPCProfile = Loadable(lazy(() => import('../pages/dashboard/NPCProfile')))
// const NPCPhraseCreate = Loadable(lazy(() => import('../pages/dashboard/NPCPhraseCreate')))
// const NPCPhraseList = Loadable(lazy(() => import('../pages/dashboard/NPCPhraseList')))
