/* eslint-disable no-console */

import { useCallback, useEffect, useState } from 'react'
import {
	doc, setDoc, getFirestore,
} from 'firebase/firestore'
// import z from 'zod'

import { logger } from 'src/services/logger'

// --------------------------------------------------------------------------

// export const routeSchemaValidator = z.object({
// 	message: z.string(),
// 	route: z.string(),
// })

declare global {
    interface Window {
        ReactNativeWebView:any
    }
}

type TEventMessage = { type: string; message: string; pushToken: string }
export interface IUseNativeBridgeHookOutput {
	requestPushPermission: () => void
	requestLogout: () => void
	// pushTokenId: string | null
	error: string | null
	status: string | null
 }

// interface Props {
// 	uid?: string
// }

const useNativeBridge = (): IUseNativeBridgeHookOutput => {
	// const [pushTokenId, setPushTokenId] = useState<string | null>(null)
	const [error, setError] = useState<string | null>(null)
	const [status, setStatus] = useState<string | null>(null)

	// const db = getFirestore()

	// const handler = useCallback(async (ev: any) => {
	// 	// logger.log('GOT MESSAGEEE')

	// 	if (uid) {
	// 		// we can only receive a string from ReactNative postMessage
	// 		if (typeof ev?.data !== 'string') return

	// 		// logger.log('uid=', uid)
	// 		setStatus((s) => `${s} - ${ev?.data}`)

	// 		try {
	// 			const obj:TEventMessage = JSON.parse(ev?.data)

	// 			const { type, message, pushToken } = obj
	// 			if (type === 'cmd') {
	// 			// logger.log('hook got a message=', data.message)

	// 				if (message === 'setPushToken') {
	// 					if (!pushToken) return

	// 					// logger.log('Setting push token id=', pushToken)
	// 					setPushTokenId(pushToken)
	// 					const ref = doc(db, 'users', uid)
	// 					await setDoc(ref, {
	// 						pushToken,
	// 					}, { merge: true })
	// 				}
	// 			}
	// 		} catch (err) {
	// 			// logger.error(err)
	// 		}
	// 	}
	// }, [uid, db])
	/*
	const handler = useCallback(async (ev: any) => {
		// logger.log('GOT MESSAGEEE')
		// logger.log('uid=', uid)
		setStatus((s) => `${s} - ${JSON.stringify(ev?.data)}`)
		try {
			if (typeof ev.data !== 'object') return

			const output = schemaValidator.parse(ev.data)

			const obj:TEventMessage = JSON.parse(ev.data)

			// if (uid) {

			if (!obj?.type) return
			if (obj?.type !== 'cmd') return
			if (!obj?.message) return

			logger.log('hook got a message=', obj?.message)

			if (obj?.message === 'setPushToken') {
				if (!obj?.pushToken) return
				const { pushToken } = obj

				logger.log('Setting push token id=', pushToken)
				setPushTokenId(pushToken)
				const ref = doc(db, 'users', uid)

				await setDoc(ref, {
					pushToken,
				}, { merge: true })
			}
		} catch (err) {
			logger.error(err)
		}
	}, [])

	useEffect(() => {
		// setStatus(null)
		logger.log('using NativeBridgeListener...')
		window.addEventListener('message', handler)
		// }

		// Don't forget to remove addEventListener
		return () => {
			// setStatus(null)
			logger.log('removing native bridge listener')
			window.removeEventListener('message', handler)
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	*/

	const requestPushPermission = useCallback(() => {
		setError(null)
		// setStatus(null)

		// if (!uid) {
		// 	setError('No uid found')
		// 	return
		// }

		if (!window?.ReactNativeWebView) {
			setError('Trying to postMessage but "window.ReactNativeWebView" Not Found')
			return
		}

		logger.log('postMessage to RN WebView requesting PushToken')
		window?.ReactNativeWebView?.postMessage(JSON.stringify({
			type: 'cmd',
			message: 'requestPushTokenPermission',
		}))
	}, [])

	const requestLogout = useCallback(() => {
		logger.log('requestLogout()')
		setError(null)
		// setStatus(null)

		// if (!uid) {
		// 	setError('requestLogout but no uid found')
		// 	return
		// }

		if (!window?.ReactNativeWebView) {
			setError('Trying to postMessage but "window.ReactNativeWebView" Not Found')
			return
		}

		logger.log('postMessage to RN WebView requesting logout')
		window?.ReactNativeWebView?.postMessage(JSON.stringify({
			type: 'cmd',
			message: 'logout',
		}))
	}, [])

	return {
		requestPushPermission,
		requestLogout,
		// pushTokenId,
		error,
		status,
	}
}

export default useNativeBridge
