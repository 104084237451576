import { createSlice, Dispatch } from '@reduxjs/toolkit'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, deleteDoc, FieldValue,
} from 'firebase/firestore'
import { TFollowingState } from 'src/types/Following'
import { logger } from 'src/services/logger'
import { IRelationship, IRelationshipFromDb, TRelationshipState } from 'src/types/Relationship'
import { relationships } from 'src/configs/Relationships'

// ----------------------------------------------------------------------

type TSuccessPayload = {
	relationship: IRelationship
	otherPersonUid: string
}

type TUpdateRelationPayload = {
	relationId: string
	otherPersonUid: string
}
type TUpdateLikesPayload = {
	likes: Array<string>
	otherPersonUid: string
}

type TUpdateScaredOfPayload = {
	scaredOf: Array<string>
	otherPersonUid: string
}

type TStartLoadingPayload = {
	otherPersonUid: string
}

type TFailureLoadingPayload = {
	otherPersonUid: string
}

const initialState: TRelationshipState = {
	relationshipByUid: {},
}

const slice = createSlice({
	name: 'relationship',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state, action) {
			const { otherPersonUid }: TStartLoadingPayload = action.payload
			state.relationshipByUid[otherPersonUid] = {
				status: {
					isLoading: true,
					error: false,
				},
				relationship: null,
			}
		},

		// HAS ERROR
		getFailure(state, action) {
			const { otherPersonUid }: TFailureLoadingPayload = action.payload
			state.relationshipByUid[otherPersonUid] = {
				status: {
					isLoading: false,
					error: true,
				},
				relationship: null,
			}
		},

		// GET PROMOTIONS
		getSuccess(state, action) {
			const { relationship, otherPersonUid }: TSuccessPayload = action.payload
			state.relationshipByUid[otherPersonUid] = {
				status: {
					isLoading: false,
					error: false,
				},
				relationship,
			}
		},

		updateRelation(state, action) {
			const { relationId, otherPersonUid }: TUpdateRelationPayload = action.payload
			state.relationshipByUid[otherPersonUid] = {
				...state.relationshipByUid[otherPersonUid],
				relationship: {
					...state.relationshipByUid[otherPersonUid].relationship,
					relationId,
				},
			}
		},

		updateLikes(state, action) {
			const { likes, otherPersonUid }: TUpdateLikesPayload = action.payload
			state.relationshipByUid[otherPersonUid] = {
				...state.relationshipByUid[otherPersonUid],
				relationship: {
					...state.relationshipByUid[otherPersonUid].relationship,
					likes,
				},
			}
		},

		updateScaredOf(state, action) {
			const { scaredOf, otherPersonUid }: TUpdateScaredOfPayload = action.payload
			state.relationshipByUid[otherPersonUid] = {
				...state.relationshipByUid[otherPersonUid],
				relationship: {
					...state.relationshipByUid[otherPersonUid].relationship,
					scaredOf,
				},
			}
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetRelationshipProps {
	uid: string
	otherPersonUid: string
}

export function getRelationship(props: GetRelationshipProps) {
	return async (dispatch: Dispatch) => {
		const { uid, otherPersonUid } = props
		dispatch(slice.actions.startLoading({ otherPersonUid }))
		try {
			// logger.log('getRelationship, props=', props)
			const db = getFirestore()
			const docRef = doc(db, 'users', uid, 'following', otherPersonUid)
			const querySnapshot = await getDoc(docRef)

			if (querySnapshot.exists()) {
				const data:IRelationshipFromDb = querySnapshot.data() as IRelationshipFromDb
				const {
					updatedAt, followerUid, uid, ...rest
				} = data

				const relationship: IRelationship = {
					...rest,
					...(!rest?.relationId && {
						relationId: '4', // default to friendship
					}),
				}

				// logger.log('relationship=', relationship)

				dispatch(slice.actions.getSuccess({
					otherPersonUid,
					relationship,
				}))
			}

			// else {
			// 	logger.warn('no relationship found')
			// 	dispatch(slice.actions.getSuccess({
			// 		otherPersonUid,
			// 		relationship: <IRelationship>{
			// 			relationId: '4', // default to friendship
			// 		},
			// 	}))
			// }
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFailure({ otherPersonUid }))
		}
	}
}

// ----------------------------------------------------------------------

interface SetRelationProps {
	uid: string
	otherPersonUid: string
	relationId: string
}
export function setRelation(props: SetRelationProps) {
	return async (dispatch: Dispatch) => {
		try {
			const { uid, otherPersonUid, relationId } = props
			dispatch(slice.actions.updateRelation({ otherPersonUid, relationId }))
			const db = getFirestore()
			const docRef = doc(db, 'users', uid, 'following', otherPersonUid)
			await setDoc(docRef, { relationId }, { merge: true })
		} catch (error) {
			logger.error(error)
		}
	}
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface SetLikesProps {
	uid: string
	otherPersonUid: string
	values: Array<string>
}
export function setLikes(props: SetLikesProps) {
	return async (dispatch: Dispatch) => {
		try {
			const { uid, otherPersonUid, values } = props
			logger.log('setLikes', props)
			dispatch(slice.actions.updateLikes({ otherPersonUid, likes: values }))
			const db = getFirestore()
			const docRef = doc(db, 'users', uid, 'following', otherPersonUid)
			await setDoc(docRef, { likes: values }, { merge: true })
		} catch (error) {
			logger.error(error)
		}
	}
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface SetScaredOfProps {
	uid: string
	otherPersonUid: string
	values: Array<string>
}

export function setScaredOf(props: SetScaredOfProps) {
	return async (dispatch: Dispatch) => {
		try {
			const { uid, otherPersonUid, values } = props
			logger.log('setScaredOf', props)
			dispatch(slice.actions.updateScaredOf({ otherPersonUid, scaredOf: values }))
			const db = getFirestore()
			const docRef = doc(db, 'users', uid, 'following', otherPersonUid)
			await setDoc(docRef, { scaredOf: values }, { merge: true })
		} catch (error) {
			logger.error(error)
		}
	}
}

// ----------------------------------------------------------------------
