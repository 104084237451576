import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// slices
import mailReducer from './slices/mail'
import chatReducer from './slices/chat'
import productReducer from './slices/product'
import tokenReducer from './slices/token'
// import customTokenReducer from './slices/customizedToken'
import calendarReducer from './slices/calendar'
import kanbanReducer from './slices/kanban'
import promotionReducer from './slices/promotion'
import feedReducer from './slices/feed'
import spaceReducer from './slices/space'
import spacesReducer from './slices/spaces'
import peopleReducer from './slices/people'
import followingReducer from './slices/following'
import followersReducer from './slices/followers'
import interestsReducer from './slices/interests'
// import promptsReducer from './slices/prompts'
import threadsReducer from './slices/threads'
import suggestedPfpReducer from './slices/suggestedPfp'
import cardStackReducer from './slices/cardStack'
import userReducer from './slices/user'
// import modReducer from './slices/mods'
import mod2Reducer from './slices/mods2'
import demoReducer from './slices/demo'
import effectReducer from './slices/effect'
import storyEditReducer from './slices/storyEdit'
import storyFeedReducer from './slices/storyFeed'
import customTokenReducer from './slices/customToken'
import webAppReducer from './slices/webapp'
// import readStatusReducer from './slices/readStatus'
// import promptSuggestionReducer from './slices/promptSuggestions'
import promptSuggestion2Reducer from './slices/promptSuggestions2'
import promptChoicesReducer from './slices/promptChoices'
import myStorySegmentsReducer from './slices/myStorySegments'
import feed2Reducer from './slices/feedv2'
import badgeReducer from './slices/badges'
import avatarReducer from './slices/avatars'
import relationshipReducer from './slices/relationship'
import peopleInterestsReducer from './slices/peopleInterests'
import threadMessagesReducer from './slices/threadMessages'

// import storyPreviewReducer from './slices/storyPreview'

// ----------------------------------------------------------------------

const rootPersistConfig = {
	key: 'root',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const promotionPersistConfig = {
	key: 'promotion',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const feedPersistConfig = {
	key: 'feed',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const customTokenPersistConfig = {
	key: 'custom-token',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const spacePersistConfig = {
	key: 'space',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const peoplePersistConfig = {
	key: 'people',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const followingPersistConfig = {
	key: 'following',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const followersPersistConfig = {
	key: 'followers',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const suggestedPfpPersistConfig = {
	key: 'suggestedPfp',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const cardStackPersistConfig = {
	key: 'promotion',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const userPersistConfig = {
	key: 'user',
	storage,
	keyPrefix: 'redux-',
	customTokens: [],
}

const effectPersistConfig = {
	key: 'effect',
	storage,
	keyPrefix: 'redux-',
	customTokens: [],
}

const promptsPersistConfig = {
	key: 'prompts',
	storage,
	keyPrefix: 'redux-',
	customTokens: [],
}

const promptSuggestionPersistConfig = {
	key: 'prompt-suggestions',
	storage,
	keyPrefix: 'redux-',
	customTokens: [],
}

/* const modsPersistConfig = {
	key: 'mods',
	storage,
	keyPrefix: 'redux-',
} */

/* const productPersistConfig = {
	key: 'product',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['sortBy', 'checkout'],
} */

/* const tokenPersistConfig = {
	key: 'token',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['sortBy'],
}

const customTokenPersistConfig = {
	key: 'customtoken',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['sortBy'],
} */

const rootReducer = combineReducers({
	mail: mailReducer,
	chat: chatReducer,
	calendar: calendarReducer,
	kanban: kanbanReducer,
	// product: persistReducer(productPersistConfig, productReducer),
	product: productReducer,
	// token: persistReducer(tokenPersistConfig, tokenReducer),
	token: tokenReducer,
	// customToken: persistReducer(customTokenPersistConfig, customTokenReducer),
	// customToken: customTokenReducer,
	promotion: persistReducer(promotionPersistConfig, promotionReducer),
	feed: persistReducer(feedPersistConfig, feedReducer),
	space: persistReducer(spacePersistConfig, spaceReducer),
	suggestedPfp: persistReducer(suggestedPfpPersistConfig, suggestedPfpReducer),
	cardStack: persistReducer(cardStackPersistConfig, cardStackReducer),
	customToken: persistReducer(customTokenPersistConfig, customTokenReducer),
	user: persistReducer(userPersistConfig, userReducer),
	people: persistReducer(peoplePersistConfig, peopleReducer),
	following: persistReducer(followingPersistConfig, followingReducer),
	followers: persistReducer(followersPersistConfig, followersReducer),
	// user: userReducer,
	// mods: modReducer,
	mods2: mod2Reducer,
	demo: demoReducer,
	storyEdit: storyEditReducer,
	spaces: spacesReducer,
	effect: persistReducer(effectPersistConfig, effectReducer),
	// promptSuggestions: persistReducer(promptSuggestionPersistConfig, promptSuggestionReducer),
	// promptSuggestions: promptSuggestionReducer,
	promptSuggestions2: promptSuggestion2Reducer,
	promptChoices: promptChoicesReducer,
	// prompts: persistReducer(promptsPersistConfig, promptsReducer),
	// prompts: promptsReducer,
	// storyFeed: persistReducer(storyFeedPersistConfig, storyFeedReducer),
	storyFeed: storyFeedReducer,
	webapp: webAppReducer,
	interests: interestsReducer,
	// readStatus: readStatusReducer,
	myStorySegments: myStorySegmentsReducer,
	feed2: feed2Reducer,
	badges: badgeReducer,
	relationship: relationshipReducer,
	peopleInterests: peopleInterestsReducer,
	avatars: avatarReducer,
	threads: threadsReducer,
	threadMessages: threadMessagesReducer,
	// storyPreview: storyPreviewReducer,
	// mods: persistReducer(modsPersistConfig, modReducer),
})

export { rootPersistConfig, rootReducer }
