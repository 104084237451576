import { TGender } from 'src/types/gender'

// export const defaultAvatarBackgroundColor = 'FFFFFF'
export const defaultAvatarBackgroundColor = 'F7AFAA'
export const avatarBackgroundColors:Array<string> = [
	defaultAvatarBackgroundColor,
	'FBD0CA',
	'FAE2E3',
	'FDE2C7',
	'FBF1CB',
	'E5EDC6',
	'E9F2E1',
	'C5E6F8',
	'C6D2EB',
	'DDC3DE',
]

export interface IAvatarImageSource {
	name: string
	gender: TGender
}
export const avatarImageNames: Array<IAvatarImageSource> = [
	{ name: 'M_1', gender: 'male' },
	{ name: 'M_2', gender: 'male' },
	{ name: 'M_3', gender: 'male' },
	// { name: 'M_4', gender: 'male' }, too female looking
	{ name: 'M_5', gender: 'male' },
	{ name: 'M_6', gender: 'male' },
	{ name: 'M_7', gender: 'male' },
	{ name: 'M_8', gender: 'male' },
	{ name: 'M_9', gender: 'male' },
	{ name: 'M_10', gender: 'male' },
	{ name: 'M_11', gender: 'male' },
	{ name: 'M_12', gender: 'male' },
	{ name: 'M_13', gender: 'male' },
	{ name: 'M_14', gender: 'male' },
	{ name: 'M_15', gender: 'male' },
	{ name: 'M_16', gender: 'male' },
	{ name: 'M_17', gender: 'male' },
	{ name: 'M_18', gender: 'male' },
	{ name: 'M_19', gender: 'male' },
	{ name: 'M_20', gender: 'male' },
	{ name: 'M_21', gender: 'male' },
	{ name: 'M_22', gender: 'male' },
	{ name: 'M_23', gender: 'male' },
	{ name: 'M_24', gender: 'male' },
	{ name: 'M_25', gender: 'male' },
	{ name: 'M_26', gender: 'male' },
	{ name: 'M_27', gender: 'male' },
	{ name: 'M_28', gender: 'male' },
	{ name: 'M_29', gender: 'male' },
	{ name: 'M_30', gender: 'male' },
	{ name: 'M_31', gender: 'male' },
	{ name: 'M_32', gender: 'male' },
	{ name: 'M_33', gender: 'male' },
	{ name: 'M_34', gender: 'male' },
	{ name: 'M_35', gender: 'male' },
	{ name: 'M_36', gender: 'male' },
	{ name: 'M_37', gender: 'male' },
	{ name: 'M_38', gender: 'male' },
	{ name: 'M_39', gender: 'male' },
	{ name: 'M_40', gender: 'male' },
	{ name: 'M_41', gender: 'male' },
	{ name: 'M_42', gender: 'male' },
	{ name: 'M_43', gender: 'male' },
	{ name: 'M_44', gender: 'male' },
	{ name: 'M_45', gender: 'male' },
	{ name: 'M_46', gender: 'male' },
	{ name: 'M_47', gender: 'male' },
	{ name: 'M_48', gender: 'male' },
	{ name: 'M_49', gender: 'male' },
	// { name: 'M_50', gender: 'male' }, too female looking
	//
	{ name: 'F-1', gender: 'female' },
	{ name: 'F-2', gender: 'female' },
	{ name: 'F-3', gender: 'female' },
	{ name: 'F-4', gender: 'female' },
	{ name: 'F-5', gender: 'female' },
	// { name: 'F-6', gender: 'female' }, missing file
	{ name: 'F-7', gender: 'female' },
	{ name: 'F-8', gender: 'female' },
	{ name: 'F-9', gender: 'female' },
	{ name: 'F-10', gender: 'female' },
	{ name: 'F-11', gender: 'female' },
	{ name: 'F-12', gender: 'female' },
	{ name: 'F-13', gender: 'female' },
	{ name: 'F-14', gender: 'female' },
	{ name: 'F-15', gender: 'female' },
	{ name: 'F-16', gender: 'female' },
	{ name: 'F-17', gender: 'female' },
	{ name: 'F-18', gender: 'female' },
	{ name: 'F-19', gender: 'female' },
	{ name: 'F-20', gender: 'female' },
	{ name: 'F-21', gender: 'female' },
	{ name: 'F-22', gender: 'female' },
	{ name: 'F-23', gender: 'female' },
	{ name: 'F-24', gender: 'female' },
	{ name: 'F-25', gender: 'female' },
	{ name: 'F-26', gender: 'female' },
	{ name: 'F-27', gender: 'female' },
	{ name: 'F-28', gender: 'female' },
	{ name: 'F-29', gender: 'female' },
	{ name: 'F-30', gender: 'female' },
	{ name: 'F-31', gender: 'female' },
	{ name: 'F-32', gender: 'female' },
	{ name: 'F-33', gender: 'female' },
	{ name: 'F-34', gender: 'female' },
	{ name: 'F-35', gender: 'female' },
	{ name: 'F-36', gender: 'female' },
	{ name: 'F-37', gender: 'female' },
	{ name: 'F-38', gender: 'female' },
	{ name: 'F-39', gender: 'female' },
	{ name: 'F-40', gender: 'female' },
	{ name: 'F-41', gender: 'female' },
	{ name: 'F-42', gender: 'female' },
	{ name: 'F-43', gender: 'female' },
	{ name: 'F-44', gender: 'female' },
	{ name: 'F-45', gender: 'female' },
	{ name: 'F-46', gender: 'female' },
	{ name: 'F-47', gender: 'female' },
	{ name: 'F-48', gender: 'female' },
	{ name: 'F-49', gender: 'female' },
	{ name: 'F-50', gender: 'female' },

]
