import { createSlice } from '@reduxjs/toolkit'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, deleteDoc, FieldValue, orderBy, collectionGroup, deleteField, limitToLast,
} from 'firebase/firestore'
import { logger } from 'src/services/logger'
import {
	 TBackstory, TThreadChoiceWithConfig,
} from 'src/types/Prompts'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
	 groupBy, keyBy,
} from 'lodash'

import {
	IThread, TThreadsState,
} from 'src/types/Threads'
import { firestoreToIThread } from 'src/utils/threads'
import { TFeedItemType } from 'src/types/Feed'
import { IPerson } from 'src/@types/people'
import { dispatch } from '../store'
// ----------------------------------------------------------------------

// type TSetChoicePayload = {
// 	id: string
// 	status: 'PENDING' | 'COMPLETE' | 'IGNORED'
// 	selectedChoice: TThreadChoiceWithConfig
// }

type TSuccessPayload = {
	threads: Array<IThread>
	uid: string
}

type TSetActiveThreadPayload = {
	threadId: string
}

type TSetNewRepromptThreadIdPayload = {
	threadId: string
}

// type TThreadByIdSuccessPayload = {
// 	thread: IThread
// 	uid: string
// }

type TDiscardThreadPayload = {
	threadId: string
	uid: string
}

type TUpdateThreadPayload = {
	threadId: string
	lastMessageByUid: string
	// summaryStatus: TThreadSummaryStatus
	// status: TThreadStatus
}

// type TClearBackstoryPayload = {
// 	messageId: string
// 	threadId: string
// }

// type TBackstoriesSuccessPayload = {
// 	messageId: string
// 	threadId: string
// 	backstories: Array<TBackstory>
// }

// type TStartLoadingBackstoriesPayload = {
// 	messageId: string
// 	promptId: string
// 	threadId: string
// }

// type TBackstoriesLoadingFailurePayload = {
// 	promptId: string
// 	threadId: string
// }

// type TSetBackstoryReactionPayload = {
// 	reactionId: string | null
// 	threadId: string
// 	messageId: string
// }

// //

// type TSummarySuccessPayload = {
// 	threadId: string
// 	summary: string | null
// }

// type TSummaryv3SuccessPayload = {
// 	threadId: string
// 	summary: IThreadSummary | null
// }

// type TStartLoadingSummaryPayload = {
// 	threadId: string
// }

// type TSummaryLoadingFailurePayload = {
// 	threadId: string
// }

// type SetLastMessagePayload = {
// 	threadId: string
// 	lastMessageByUid: string
// }

//

const initialState: TThreadsState = {
	isLoading: false,
	isLoadingChoices: false,
	error: false,
	errorLoadingThreadId: false,
	threads: [],
	threadByThreadId: {},
	threadsByPartnerUid: {},
	// promptsPromptedByUid: {},
	// backstoriesByThreadId: {},
	summaryByThreadId: {},
	activeThreadId: null,
	unansweredThreadsCount: null,
	newRepromptThreadId: null,
}

const slice = createSlice({
	name: 'threads',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.error = false
		},

		// START LOADING
		startLoadingChoices(state) {
			state.isLoadingChoices = true
			// state.error = false
		},

		// HAS ERROR
		getFailure(state) {
			state.isLoading = false
			state.error = true
		},

		// setFailedToLoadThreadId(state, action) {
		// 	const { threadId }:TFailedToLoadThreadIdPayload = action.payload
		// 	state.errorLoadingThreadId = true
		// },

		// setStartToLoadThreadId(state, action) {
		// 	const { threadId }:TStartToLoadThreadIdPayload = action.payload
		// 	state.errorLoadingThreadId = false
		// },

		// GET PROMOTIONS
		getSuccess(state, action) {
			const { threads, uid }:TSuccessPayload = action.payload
			state.isLoading = false
			state.error = false

			/*
			const localThreadIds:Array<string> = state.threads.map((thread) => thread.id)
			const newThreadIds:Array<string> = threads.map((thread) => thread.id)

			const deletedThreadIds:Array<string> = localThreadIds.filter((localThreadId) => !newThreadIds.includes(localThreadId))

			if (deletedThreadIds.length !== 0) {
				deletedThreadIds.forEach((deletedThreadId) => {
					const idx = state.threads.findIndex((item) => item.id === deletedThreadId)
					if (idx >= 0) {
						state.threads.splice(idx, 1)
					}
				})
			}

			state.threads = [
				...state.threads,
				...threads,
			]
*/

			// const threadIds:Array<string> = state.threads.map((thread) => thread.id)

			// const newThreadsThatAreNotDupsAndNotDeleted:Array<IThread> = threads.filter((thread) => !threadIds.includes(thread.id) && !thread.isDeleted)
			// const threadsNotDeleted:Array<IThread> = threads.filter((thread) => !thread.isDeleted)

			// if (newThreadsThatAreNotDupsAndNotDeleted.length !== 0) {
			// 	state.threads = [
			// 		...newThreadsThatAreNotDupsAndNotDeleted, // we insert the newly arrived prompts at the top of the card stack.  Note they should arrive in date desc order, so newest should technically be on top
			// 		...state.threads,
			// 	]
			// }

			// filter out reprompts that are still in initialization state from the clearBackstory
			// const allThreadsExcludingUninitializedReprompts:Array<IThread> = threads.filter((thread) => !((thread.status === 'INITIALIZING_REPROMPT') && (thread.createdByUid !== uid)))

			// state.threads = threads
			// state.threads = threads.filter((thread) => !((thread.status === 'INITIALIZING_REPROMPT') && (thread.createdByUid !== uid)))
			// state.threads = threads.filter((thread) => thread.status === 'READY' || ((<Array<TThreadStatus>>['INITIALIZING_REPROMPT', 'INITIALIZING_PROMPT']).includes(thread.status) && (thread.createdByUid === uid)))

			state.threads = threads
			state.threadsByPartnerUid = groupBy(state.threads, (thread) => thread.partnerUid)
			state.threadByThreadId = keyBy(state.threads, 'id')
			state.unansweredThreadsCount = state.threads.filter((thread) => (thread.lastMessageByUid !== uid)).length

			state.threads.forEach((thread) => {
				state.summaryByThreadId[thread.id] = {
					...(state.summaryByThreadId?.[thread.id] ?? {
						isLoading: false,
						isLoaded: false,
						error: false,
						summary: null,
					}),
				}
			})
		},

		// getThreadByIdSuccess(state, action) {
		// 	const { thread, uid }:TThreadByIdSuccessPayload = action.payload

		// 	const threadIds:Array<string> = state.threads.map((thread) => thread.id)
		// 	const alreadyExistsInArray:boolean = threadIds.includes(thread.id)

		// 	if (!alreadyExistsInArray) {
		// 		logger.log('does not exist so adding it')
		// 		state.threads = [
		// 			...state.threads,
		// 			thread,
		// 		]

		// 		// state.promptsPromptedByUid = groupBy(state.prompts, (item) => item.prompters?.[0]?.uid ?? 'crappola')
		// 		state.threadsByPartnerUid = groupBy(state.threads, (item) => item.partnerUid)
		// 		state.unansweredThreadsCount = state.threads.filter((thread) => thread.lastMessageByUid !== uid).length
		// 	}

		// 	state.isLoading = false
		// 	state.error = false
		// },

		// getBackstoriesSuccess(state, action) {
		// 	const { threadId, backstories }:TBackstoriesSuccessPayload = action.payload
		// 	state.backstoriesByThreadId = {
		// 		...state.backstoriesByThreadId,
		// 		[threadId]: backstories,
		// 	}
		// },

		// clearBackstory(state, action) {
		// 	const { messageId, threadId }:TClearBackstoryPayload = action.payload
		// 	if (state.backstoriesByThreadId?.[threadId]?.basedOnMessageId !== messageId) {
		// 		state.backstoriesByThreadId[threadId] = {
		// 			isLoading: false,
		// 			isLoaded: false,
		// 			error: false,
		// 			backstories: [],
		// 			basedOnMessageId: messageId,
		// 		}
		// 	}
		// },

		// getBackstoriesSuccess(state, action) {
		// 	const { messageId, threadId, backstories }:TBackstoriesSuccessPayload = action.payload
		// 	state.backstoriesByThreadId[threadId] = {
		// 		isLoading: false,
		// 		isLoaded: true,
		// 		error: false,
		// 		backstories,
		// 		basedOnMessageId: messageId,
		// 	}
		// },

		// startLoadingBackstories(state, action) {
		// 	const { messageId, promptId, threadId }:TStartLoadingBackstoriesPayload = action.payload
		// 	state.backstoriesByThreadId[threadId] = {
		// 		isLoading: true,
		// 		isLoaded: false,
		// 		error: false,
		// 		backstories: [],
		// 		basedOnMessageId: messageId,
		// 	}
		// },

		// backstoriesLoadingFailure(state, action) {
		// 	const { promptId, threadId }:TBackstoriesLoadingFailurePayload = action.payload
		// 	state.backstoriesByThreadId[threadId] = {
		// 		...state.backstoriesByThreadId[threadId],
		// 		isLoading: false,
		// 		isLoaded: false,
		// 		error: true,
		// 	}
		// },

		// setBackstoryReaction(state, action) {
		// 	const { reactionId, messageId, threadId }:TSetBackstoryReactionPayload = action.payload

		// 	const idx = state.backstoriesByThreadId?.[threadId]?.backstories?.findIndex((item) => item.messageId === messageId)

		// 	if (idx >= 0) {
		// 		state.backstoriesByThreadId[threadId].backstories[idx].reactionId = !reactionId ? undefined : reactionId
		// 	}
		// },

		// startLoadingSummary(state, action) {
		// 	const { threadId }:TStartLoadingSummaryPayload = action.payload
		// 	state.summaryByThreadId[threadId] = {
		// 		isLoading: true,
		// 		isLoaded: false,
		// 		error: false,
		// 		summary: null,
		// 	}
		// },

		// summaryLoadingFailure(state, action) {
		// 	const { threadId }:TSummaryLoadingFailurePayload = action.payload
		// 	state.summaryByThreadId[threadId] = {
		// 		...state.summaryByThreadId[threadId],
		// 		isLoading: false,
		// 		isLoaded: false,
		// 		error: true,
		// 	}
		// },

		// getSummarySuccess(state, action) {
		// 	const { threadId, summary }:TSummarySuccessPayload = action.payload
		// 	state.summaryByThreadId[threadId] = {
		// 		isLoading: false,
		// 		isLoaded: true,
		// 		error: false,
		// 		summary,
		// 	}
		// },

		// startLoadingSummary(state, action) {
		// 	const { threadId }:TStartLoadingSummaryPayload = action.payload
		// 	state.summaryByThreadId[threadId] = {
		// 		isLoading: true,
		// 		isLoaded: false,
		// 		error: false,
		// 		summary: null,
		// 	}
		// },

		// summaryLoadingFailure(state, action) {
		// 	const { threadId }:TSummaryLoadingFailurePayload = action.payload
		// 	state.summaryByThreadId[threadId] = {
		// 		...state.summaryByThreadId[threadId],
		// 		isLoading: false,
		// 		isLoaded: false,
		// 		error: true,
		// 	}
		// },

		// getSummarySuccess(state, action) {
		// 	const { threadId, summary }:TSummaryv3SuccessPayload = action.payload

		// 	// logger.log(`Updating Thread Summary for threadId=${threadId}, with summary=${JSON.stringify(summary)}`)

		// 	state.summaryByThreadId[threadId] = {
		// 		isLoading: false,
		// 		isLoaded: true,
		// 		error: false,
		// 		summary,
		// 	}
		// },

		discardThread(state, action) {
			const { threadId, uid }:TDiscardThreadPayload = action.payload
			const idx = state.threads.findIndex((item) => item.id === threadId)
			if (idx >= 0) {
				state.threads.splice(idx, 1)
				state.threadsByPartnerUid = groupBy(state.threads, (item) => item.partnerUid)
				state.threadByThreadId = keyBy(state.threads, 'id')
				state.unansweredThreadsCount = state.threads.filter((thread) => (thread.lastMessageByUid !== uid)).length
			}
		},

		updateThread(state, action) {
			const {
				threadId, lastMessageByUid: uid, // summaryStatus, status,
			}:TUpdateThreadPayload = action.payload

			const idx = state.threads.findIndex((item) => item.id === threadId)
			if (idx >= 0) {
				state.threads[idx].lastMessageByUid = uid
				// state.threads[idx].summaryStatus = summaryStatus
				// state.threads[idx].status = status
				state.threadsByPartnerUid = groupBy(state.threads, (item) => item.partnerUid)
				state.threadByThreadId = keyBy(state.threads, 'id')
				state.unansweredThreadsCount = state.threads.filter((thread) => (thread.lastMessageByUid !== uid)).length
			}
		},
		// purgeNonPendingPrompts(state) {
		// 	const ignoredOrCompletedPrompts:Array<IPrompt> = state.prompts.filter((prompt) => prompt.status !== 'PENDING')
		// 	const promptIds:Array<string> = ignoredOrCompletedPrompts.map((prompt) => prompt.id) ?? []

		// 	promptIds.forEach((id) => {
		// 		const idx = state.prompts.findIndex((item) => item.id === id)
		// 		if (idx >= 0) {
		// 			state.prompts.splice(idx, 1)
		// 		}
		// 	})

		// 	// state.promptsPromptedByUid = groupBy(state.prompts, (item) => item.prompters?.[0]?.uid ?? 'crappola')
		// 	state.promptsPromptedByUid = groupBy(state.prompts, (item) => item.from.uid)
		// },

		// Remove FOLLOWING
		// setStatus(state, action) {
		// 	const { threadId, lastMessageByUid }: SetLastMessagePayload = action.payload
		// 	const idx = state.threads.findIndex((thread) => thread.id === threadId)
		// 	if (idx >= 0) {
		// 		state.threads[idx].lastMessageByUid = lastMessageByUid
		// 		// state.promptsByUid = groupBy(state.prompts, (item) => item.actors[0].uid)

		// 		// state.promptsPromptedByUid = groupBy(state.prompts, (item) => item.prompters?.[0]?.uid ?? 'crappola')
		// 		state.threadsByPartnerUid = groupBy(state.threads, (item) => item.partnerUid)
		// 	}
		// },

		// setDismissed(state, action) {
		// 	const { id } = action.payload
		// 	const idx = state.prompts.findIndex((prompt) => prompt.id === id)
		// 	if (idx >= 0) {
		// 		state.prompts[idx].dismissed = true
		// 		// state.promptsByUid = groupBy(state.prompts, (item) => item.actors[0].uid)

		// 		// state.promptsPromptedByUid = groupBy(state.prompts, (item) => item.prompters?.[0]?.uid ?? 'crappola')
		// 		state.promptsPromptedByUid = groupBy(state.prompts, (item) => item.from.uid)
		// 	}
		// },

		// setChoice(state, action) {
		// 	const { id, status, selectedChoice }: TSetChoicePayload = action.payload
		// 	const idx = state.prompts.findIndex((prompt) => prompt.id === id)
		// 	if (idx >= 0) {
		// 		state.prompts[idx] = {
		// 			...state.prompts[idx],
		// 			status,
		// 			selectedChoice,
		// 		}
		// 		// state.promptsByUid = groupBy(state.prompts, (item) => item.actors[0].uid)

		// 		// state.promptsPromptedByUid = groupBy(state.prompts, (item) => item.prompters?.[0]?.uid ?? 'crappola')
		// 		state.promptsPromptedByUid = groupBy(state.prompts, (item) => item.from.uid)
		// 	}
		// },

		failedLoadingChoices(state) {
			state.isLoadingChoices = false
		},

		setActiveThread(state, action) {
			const { threadId }: TSetActiveThreadPayload = action.payload
			state.activeThreadId = threadId
		},

		setNewRepromptThreadId(state, action) {
			const { threadId }: TSetNewRepromptThreadIdPayload = action.payload
			state.newRepromptThreadId = threadId
		},
	},
})

// Actions
export const {
	setActiveThread,
} = slice.actions

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetThreadsProps {
	uid: string
}

export const getThreads = ({ uid }:GetThreadsProps) => (): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoading())
	try {
		logger.log('getThreads')
		const db = getFirestore()
		const collectionRef = collection(db, 'threads')
		const q = query(
			collectionRef,
			where('isDeleted', '==', false),
			where('uids', 'array-contains', uid),
			orderBy('updatedAt', 'desc'),
		)

		const unsubscribe: Unsubscribe = onSnapshot(q, async (querySnapshot) => {
			const threads:Array<IThread> = querySnapshot.docs.map((doc) => firestoreToIThread(doc, uid))

			// logger.log('threads=', threads)

			dispatch(slice.actions.getSuccess({
				uid,
				threads,
			}))

			// threads.forEach((thread) => {
			// 	// logger.log('CLEARING BACKSTORY', prompt.threadId)

			// 	dispatch(slice.actions.clearBackstory({
			// 		threadId: thread.id,
			// 	}))
			// })

			// 	await Promise.all(threads.map(async (thread) => {
			// 		if (thread?.lastMessageId) {
			// 			const summariesCollectionRef = collection(db, 'threads', thread.id, 'thread_summaries')
			// 			const summariesQuery = query(
			// 				summariesCollectionRef,
			// 				// where('isDeleted', '==', false),
			// 				where('intendedReaderUid', '==', uid),
			// 				where('messageId', '==', thread.lastMessageId),
			// 				orderBy('createdAt', 'asc'),
			// 				limitToLast(1),
			// 			)

			// 			const summariesQuerySnapshot = await getDocs(summariesQuery)

		// 			if (summariesQuerySnapshot.docs.length === 1) {
		// 				const summary:IThreadSummary = firestoreToIThreadSummary(summariesQuerySnapshot.docs[0])
		// 				 dispatch(slice.actions.getSummarySuccess({
		// 					threadId: thread.id,
		// 					summary,
		// 				}))
		// 			}
		// 			// else {
		// 			// 	throw Error(`yikes, no summary found for this thread id=${thread.id}`)
		// 			// }
		// 			// else {
		// 			// 	 dispatch(slice.actions.getSummarySuccess({
		// 			// 		threadId: thread.id,
		// 			// 		summary: null,
		// 			// 	}))
		// 			// }
		// 		}
		// 		return Promise.resolve()
		// 	}))
		})

		return unsubscribe
	} catch (error) {
		dispatch(slice.actions.getFailure())
	}
	return undefined
}

// ----------------------------------------------------------------------

// interface GetThreadByIdProps {
// 	threadId: string
// 	uid: string
// }

// export function getThreadById({ threadId, uid }:GetThreadByIdProps) {
// 	return async () => {
// 		dispatch(slice.actions.setStartToLoadThreadId({threadId}))
// 		try {
// 			logger.log('getThreadById', threadId)
// 			const db = getFirestore()
// 			const docRef = doc(db, 'threads', threadId)

// 			const querySnapshot = await getDoc(docRef)

// 			if (!querySnapshot.exists()) {
// 				throw Error('Thread id not found')
// 			}

// 			const thread:IThread = firestoreToIThread(querySnapshot, uid)

// 			dispatch(slice.actions.getThreadByIdSuccess({
// 				thread,
// 			}))

// 			dispatch(slice.actions.clearBackstory({
// 				threadId: thread.id,
// 			}))
// 		} catch (error) {
// 			logger.error(error)
// 			dispatch(slice.actions.setFailedToLoadThreadId({threadId}))
// 		}
// 	}
// }

// ----------------------------------------------------------------------

// interface IgnoreProps {
// 	uid: string
// 	promptId: string
// }
// export function ignorePrompt(props: IgnoreProps) {
// 	return async () => {
// 		const { uid, promptId } = props
// 		const status = 'IGNORED'
// 		dispatch(slice.actions.setStatus({ id: promptId, status }))
// 		const db = getFirestore()
// 		const docRef = doc(db, 'users', uid, 'prompts', promptId)
// 		await setDoc(docRef, { status }, { merge: true })
// 	}
// }

// ----------------------------------------------------------------------

// interface MarkAsCompleteProps {
// 	uid: string
// 	promptId: string
// }
// export function markPromptAsComplete(props: MarkAsCompleteProps) {
// 	return async () => {
// 		const { uid, promptId } = props
// 		const status = 'COMPLETE'
// 		dispatch(slice.actions.setStatus({ id: promptId, status }))
// 		const db = getFirestore()
// 		const docRef = doc(db, 'users', uid, 'prompts', promptId)
// 		await setDoc(docRef, { status }, { merge: true })
// 	}
// }

// ----------------------------------------------------------------------

export interface ReplyToThreadProps {
	// fromUid: string
	// toUid: string
	from: IPerson
	to: IPerson
	choice: TThreadChoiceWithConfig
	threadId: string
}
export function replyToThread(props: ReplyToThreadProps) {
	return async () => {
		try {
			logger.log('replyToThread, props=', props)
			const {
				from, threadId,
			} = props

			dispatch(slice.actions.updateThread({
				threadId,
				lastMessageByUid: from.uid,
				// summaryStatus: <TThreadSummaryStatus>'PENDING',
				// status: 'READY',
			 }))

			const functions = getFunctions()

			await httpsCallable(functions, 'threads-fns-replyToThread')(props)
		} catch (error) {
			logger.error(error)
		}
	}
}

// ----------------------------------------------------------------------

// interface DismissCardProps {
// 	promptId: string
// }
// export function dismissCard(props: DismissCardProps) {
// 	return async () => {
// 		const { promptId } = props
// 		dispatch(slice.actions.setDismissed({ id: promptId }))
// 	}
// }

// ---------------------------------------------------------------------

/* interface GeneratePromptsProps {
	uid: string
}

export function generatePrompts({ uid }: GeneratePromptsProps) {
	return async () => {
		try {
			logger.log('generatePrompts')
			const functions = getFunctions()
			const requestData = {
				uid,
			} as any

			await httpsCallable(functions, 'users-fns-generatePrompts')(requestData)
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.failedLoadingChoices())
		}
	}
} */

// ----------------------------------------------------------------------

interface ExecuteP2PChoiceProps {
	// id: string
	// text: string
	toUid: string
	// themeId: TPromptChoiceThemeId | null
	// config: TNewPromptChoiceConfig
	choice: TThreadChoiceWithConfig
}
export function executeP2PChoice(props: ExecuteP2PChoiceProps) {
	return async () => {
		try {
			logger.log('executeP2PChoice, props=', props)
			const functions = getFunctions()

			await httpsCallable(functions, 'users-fns-executeP2p')(props)
		} catch (error) {
			logger.error(error)
		}
	}
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface InitializeRepromptResponse {
	threadId: string
}

interface InitializeRepromptProps {
	text: string
	fromUid: string
}
export function initializeReprompt(props: InitializeRepromptProps) {
	return async () => {
		try {
			logger.log('initializeRepromptProps=', props)
			const functions = getFunctions()

			const { data: { threadId } } = await httpsCallable<{}, InitializeRepromptResponse>(functions, 'threads-fns-initializeReprompt')(props)

			dispatch(slice.actions.setNewRepromptThreadId({
				threadId,
			 }))
		} catch (error) {
			logger.error(error)
		}
	}
}

// ----------------------------------------------------------------------

interface ExecuteRepromptProps {
	toUid: string
	starter: string
	feedItemType: TFeedItemType
	choice: TThreadChoiceWithConfig
}
export function executeReprompt(props: ExecuteRepromptProps) {
	return async () => {
		try {
			logger.log('executeReprompt, props=', props)
			const functions = getFunctions()

			await httpsCallable(functions, 'users-fns-executeReprompt')(props)
		} catch (error) {
			logger.error(error)
		}
	}
}

// ----------------------------------------------------------------------

interface NudgeProps {
	toUid: string
}
export function nudge(props: NudgeProps) {
	return async () => {
		try {
			logger.log('nudge, props=', props)
			const functions = getFunctions()
			const requestData = {
				...props,
			} as any

			await httpsCallable(functions, 'threads-fns-nudge')(requestData)
		} catch (error) {
			logger.error(error)
		}
	}
}

// ----------------------------------------------------------------------

/* interface GetBackstoriesProps {
	uid: string
	threadId: string
	sortOrder: 'asc' | 'desc'
	limit?: number
}

export function getBackstories({
	uid, threadId, sortOrder, limit,
}:GetBackstoriesProps) {
	return async () => {
		dispatch(slice.actions.startLoadingBackstories({ threadId }))
		try {
			logger.log('getBackstories')
			const db = getFirestore()
			const collectionRef = collectionGroup(db, 'backstories')
			let q = query(
				collectionRef,
				where('threadId', '==', threadId),
				where('intendedReaderUid', '==', uid),
				orderBy('createdAt', sortOrder),
			)

			if (limit) {
				q = query(
					collectionRef,
					where('threadId', '==', threadId),
					where('intendedReaderUid', '==', uid),
					orderBy('createdAt', sortOrder),
					limitToLast(limit),
				)
			}

			const querySnapshot = await getDocs(q)

			const backstories:Array<IBackstoryfromDb> = querySnapshot.docs.map((doc) => {
				const data = doc.data() as Omit<IBackstoryfromDb, 'id'>
				const backstory:TBackstory = {
					id: doc.id,
					...data,
					createdDate: data.createdAt.toDate(),
				}

				return backstory
			})

			logger.log('backstories=', backstories)

			dispatch(slice.actions.getBackstoriesSuccess({
				backstories,
				threadId,
			}))
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.backstoriesLoadingFailure({ threadId }))
		}
	}
} */

// ----------------------------------------------------------------------

// interface GetSummaryProps {
// 	uid: string
// 	threadId: string
// }

// export function getSummary(props:GetSummaryProps) {
// 	return async () => {
// 		const { uid, threadId } = props
// 		dispatch(slice.actions.startLoadingSummary({ threadId }))
// 		try {
// 			logger.log('getSummary, props=', props)
// 			const db = getFirestore()
// 			const collectionRef = collection(db, 'threads', threadId, 'thread_summaries')
// 			const q = query(
// 				collectionRef,
// 				where('intendedReaderUid', '==', uid),
// 				orderBy('createdAt', 'asc'),
// 				limitToLast(2),
// 			)

// 			const querySnapshot = await getDocs(q)
// 			logger.log('getSummary, querySnapshot.docs.length ', querySnapshot.docs.length)
// 			if (querySnapshot.docs.length === 2) {
// 				// NOTE: we obtain the 2nd to last summary, which will not include the most recent move by your partner
// 				const summary:string = querySnapshot.docs[0].data()?.summary as string

// 				logger.log('getSummary= ', summary)
// 				// logger.log('backstories=', backstories)

// 				dispatch(slice.actions.getSummarySuccess({
// 					threadId,
// 					summary,
// 				}))
// 			} else {
// 				dispatch(slice.actions.getSummarySuccess({
// 					threadId,
// 					summary: null,
// 				}))
// 			}
// 		} catch (error) {
// 			logger.error(error)
// 			dispatch(slice.actions.summaryLoadingFailure({ threadId }))
// 		}
// 	}
// }

// interface GetSummaryv2Props {
// 	uid: string
// 	threadId: string
// }

// export function getSummaryv2(props:GetSummaryv2Props) {
// 	return async () => {
// 		const { uid, threadId } = props
// 		dispatch(slice.actions.startLoadingSummary({ threadId }))
// 		try {
// 			logger.log('getSummary, props=', props)
// 			const db = getFirestore()
// 			const collectionRef = collection(db, 'threads', threadId, 'thread_summaries')
// 			const q = query(
// 				collectionRef,
// 				where('intendedReaderUid', '==', uid),
// 				orderBy('createdAt', 'asc'),
// 				limitToLast(1),
// 			)

// 			const querySnapshot = await getDocs(q)
// 			logger.log('getSummary, querySnapshot.docs.length ', querySnapshot.docs.length)
// 			if (querySnapshot.docs.length === 1) {
// 				// NOTE: we obtain the 2nd to last summary, which will not include the most recent move by your partner
// 				const summary:string = querySnapshot.docs[0].data()?.summary as string

// 				logger.log('getSummary= ', summary)
// 				// logger.log('backstories=', backstories)

// 				dispatch(slice.actions.getSummarySuccess({
// 					threadId,
// 					summary,
// 				}))
// 			} else {
// 				dispatch(slice.actions.getSummarySuccess({
// 					threadId,
// 					summary: null,
// 				}))
// 			}
// 		} catch (error) {
// 			logger.error(error)
// 			dispatch(slice.actions.summaryLoadingFailure({ threadId }))
// 		}
// 	}
// }

// interface GetSummaryv3Props {
// 	uid: string
// 	threadId: string
// }

// export function getSummaryv3(props:GetSummaryv3Props) {
// 	return async () => {
// 		const { uid, threadId } = props
// 		dispatch(slice.actions.startLoadingSummary({ threadId }))
// 		try {
// 			logger.log('getSummary, props=', props)
// 			const db = getFirestore()
// 			const collectionRef = collection(db, 'threads', threadId, 'thread_summaries')
// 			const q = query(
// 				collectionRef,
// 				where('intendedReaderUid', '==', uid),
// 				orderBy('createdAt', 'asc'),
// 				limitToLast(1),
// 			)

// 			const querySnapshot = await getDocs(q)
// 			logger.log('getSummary, querySnapshot.docs.length ', querySnapshot.docs.length)
// 			if (querySnapshot.docs.length === 1) {
// 				// NOTE: we obtain the 2nd to last summary, which will not include the most recent move by your partner
// 				const summary:IThreadSummary = firestoreToIThreadSummary(querySnapshot.docs[0])

// 				logger.log('getSummary= ', summary)
// 				// logger.log('backstories=', backstories)

// 				dispatch(slice.actions.getSummarySuccess({
// 					threadId,
// 					summary,
// 				}))
// 			} else {
// 				dispatch(slice.actions.getSummarySuccess({
// 					threadId,
// 					summary: null,
// 				}))
// 			}
// 		} catch (error) {
// 			logger.error(error)
// 			dispatch(slice.actions.summaryLoadingFailure({ threadId }))
// 		}
// 	}
// }

// export function getBackstories({
// 	uid, threadId, promptId, sortOrder,
// }:GetBackstoriesProps) {
// 	return async () => {
// 		dispatch(slice.actions.startLoadingBackstories({ threadId }))
// 		try {
// 			logger.log('getBackstories')
// 			const db = getFirestore()
// 			const collectionRef = collection(db, 'threads', threadId, 'user_threads', uid, 'backstories')
// 			const q = query(collectionRef, orderBy('createdAt', sortOrder))

// 			const querySnapshot = await getDocs(q)

// 			const backstories:Array<IBackstoryfromDb> = querySnapshot.docs.map((doc) => {
// 				const data = doc.data() as Omit<IBackstoryfromDb, 'id'>
// 				const backstory:TBackstory = {
// 					id: doc.id,
// 					...data,
// 					createdDate: data.createdAt.toDate(),
// 				}

// 				return backstory
// 			})

// 			logger.log('backstories=', backstories)

// 			dispatch(slice.actions.getBackstoriesSuccess({
// 				backstories,
// 				threadId,
// 				promptId,
// 			}))
// 		} catch (error) {
// 			logger.error(error)
// 			dispatch(slice.actions.backstoriesLoadingFailure({ threadId }))
// 		}
// 	}
// }

// ----------------------------------------------------------------------

// interface SetReactionToBackstoryProps {
// 	uid: string
// 	reactionId: string
// 	backstory:TBackstory
// }
// export function setReactionToToBackstory(props: SetReactionToBackstoryProps) {
// 	return async () => {
// 		try {
// 			const { uid, reactionId, backstory } = props
// 			const { threadId, id, messageId } = backstory
// 			dispatch(slice.actions.setBackstoryReaction({ reactionId, messageId, threadId }))
// 			const db = getFirestore()
// 			const myBackstoryDocRef = doc(db, 'threads', threadId, 'thread_messages', messageId, 'backstories', uid)
// 			const fromBackstoryDofRef = doc(db, 'threads', threadId, 'thread_messages', messageId, 'backstories', backstory.from.uid)

// 			await Promise.all([

// 				//

// 				setDoc(myBackstoryDocRef, { reactionId }, { merge: true }),

// 				//

// 				setDoc(fromBackstoryDofRef, { reactionId }, { merge: true }),

// 				//

// 			])
// 		} catch (error) {
// 			logger.error(error)
// 		}
// 	}
// }

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// interface UnSetReactionToBackstoryProps {
// 	uid: string
// 	backstory:TBackstory
// }
// export function unsetReactionToToBackstory(props: UnSetReactionToBackstoryProps) {
// 	return async () => {
// 		try {
// 			const { uid, backstory } = props
// 			const { threadId, id, messageId } = backstory
// 			dispatch(slice.actions.setBackstoryReaction({ reactionId: null, messageId, threadId }))
// 			const db = getFirestore()
// 			const myBackstoryDocRef = doc(db, 'threads', threadId, 'thread_messages', messageId, 'backstories', uid)
// 			const fromBackstoryDofRef = doc(db, 'threads', threadId, 'thread_messages', messageId, 'backstories', backstory.from.uid)

// 			await Promise.all([

// 				//

// 				setDoc(myBackstoryDocRef, { reactionId: deleteField() }, { merge: true }),

// 				//

// 				setDoc(fromBackstoryDofRef, { reactionId: deleteField() }, { merge: true }),

// 				//

// 			])
// 		} catch (error) {
// 			logger.error(error)
// 		}
// 	}
// }

interface DiscardThreadProps {
	threadId: string
	uid: string
}
export function discardThread(props: DiscardThreadProps) {
	return async () => {
		logger.log('discardThread, props=', props)
		const { threadId, uid } = props

		dispatch(slice.actions.discardThread({ threadId, uid }))
		const db = getFirestore()
		const docRef = doc(db, 'threads', threadId)
		await setDoc(docRef, { isDeleted: true, endedByUid: uid }, { merge: true })
	}
}

// ----------------------------------------------------------------------
