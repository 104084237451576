import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import uniq from 'lodash/uniq'
import { logger } from 'src/services/logger'
import { createSlice, Dispatch } from '@reduxjs/toolkit'
// utils
import {
	addDoc, arrayRemove, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, increment, query, setDoc, Timestamp, updateDoc, where,
} from 'firebase/firestore'
import {
	IModCard, IModPrompts, IModsBoard, IModsColumn, IModsState2,
} from 'src/@types/mods'
import { getFunctions, httpsCallable } from 'firebase/functions'

// @types
import { ICustomTokenStoriesEdit, IEditableStory } from 'src/@types/storiesEdit'
import uniqId from 'uniqid'
import { IKanbanState, IKanbanCard, IKanbanColumn } from '../../@types/kanban'

// ----------------------------------------------------------------------

function objFromArray<Type extends Record<string, any>>(array: Type[], key = 'id') {
	return array.reduce<Record<string, Type>>((accumulator, current) => {
		accumulator[current[key]] = current
		return accumulator
	}, {})
}

const initialState: ICustomTokenStoriesEdit = {
	isLoading: false,
	isSaving: {},
	isCreatingStory: false,
	isUpdatingStory: false,
	deletingStories: false,
	// promptsIsLoading: false,
	error: null,
	// previewModId: null,
	// stories: {},
	stories: {
		byCustomTokenId: {},
		// byId: {},
		// allIds: [],
	},
}

const slice = createSlice({
	name: 'story-edit',
	initialState,
	reducers: {

		// START SAVING
		// startSaving(state, action) {
		// 	const modId = action.payload
		// 	state.isSaving[modId] = true
		// },

		startDeleteStories(state) {
			state.deletingStories = true
		},

		deleteStoriesSuccess(state) {
			state.deletingStories = false
		},

		// START LOADING
		startLoading(state, action) {
			const { customTokenId } = action.payload
			state.stories.byCustomTokenId[customTokenId] = {
				isLoading: true,
				isCreatingStory: false,
				isUpdatingStory: false,
				allIds: [],
				byId: {},
			}
		},

		// START LOADING
		startCreateStory(state, action) {
			const { customTokenId } = action.payload
			const existingStories = state.stories.byCustomTokenId[customTokenId] ?? {
				isCreatingStory: true,
				byId: {},
			}
			state.stories.byCustomTokenId[customTokenId] = {
				...existingStories,
			}
		},

		// START LOADING
		startUpdateStory(state, action) {
			const { customTokenId, storyId } = action.payload
			state.stories.byCustomTokenId[customTokenId] = {
				...state.stories.byCustomTokenId[customTokenId],
				isUpdatingStory: true,
			}
		},

		// HAS ERROR
		hasError(state, action) {
			const { customTokenId, error } = action.payload
			state.stories.byCustomTokenId[customTokenId] = {
				...state.stories.byCustomTokenId[customTokenId],
				isLoading: false,
			}
			state.error = error
		},

		// GET BOARD
		getStoriesSuccess(state, action) {
			const { customTokenId, stories } = action.payload
			const byId = keyBy(stories, 'id')
			state.stories.byCustomTokenId[customTokenId] = {
				byId,
				allIds: Object.keys(byId),
				isLoading: false,
				isUpdatingStory: false,
				isCreatingStory: false,
			}

			// console.log(state.stories.byCustomTokenId[customTokenId])
			// state.stories.allIds = Object.keys(state.stories.byId)
		},

		// // CREATE NEW COLUMN
		// createColumnSuccess(state, action) {
		// 	const newColumn = action.payload
		// 	state.isLoading = false
		// 	state.board.columns = {
		// 		...state.board.columns,
		// 		[newColumn.id]: newColumn,
		// 	}
		// 	state.board.columnOrder.push(newColumn.id)
		// },

		// persistCard(state, action) {
		// 	const columns = action.payload
		// 	console.log(
		// 		columns,
		// 	)
		// 	state.board.columns = columns
		// 	state.board.version += 1
		// },

		// persistColumn(state, action) {
		// 	state.board.columnOrder = action.payload
		// },

		createStorySuccess(state, action) {
			const { customTokenId, story } = action.payload
			state.stories.byCustomTokenId[customTokenId].byId[story.id] = story
			state.stories.byCustomTokenId[customTokenId].allIds = Object.keys(state.stories.byCustomTokenId[customTokenId].byId[story.id])
			state.stories.byCustomTokenId[customTokenId].isCreatingStory = false
		},

		updateStorySuccess(state, action) {
			const { customTokenId, story } = action.payload
			state.stories.byCustomTokenId[customTokenId].byId[story.id] = story
			state.stories.byCustomTokenId[customTokenId].isUpdatingStory = false
		},

		/* 	// UPDATE MOD
		updateMod(state, action) {
			const { mod } = action.payload
			state.board.mods[mod.id] = merge(state.board.mods[mod.id], mod)
			if (mod?.selectedActionIds) {
				state.board.mods[mod.id].selectedActionIds = mod.selectedActionIds
			}
			state.isSaving[mod.id] = false
			state.board.version += 1
		},

		deleteMod(state, action) {
			const { modId, columnId } = action.payload

			console.log(action.payload)
			console.log(state.board.columns)
			state.board.columns[columnId].modIds = state.board.columns[columnId].modIds.filter(
				(id) => id !== modId,
			)

			state.board.mods = omit(state.board.mods, [modId])
			state.board.version += 1
		},

		setPreviewModId(state, action) {
			state.previewModId = action.payload
		},

		clearPreviewModId(state) {
			state.previewModId = null
		},

		// START LOADING
		promptsStartLoading(state) {
			state.promptsIsLoading = true
			state.prompts = []
		},

		// GET MOD PROMPTS SUCCESS
		getPromptsSuccess(state, action) {
			state.promptsIsLoading = false
			const { prompts } = action.payload
			state.prompts = prompts
		}, */

	},
})

// Reducer
export default slice.reducer

export const { actions } = slice

// ----------------------------------------------------------------------

// export function getBoard() {
// 	return async (dispatch: Dispatch) => {
// 		dispatch(slice.actions.startLoading())
// 		try {
// 			const response = await axios.get('/api/kanban/board')
// 			dispatch(slice.actions.getBoardSuccess(response.data.board))
// 		} catch (error) {
// 			dispatch(slice.actions.hasError(error))
// 		}
// 	}
// }

interface GetModsProps {
	customTokenId: string
}

export function getStories({ customTokenId }:GetModsProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading({ customTokenId }))
		try {
			// console.log('getStories')
			const db = getFirestore()
			const storiesCollectionRef = collection(db, 'customizedTokens', customTokenId, 'stories')
			const storiesQuerySnapshot = await getDocs(query(storiesCollectionRef, where('isDeleted', '!=', true)))
			const stories: Array<IEditableStory> = storiesQuerySnapshot.docs.map((doc):IEditableStory => {
				const story : IEditableStory = {
					id: doc.id,
					...doc.data() as Omit<IEditableStory, 'id'>,
				}
				return story
			})

			dispatch(slice.actions.getStoriesSuccess({ customTokenId, stories }))
		} catch (error) {
			dispatch(slice.actions.hasError({ customTokenId, error }))
		}
	}
}

// ----------------------------------------------------------------------

interface CreateStoryProps {
	story: Omit<IEditableStory, 'id' | 'version' | 'coverImageUrl'>
}

export function createStory({ story }: CreateStoryProps) {
	return async (dispatch: Dispatch) => {
		const db = getFirestore()
		const { customTokenId } = story
		dispatch(slice.actions.startCreateStory({ customTokenId }))
		const newStory:Omit<IEditableStory, 'id' | 'version'> = {
			...story,
			isDeleted: false,
			updatedAtMilliseconds: Timestamp.now().toMillis(),
			createdAtMilliseconds: Timestamp.now().toMillis(),
			version: increment(1),
		}

		const storyDocRef = await addDoc(collection(db, 'customizedTokens', customTokenId, 'stories'), newStory)

		dispatch(slice.actions.createStorySuccess({ customTokenId, story: { id: storyDocRef.id, ...newStory } }))
	}
}

interface UpdateStoryProps {
	story: Omit<IEditableStory, 'version'>
}
export function updateStory({ story }: UpdateStoryProps) {
	return async (dispatch: Dispatch) => {
		const { customTokenId, id } = story
		dispatch(slice.actions.startUpdateStory({ customTokenId, storyId: id }))
		const db = getFirestore()

		const newStory = {
			...story,
			updatedAtMilliseconds: Timestamp.now().toMillis(),
			version: increment(1),
		}

		const storyDocRef = doc(db, 'customizedTokens', customTokenId, 'stories', id)
		await setDoc(storyDocRef, newStory, { merge: true })

		const updatedDoc = await getDoc(storyDocRef)
		logger.log(updatedDoc.data())

		dispatch(slice.actions.updateStorySuccess({ customTokenId, story: updatedDoc.data() }))
	}
}

// ----------------------------------------------------------------------

interface DeleteStoryProps {
	storyIds: Array<string>
	customTokenId: string
}
export function deleteStories({ storyIds, customTokenId }: DeleteStoryProps) {
	return async (dispatch: Dispatch) => {
		// const { customTokenId, id } = story
		dispatch(slice.actions.startDeleteStories())
		const db = getFirestore()

		await Promise.all(storyIds.map(async (storyId) => {
			const storyDocRef = doc(db, 'customizedTokens', customTokenId, 'stories', storyId)
			await setDoc(storyDocRef, { isDeleted: true }, { merge: true })
		}))

		dispatch(slice.actions.deleteStoriesSuccess())
	}
}

// ----------------------------------------------------------------------
