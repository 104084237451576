import { createSlice, Dispatch } from '@reduxjs/toolkit'

import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, deleteDoc, FieldValue,
} from 'firebase/firestore'
import { TFollowingState } from 'src/types/Following'
import { logger } from 'src/services/logger'

// ----------------------------------------------------------------------

const initialState: TFollowingState = {
	isLoaded: false,
	isLoading: false,
	error: false,
	uids: [],

}

const slice = createSlice({
	name: 'following',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoaded = false
			state.isLoading = true
			state.error = false
		},

		// HAS ERROR
		getFailure(state) {
			state.isLoaded = false
			state.isLoading = false
			state.error = true
		},

		// GET PROMOTIONS
		getSuccess(state, action) {
			const { uids } = action.payload
			state.isLoaded = true
			state.isLoading = false
			state.uids = uids
			state.error = false
		},

		// Add FOLLOWING
		addFollowing(state, action) {
			const { uid } = action.payload
			if (!state.uids.includes(uid)) {
				state.uids.push(uid)
			}
		},

		// Remove FOLLOWING
		removeFollowing(state, action) {
			const { uid } = action.payload
			const idx = state.uids.indexOf(uid)
			if (idx >= 0) {
				state.uids.splice(idx, 1)
			}
		},
	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetFollowingProps {
	uid: string
}
export const getFollowing = ({ uid }:GetFollowingProps) => (dispatch:Dispatch): Unsubscribe | undefined => {
	dispatch(slice.actions.startLoading())
	try {
		logger.log('getFollowing')
		const db = getFirestore()
		const collectionRef = collection(db, 'users', uid, 'following')
		const q = query(collectionRef)
		const unsubscribe: Unsubscribe = onSnapshot(q, async (querySnapshot) => {
			const uids = querySnapshot.docs.map((doc) => doc.id)
			dispatch(slice.actions.getSuccess({
				uids,
			}))
		})

		return unsubscribe
	} catch (error) {
		logger.error(error)
		dispatch(slice.actions.getFailure())
	}
	return undefined
}

// ----------------------------------------------------------------------

interface FollowProps {
	uid: string
	uidToFollow: string
}
export function followPerson(props: FollowProps) {
	return async (dispatch: Dispatch) => {
		const { uid, uidToFollow } = props
		dispatch(slice.actions.addFollowing({ uid: uidToFollow }))
		const db = getFirestore()
		const docRef = doc(db, 'users', uid, 'following', uidToFollow)
		await setDoc(docRef, {
			uid: uidToFollow,
			followerUid: uid,
			updatedAt: serverTimestamp(),
		}, { merge: true })
	}
}

// ----------------------------------------------------------------------

interface UnFollowProps {
	uid: string
	uidToUnFollow: string
}
export function unfollowPerson(props: UnFollowProps) {
	return async (dispatch: Dispatch) => {
		const { uid, uidToUnFollow } = props
		dispatch(slice.actions.removeFollowing({ uid: uidToUnFollow }))
		const db = getFirestore()
		const docRef = doc(db, 'users', uid, 'following', uidToUnFollow)
		await deleteDoc(docRef)
	}
}

// ----------------------------------------------------------------------
