import { createSlice, Dispatch } from '@reduxjs/toolkit'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, orderBy, limit, collectionGroup, startAfter, QueryDocumentSnapshot, DocumentData, getCountFromServer, FieldValue, deleteField,
} from 'firebase/firestore'
import { ConsoleLogger, logger } from 'src/services/logger'

import { FeedState, IFeedItemFromDb, TFeedItem } from 'src/types/Feed'
import NavItem from 'src/components/nav-section/vertical/NavItem'
import { Field } from 'formik'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { store } from '../store'
// ----------------------------------------------------------------------

export type TSort = 'byCreatedDesc'

type TGetPageSuccessPayload = {
	items: Array<TFeedItem>
	lastDoc: QueryDocumentSnapshot<DocumentData> | null
	pageSize: number
	reset: boolean
}

type TGetTotalMoveSuccessPayload = {
	total: number
}

type TToggleLikePayload = {
	// uid: string
	item: TFeedItem
	isLiked: boolean
}

type TSetRepromptedPayload = {
	item: TFeedItem
}

const initialState: FeedState = {
	isLoading: false,
	error: false,
	items: [],
	lastDoc: null,
	hasMore: true,
}

const slice = createSlice({
	name: 'feedv2',
	initialState,
	reducers: {

		startLoadingPage(state) {
			state.isLoading = true
			state.error = false
		},

		getPageFailure(state) {
			state.isLoading = false
			state.error = true
		},

		getPageSuccess(state, action) {
			const {
				lastDoc, items, pageSize, reset,
			}: TGetPageSuccessPayload = action.payload

			state.lastDoc = lastDoc

			state.items = [
				...(!reset ? state.items : []),
				...items,
			]

			state.hasMore = items.length >= pageSize
			state.isLoading = false
			state.error = false
		},

		toggleLike(state, action) {
			const { item: targetedItem, isLiked }:TToggleLikePayload = action.payload
			const idx = state.items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				const { likes = 0 } = state.items[idx]
				state.items[idx].isLiked = isLiked
				state.items[idx].likes = (likes + (isLiked ? 1 : -1))
			}
		},

		setReprompted(state, action) {
			const { item: targetedItem }:TSetRepromptedPayload = action.payload
			const idx = state.items.findIndex((item) => item.id === targetedItem.id)
			if (idx >= 0) {
				const { reprompts = 0 } = state.items[idx]
				state.items[idx].isReprompted = true
				state.items[idx].reprompts = reprompts + 1
			}
		},

	},
})

// Reducer
export default slice.reducer

// --------------------------------------------------

interface GetPageProps {
	pageSize: number
	uid: string
	reset?: boolean
}

export function getPage(props:GetPageProps) {
	return async () => {
		logger.log('feedv2, getPage', props)
		const { dispatch, getState } = store

		dispatch(slice.actions.startLoadingPage())

		try {
			const {
				 pageSize, uid, reset,
			} = props

			const { feed2: feedState }:{feed2: FeedState} = getState()
			const startAfterDoc: QueryDocumentSnapshot<DocumentData, DocumentData> | null = feedState.lastDoc as QueryDocumentSnapshot<DocumentData, DocumentData>
			const db = getFirestore()
			const collectionRef = collection(db, 'users', uid, 'feed_items')

			let q

			if (reset || !startAfterDoc) {
				q = query(
					collectionRef,
					orderBy('createdAt', 'desc'),
					limit(pageSize),
				)
			} else {
				q	 = query(
					collectionRef,
					orderBy('createdAt', 'desc'),
					limit(pageSize),
					startAfter(startAfterDoc),
				)
			}

			const querySnapshot = await getDocs(q)

			if (!querySnapshot.empty) {
				const items: Array<TFeedItem> = querySnapshot.docs.map((item) => {
					const feedItem: TFeedItem = {
						id: item.id,
						...(item.data() as Omit<IFeedItemFromDb, 'id'>),
						createdAtDate: (item.data() as IFeedItemFromDb).createdAt.toDate(),
					}
					return feedItem
				})

				dispatch(slice.actions.getPageSuccess({
					items,
					reset,
					lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
					pageSize,
				}))
			} else {
				dispatch(slice.actions.getPageSuccess({
					items: [],
					reset,
					lastDoc: null,
					pageSize,
				}))
			}
		} catch (err) {
			logger.error(err)
			dispatch(slice.actions.getPageFailure())
		}
	}
}

// ----------------------------------------------------------------------

interface ToggleLikeProps {
	uid: string
	item: TFeedItem
}
export function toggleLike(props: ToggleLikeProps) {
	return async (dispatch: Dispatch) => {
		const { uid, item } = props
		logger.log('toggleLike', props)
		const newIsLiked = !(item?.isLiked ?? false)

		dispatch(slice.actions.toggleLike({ item, isLiked: newIsLiked }))
		const db = getFirestore()
		const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', item.id)
		const rootFeedItemDocRef = doc(db, 'root_feed_items', item.rootFeedId)

		await Promise.all([

			//

			setDoc(userFeedItemDocRef, {
				isLiked: newIsLiked,
				updatedAt: serverTimestamp(),
				...(newIsLiked ? {
					likedAt: serverTimestamp(),
				} : {
					likedAt: deleteField(),
				}),

			}, { merge: true }),

			//

			setDoc(rootFeedItemDocRef, {
				likes: increment(newIsLiked ? 1 : -1),
				likesUpdatedAt: serverTimestamp(),
				updatedAt: serverTimestamp(),
			}, { merge: true }),

			//

		])
	}
}

// ----------------------------------------------------------------------

interface SetRepromptedProps {
	uid: string
	item: TFeedItem
}
export function setReprompted(props: SetRepromptedProps) {
	return async (dispatch: Dispatch) => {
		const { uid, item } = props
		logger.log('setReprompted', props)
		// const newIsLiked = !(item?.isLiked ?? false)

		dispatch(slice.actions.setReprompted({ item }))
		const db = getFirestore()
		const userFeedItemDocRef = doc(db, 'users', uid, 'feed_items', item.id)
		const rootFeedItemDocRef = doc(db, 'root_feed_items', item.rootFeedId)

		await Promise.all([

			//

			setDoc(userFeedItemDocRef, {
				isReprompted: true,
				updatedAt: serverTimestamp(),
				repromptedAt: serverTimestamp(),
			}, { merge: true }),

			//

			setDoc(rootFeedItemDocRef, {
				reprompts: increment(1),
				repromptsUpdatedAt: serverTimestamp(),
				updatedAt: serverTimestamp(),
			}, { merge: true }),

			//

		])
	}
}

// ----------------------------------------------------------------------

interface PostStatusUpdateProps {
	uid: string
	text: string
}
export function postStatusUpdate(props: PostStatusUpdateProps) {
	return async (dispatch: Dispatch) => {
		const { uid, text } = props
		logger.log('postStatusUpdate', props)

		// dispatch(slice.actions.setReprompted({ item }))

		const functions = getFunctions()
		await httpsCallable(functions, 'users-fns-postStatusUpdate')({ text })
	}
}

// ----------------------------------------------------------------------
