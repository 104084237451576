import { createSlice } from '@reduxjs/toolkit'
// utils
// @types
// import { FeedState } from '../../@types/feed'
import { CustomizedTokenPost, IPostComment } from 'src/@types/customizedToken'
import _ from 'lodash'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { IFeedInfo, IViewableStory } from 'src/@types/storiesViewable'
import { logger } from 'src/services/logger'
import { TForcedSort } from 'src/types/TForcedSort'
import { createFeedKey } from 'src/utils/feedSort'
import { DEFAULT_STORY_SCHEMA, algoliaConfigs } from 'src/config'
import algoliasearch from 'algoliasearch'
import axios from '../../utils/axios'
import { store } from '../store'

// ----------------------------------------------------------------------

const client = algoliasearch(algoliaConfigs.appId, algoliaConfigs.apiKey)

export const PAGE_SIZE = 3

const attributesToRetrieve = [
	'*',
	'-isDeleted',
]

type TStoryId = string
type TFeedId = string

type StoryFeedState = {
  isLoading: {
    byId: Record<TFeedId, boolean>
  }
	isLoadingFeedError: {
		byId: Record<TFeedId, Error | string | null>
	}
	isLoadingStory: {
		byId: Record<TStoryId, boolean>
	}
	isLoadingStoryError: {
		byId: Record<TStoryId, Error | string | null>
	}
	// isLoadingComments: {
  //   byId: Record<string, boolean>
  // }
  // error: Error | string | null
  feeds: {
    byId: Record<string, IFeedInfo>
  }
  // comments: {
  //   byId: Record<string, Array<IPostComment>>
  // }
  activeFeedId: null | string
	// hasMore: boolean
	// storyCount: number
	// hasChanged: boolean
  // participants: Participant[]
  // recipients: any[]
	sortBy: TForcedSort | null
	query: string
};

const initialState: StoryFeedState = {
	isLoading: {
		byId: {},
	},
	isLoadingFeedError: {
		byId: {},
	},
	isLoadingStory: {
		byId: {},
	},
	isLoadingStoryError: {
		byId: {},
	},
	// isLoadingComments: {
	// 	byId: {},
	// },
	// error: null,
	feeds: {
		byId: {},
	},
	// comments: {
	// 	byId: {},
	// },
	activeFeedId: null,
	// hasChanged: false,
	// hasMore: true,
	storyCount: 0,
	sortBy: 'byNewest',
	query: '',
}

const slice = createSlice({
	name: 'story-feed',
	initialState,
	reducers: {

		// START LOADING POSTS
		startLoadingFeed(state, action) {
			const { feedId } = action.payload
			state.isLoading.byId[feedId] = true
			state.isLoadingFeedError.byId[feedId] = null
			if (!state.feeds.byId[feedId]) {
				state.feeds.byId[feedId] = {
					storyCount: 0,
					lastPageNumberLoaded: null,
					hasMore: true,
					stories: [],
				}
			}
		},

		getFeedFailure(state, action) {
			const { feedId, error } = action.payload
			state.isLoading.byId[feedId] = false
			state.isLoadingFeedError.byId[feedId] = error
		},

		// START LOADING STORY
		startLoadingStory(state, action) {
			const { storyId, feedId } = action.payload
			state.isLoadingStory.byId[storyId] = true
			state.isLoadingStoryError.byId[storyId] = null

			if (!state.feeds.byId[feedId]) {
				state.feeds.byId[feedId] = {
					storyCount: 0,
					lastPageNumberLoaded: null,
					hasMore: true,
					stories: [],
				}
			}
		},

		getStoryFailure(state, action) {
			const { storyId, error } = action.payload
			state.isLoadingStory.byId[storyId] = false
			state.isLoadingStoryError.byId[storyId] = error
		},

		// START LOADING COMMENTS
		// startLoadingComments(state, action) {
		// 	const postId = action.payload
		// 	state.isLoadingComments.byId[postId] = true
		// },

		// noHasMore(state) {
		// 	state.hasMore = false
		// },

		// HAS ERROR
		// hasError(state, action) {
		// 	// state.isLoading = false
		// 	state.error = action.payload
		// },

		// GET POST FIRST PAGE
		getStorySuccess(state, action) {
			// logger.log('action.payload=', action.payload)
			const { feedId, storyId, story }: {feedId: string; storyId: string; story: IViewableStory } = action.payload

			const storyIds:Array<string> = state.feeds.byId[feedId]?.stories?.map((story) => story.id) ?? []
			// logger.log('storyIds=', storyIds)
			const indexOfExistingStory = storyIds.indexOf(storyId)
			// logger.log('indexOfExistingStory=', indexOfExistingStory)

			const feed:Array<IViewableStory> = [...(state.feeds.byId?.[feedId]?.stories ?? [])]

			if (indexOfExistingStory >= 0) {
				// this removes the story from the array
				feed.splice(indexOfExistingStory, 1)
				// logger.log('feed, after removal of found story=', feed)
			}

			state.feeds.byId[feedId].stories = [story, ...feed]

			// logger.log('state.feeds.byId[feedId]=', state.feeds.byId[feedId])
			// state.hasChanged = stories.length > 0
			state.isLoadingStory.byId[storyId] = false
			state.isLoadingStoryError.byId[storyId] = null
		},

		// GET POST FIRST PAGE
		getPageSuccess(state, action) {
			const {
				feedId, stories, storyCount, pageNumber,
			}: {feedId: string; stories: Array<IViewableStory>;storyCount: number; pageNumber: number} = action.payload

			const storyIds:Array<string> = state.feeds.byId[feedId]?.stories?.map((story) => story.id) ?? []
			const filteredForDups:Array<IViewableStory> = stories.filter((story) => !storyIds.includes(story.id))

			if (filteredForDups.length !== 0) {
				state.feeds.byId[feedId].stories = [
					...(state.feeds.byId?.[feedId]?.stories ?? []),
					...filteredForDups,
				]

				// state.hasChanged = true
			}

			// state.feeds.byId[feedId] = [...state.feeds.byId[feedId], ...stories]
			// state.hasChanged = stories.length > 0
			state.feeds.byId[feedId].storyCount = storyCount
			state.feeds.byId[feedId].lastPageNumberLoaded = pageNumber
			state.feeds.byId[feedId].hasMore = state.feeds?.byId?.[feedId]?.stories?.length < storyCount
			// if (state.feeds?.byId?.[feedId]?.stories?.length >= storyCount) {
			// 	state.feeds.byId[feedId].hasMore = false
			// }

			state.isLoading.byId[feedId] = false
			state.isLoadingFeedError.byId[feedId] = null
		},

		setSortBy(state, action) {
			state.sortBy = action.payload
		},

		setQuery(state, action) {
			state.query = action.payload
			state.sortBy = null
		},

		resetQuery(state) {
			state.query = ''
			state.sortBy = 'byNewest'
		},
		// // GET POST NEXT PAGE
		// getFeedNextPageSuccess(state, action) {
		// 	const { feedId, stories }: {feedId: string; stories: Array<IViewableStory> } = action.payload

		// 	// logger.log(stories)
		// 	const storyIds:Array<string> = state.feeds.byId[feedId].map((story) => story.id)
		// 	const filteredForDups:Array<IViewableStory> = stories.filter((story) => !storyIds.includes(story.id))

		// 	if (filteredForDups.length !== 0) {
		// 		state.feeds.byId[feedId] = [
		// 			...state.feeds.byId[feedId],
		// 			...filteredForDups,
		// 		]
		// 	}

		// 	if (state.feeds.byId[feedId].length >= state.storyCount) {
		// 		state.hasMore = false
		// 	}

		// 	state.isLoading.byId[feedId] = false
		// 	state.isLoadingFeedError.byId[feedId] = null
		// },

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetFirstPageResponse {
	stories: Array<IViewableStory>
	storyCount: number
	pageNumber: number
}

type TGetNextPageResponse = Pick<GetFirstPageResponse, 'stories'>

/* interface FirstPageProps {
	feedId?: string
	pageSize: number
}

export function getFirstPage({ feedId = 'DEFAULT', pageSize }:FirstPageProps) {
	return async () => {
		// logger.log('getFirstPage, news-fns-nextFeedPage')
		const { dispatch } = store

		dispatch(slice.actions.startLoadingFeed({ feedId }))
		// dispatch(slice.actions.hasChanged(false))
		try {
			const functions = getFunctions()
			const fn = httpsCallable<{}, GetFirstPageResponse>(functions, 'stories-feed-fns-nextFeedPage')
			const requestData = {
				pageSize,
				...(feedId && {
					customTokenId: feedId,
				}),
			}

			// logger.log(requestData)

			const { data: { stories, storyCount } } = await fn(requestData)

			// logger.log('getFirstPage, storyCount=', storyCount)
			// logger.log('getFirstPage, stories=', stories)

			dispatch(slice.actions.getFeedFirstPageSuccess({
				feedId,
				stories,
				storyCount,
			}))

			// if (stories.length >= storyCount) {
			// 	dispatch(slice.actions.noHasMore())
			// }
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFeedFailure({ feedId, error }))
		}
	}
} */

// ----------------------------------------------------------------------
/* interface NextPageProps {
	feedId?: string
	startAfterRank: number
	startAfterMilliseconds: number
	pageSize: number
	// startAfterCustomTokenId: string
}

export function getNextPage({
	feedId = 'DEFAULT', startAfterRank, startAfterMilliseconds, pageSize,
}:NextPageProps) {
	return async () => {
		// logger.log('getNextPage, feedId=', feedId)
		// logger.log('getNextPage, news-fns-nextFeedPage')
		const { dispatch } = store

		dispatch(slice.actions.startLoadingFeed({ feedId }))
		// dispatch(slice.actions.hasChanged(false))
		try {
			const functions = getFunctions()
			const fn = httpsCallable<{}, TGetNextPageResponse>(functions, 'stories-feed-fns-nextFeedPage')
			const requestData = {
				pageSize,
				startAfterMilliseconds,
				startAfterRank,
				// startAfterCustomTokenId,
				...(feedId && {
					customTokenId: feedId,
				}),
			}

			// logger.log('getNextPage requestData=', requestData)

			const { data: { stories } } = await fn(requestData)

			// logger.log('getNextPage, stories=', stories.length)

			dispatch(slice.actions.getFeedNextPageSuccess({
				feedId,
				stories,
			}))
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFeedFailure({ feedId, error }))
		}
	}
} */

// --------------------------------------------------

interface GetPageProps {
	searchQuery?: string
	sortBy: TForcedSort | null
	pageSize: number
	pageNumber: number
	feedId: string
}

export function getPage(props:GetPageProps) {
	return async () => {
		logger.log('getPage', props)
		const {
			feedId,
		} = props
		const { dispatch } = store

		dispatch(slice.actions.startLoadingFeed({ feedId }))

		try {
			const {
				sortBy, pageNumber, pageSize, searchQuery,
			} = props

			let query:string = searchQuery ?? ''
			let indexName = algoliaConfigs.primaryIndex
			if (sortBy) {
				if (sortBy === 'byRank') {
					indexName = algoliaConfigs.byRankIndex
					query = ''
				}

				if (sortBy === 'byNewest') {
					indexName = algoliaConfigs.byNewestIndex
					query = ''
				}
			}
			// console.log(algoliaConfigs)

			const index = client.initIndex(indexName)

			const {
				hits, nbHits, page, nbPages,
			} = await index.search(query, {
				attributesToRetrieve,
				hitsPerPage: pageSize,
				page: pageNumber,
				filters: 'isReady:true AND isPublished:true AND isDeleted:false',
			})

			logger.log(hits, nbHits, page, nbPages)

			const stories: Array<IViewableStory> = hits.map(({ _highlightResult: _, objectID, ...rest }) => {
				const storySchema = (rest as Omit<IViewableStory, 'id'>)?.storySchema ?? DEFAULT_STORY_SCHEMA
				const story: IViewableStory = {
					id: objectID,
					...rest as Omit<IViewableStory, 'id'>,
					storySchema,
				}
				return story
			})

			dispatch(slice.actions.getPageSuccess({
				feedId,
				stories,
				storyCount: nbHits,
				pageNumber,
			}))
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFeedFailure({ feedId, error }))
		}
	}
}

interface StoryByIdProps {
	feedId: string
	storyId: string
}
interface GetStoryByIdResponse {
	story: IViewableStory | undefined
}
export function getStoryById({ feedId, storyId }:StoryByIdProps) {
	return async ():Promise<IViewableStory | undefined> => {
		const { dispatch } = store

		dispatch(slice.actions.startLoadingStory({ storyId, feedId }))
		try {
			const indexName = algoliaConfigs.primaryIndex

			const index = client.initIndex(indexName)

			const {
				hits, nbHits, page, nbPages,
			} = await index.search('', {
				attributesToRetrieve,
				filters: `isDeleted:false AND objectID:"${storyId}"`,
			})

			logger.log(hits, nbHits, page, nbPages)

			const stories: Array<IViewableStory> = hits.map(({ _highlightResult: _, objectID, ...rest }) => {
				const storySchema = (rest as Omit<IViewableStory, 'id'>)?.storySchema ?? DEFAULT_STORY_SCHEMA
				const story: IViewableStory = {
					id: objectID,
					...rest as Omit<IViewableStory, 'id'>,
					storySchema,
				}
				return story
			})

			if (stories.length === 0) {
				throw Error(`Story with id=[${storyId}] Not Found'`)
			}

			const story: IViewableStory = stories[0]

			dispatch(slice.actions.getStorySuccess({
				feedId,
				storyId,
				story,
			}))

			return story
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getStoryFailure({ storyId, error }))
		}
		return undefined
	}
}
/* export function getStoryById({ feedId, storyId }:StoryByIdProps) {
	return async ():Promise<IViewableStory | undefined> => {
		const { dispatch } = store

		dispatch(slice.actions.startLoadingStory({ storyId, feedId }))
		try {
			const functions = getFunctions()
			const fn = httpsCallable<{}, GetStoryByIdResponse>(functions, 'stories-fns-getStoryById')
			const requestData = {
				id: storyId,
			}
			// logger.log('stories-fns-getStoryById, requestData=', requestData)
			const { data } = await fn(requestData)

			// logger.log('stories-fns-getStoryById, response=', story)
			if (!data?.story) {
				throw Error(`Story with id=[${storyId}] Not Found'`)
			}

			dispatch(slice.actions.getStorySuccess({
				feedId,
				storyId,
				story: data.story,
			}))
			return data.story
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getStoryFailure({ storyId, error }))
		}
		return undefined
	}
} */

interface TrackImpressionProps {
	storyId: string
}

export function trackImpression({ storyId }:TrackImpressionProps) {
	return async () => {
		// const { dispatch } = store
		// dispatch(slice.actions.startLoading(feedId))
		// dispatch(slice.actions.hasChanged(false))

		try {
			const functions = getFunctions()
			const fn = httpsCallable<{}, GetFirstPageResponse>(functions, 'stories-feed-fns-trackImpression')
			const requestData = {
				storyId,
			}

			await fn(requestData)

			// logger.log('storyCount=', storyCount)
			// logger.log('stories=', stories)

			// dispatch(slice.actions.getFeedFirstPageSuccess({
			// 	feedId,
			// 	stories,
			// 	storyCount,
			// }))
		} catch (error) {
			logger.error(error)
			// dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

interface TrackEngagementProps {
	storyId: string
}

export function trackEngagement({ storyId }:TrackEngagementProps) {
	return async () => {
		// const { dispatch } = store
		// dispatch(slice.actions.startLoading(feedId))
		// dispatch(slice.actions.hasChanged(false))

		try {
			const functions = getFunctions()
			const fn = httpsCallable<{}, GetFirstPageResponse>(functions, 'stories-feed-fns-trackEngagement')
			const requestData = {
				storyId,
			}

			await fn(requestData)

			// logger.log('storyCount=', storyCount)
			// logger.log('stories=', stories)

			// dispatch(slice.actions.getFeedFirstPageSuccess({
			// 	feedId,
			// 	stories,
			// 	storyCount,
			// }))
		} catch (error) {
			// dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

interface UpdateStoryLikesProps {
	storyId: string
	changeBy: number
}

export function updateLikes(props:UpdateStoryLikesProps) {
	return async () => {
		try {
			logger.log('updateLikes', props)
			const functions = getFunctions()
			const fn = httpsCallable<{}, GetFirstPageResponse>(functions, 'stories-feed-fns-updateLikes')
			await fn(props)
		} catch (error) {
			// dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

export function setSortBy(sortBy: TForcedSort | null) {
	return () => {
		const { dispatch } = store
		dispatch(slice.actions.setSortBy(sortBy))
	}
}

// ----------------------------------------------------------------------

export function setQuery(query:string) {
	return () => {
		const { dispatch } = store
		dispatch(slice.actions.setQuery(query))
	}
}

// ----------------------------------------------------------------------

export function resetQuery() {
	return () => {
		const { dispatch } = store
		dispatch(slice.actions.resetQuery())
	}
}
