import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// @mui
import { styled, useTheme } from '@mui/material/styles'
import {
	Box, Button, AppBar, Toolbar, Container,
} from '@mui/material'
// hooks
import LogoJustIcon from 'src/components/LogoIcon'
import useOffSetTop from '../../hooks/useOffSetTop'
import useResponsive from '../../hooks/useResponsive'
// utils
import cssStyles from '../../utils/cssStyles'
// config
import { HEADER, START_CTA, WHITELIST_CTA } from '../../config'
// components
// import Logo from '../../components/Logo'
import Logo from '../../components/Logo3'
import Label from '../../components/Label'
//
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
import navConfig from './MenuConfig'
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths'
import useMixpanel, { MixpanelEvents, MixpanelEventTypes, MixpanelParentComponents } from '../../hooks/useMixpanel'

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
	height: HEADER.MOBILE_HEIGHT,
	transition: theme.transitions.create(['height', 'background-color'], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	[theme.breakpoints.up('md')]: {
		height: HEADER.MAIN_DESKTOP_HEIGHT,
	},
}))

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
	left: 0,
	right: 0,
	bottom: 0,
	height: 24,
	zIndex: -1,
	margin: 'auto',
	borderRadius: '50%',
	position: 'absolute',
	width: 'calc(100% - 48px)',
	boxShadow: theme.customShadows.z8,
}))

// ----------------------------------------------------------------------

export default function MainHeader() {
	const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)

	const { mixpanel } = useMixpanel()

	const navigate = useNavigate()

	const theme = useTheme()

	const { pathname } = useLocation()

	const isDesktop = useResponsive('up', 'md')

	const isHome = pathname === '/'

	return (
		<AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
			<ToolbarStyle
				disableGutters
				sx={{
					...(isOffset && {
						...cssStyles(theme).bgBlur(),
						height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
					}),
				}}
			>
				<Container
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<LogoJustIcon />

					{/* <Label color="info" sx={{ ml: 1 }}>
						v0.2.1
					</Label> */}

					<Box sx={{ flexGrow: 1 }} />

					{/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}

					{/* 	<Button
						variant="contained"
						target="_blank"
						rel="noopener"
						href="https://material-ui.com/store/items/minimal-dashboard/"
					>
						Connect Wallet
					</Button> */}
					{/* <Button
						variant="contained"
						onClick={() => {
							mixpanel.track(
								MixpanelEvents.GetStarted,
								{
									cta: START_CTA,
									type: MixpanelEventTypes.Interaction,
									parentComponent: MixpanelParentComponents.Header,
								},
							)
							navigate(PATH_AUTH.register)
						}}
					>
						{START_CTA}
					</Button> */}

					{/* {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
				</Container>
			</ToolbarStyle>

			{isOffset && <ToolbarShadowStyle />}
		</AppBar>
	)
}
