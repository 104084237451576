import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IPromotion } from 'src/types/IPromotion'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ICustomTokenSpaceResponse } from 'src/@types/space'
import { TPeopleState } from 'src/types/People'
import {
	doc, getDoc, getFirestore, onSnapshot, query,
	increment, serverTimestamp, setDoc, Timestamp, collection, getDocs, where, addDoc, Unsubscribe, orderBy, limit, collectionGroup, startAfter, QueryDocumentSnapshot, DocumentData, getCountFromServer,
} from 'firebase/firestore'
import { IPerson } from 'src/@types/people'
import keyBy from 'lodash/keyBy'
import { differenceInMinutes } from 'date-fns'
import { logger } from 'src/services/logger'

import {
	IStorySegmentFromDb, MyStorySegmentsState, queryablePublishedStates, TStorySegment, TStorySegmentPublishedStatus,
} from 'src/types/MyStorySegments'
import { store } from '../store'
// ----------------------------------------------------------------------

export type TSort = 'byCreatedDesc'

type TGetPageSuccessPayload = {
	items: Array<TStorySegment>
	lastDoc: QueryDocumentSnapshot<DocumentData> | null
	pageSize: number
	reset: boolean
}

type TGetTotalMoveSuccessPayload = {
	total: number
}

type TGetTotalPublishedMoveSuccessPayload = {
	total: number
}

type TSetPublishedStatusPayload = {
	id: string
	publishedStatus: TStorySegmentPublishedStatus
}

const initialState: MyStorySegmentsState = {
	isLoading: false,
	error: false,
	items: [],
	lastDoc: null,
	hasMore: true,
	totalMoves: null,
	totalPublishedMoves: 0,
}

const slice = createSlice({
	name: 'my-story-segments',
	initialState,
	reducers: {

		startLoadingPage(state) {
			state.isLoading = true
			state.error = false
		},

		getPageFailure(state) {
			state.isLoading = false
			state.error = true
		},

		getPageSuccess(state, action) {
			const {
				lastDoc, items, pageSize, reset,
			}: TGetPageSuccessPayload = action.payload

			state.lastDoc = lastDoc

			state.items = [
				...(!reset ? state.items : []),
				...items,
			]

			state.hasMore = items.length >= pageSize
			state.isLoading = false
			state.error = false
		},

		getTotalMovesSuccess(state, action) {
			const { total }: TGetTotalMoveSuccessPayload = action.payload
			state.totalMoves = total
		},

		getTotalPublishedMovesSuccess(state, action) {
			const { total }: TGetTotalPublishedMoveSuccessPayload = action.payload
			state.totalPublishedMoves = total
		},

		setPublishedStatus(state, action) {
			const { id, publishedStatus }: TSetPublishedStatusPayload = action.payload
			const idx = state.items.findIndex((item) => item.id === id)
			if (idx >= 0) {
				if (publishedStatus === 'PUBLISHED') {
					state.items[idx].publishedStatus = publishedStatus
				} else {
					state.items.splice(idx, 1)
				}
			}
		},

	},
})

// Reducer
export default slice.reducer

// --------------------------------------------------

interface GetPageProps {
	pageSize: number
	// pageNumber: number
	reset?: boolean
	uid: string
}

export function getPage(props:GetPageProps) {
	return async () => {
		logger.log('getPage', props)
		const { dispatch, getState } = store

		dispatch(slice.actions.startLoadingPage())

		try {
			const {
				 pageSize, reset, uid,
			} = props

			const db = getFirestore()
			const collectionGroupRef = collectionGroup(db, 'story_segments')

			let q

			if (reset) {
				q = query(
					collectionGroupRef,
					orderBy('createdAt', 'desc'),
					limit(pageSize),
					where('uid', '==', uid),
					where('publishedStatus', 'in', queryablePublishedStates),
				)
			} else {
				const { myStorySegments }:{myStorySegments: MyStorySegmentsState} = getState()
				logger.log('myStorySegments=', myStorySegments)
				const startAfterDoc: QueryDocumentSnapshot<DocumentData, DocumentData> = myStorySegments.lastDoc as QueryDocumentSnapshot<DocumentData, DocumentData>
				q	 = query(
					collectionGroupRef,
					orderBy('createdAt', 'desc'),
					limit(pageSize),
					startAfter(startAfterDoc),
					where('uid', '==', uid),
					where('publishedStatus', 'in', queryablePublishedStates),
				)
			}

			const querySnapshot = await getDocs(q)
			if (!querySnapshot.empty) {
				const items: Array<TStorySegment> = querySnapshot.docs.map((item) => {
					const segment: TStorySegment = {
						id: item.id,
						...(item.data() as Omit<IStorySegmentFromDb, 'id'>),
						createdAtDate: (item.data() as IStorySegmentFromDb).createdAt.toDate(),
					}
					return segment
				})

				dispatch(slice.actions.getPageSuccess({
					reset,
					items,
					lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
					pageSize,
				}))
			} else {
				dispatch(slice.actions.getPageSuccess({
					reset,
					items: [],
					lastDoc: null,
					pageSize,
				}))
			}
		} catch (err) {
			logger.error(err)
			dispatch(slice.actions.getPageFailure())
		}
	}
}

// --------------------------------------------------

interface GetTotalMovesProps {
	uid: string
}

export function getTotalMoves(props:GetTotalMovesProps) {
	return async () => {
		logger.log('getTotalMoves', props)
		const { dispatch } = store

		try {
			const {
				 uid,
			} = props

			const db = getFirestore()
			const collectionGroupRef = collectionGroup(db, 'story_segments')

			const q = query(
				collectionGroupRef,
				where('uid', '==', uid),
			)

			const querySnapshot = await getCountFromServer(q)

			dispatch(slice.actions.getTotalMovesSuccess({
				total: querySnapshot.data().count,
			}))
		} catch (err) {
			logger.error(err)
			// dispatch(slice.actions.getPageFailure())
		}
	}
}

// ----------------------------------------------------------------------

interface GetTotalPublishedMovesProps {
	uid: string
}

export function getTotalPublishedMoves(props:GetTotalPublishedMovesProps) {
	return async () => {
		logger.log('getTotalPublishedMoves', props)
		const { dispatch } = store

		try {
			const {
				 uid,
			} = props

			const db = getFirestore()
			const collectionGroupRef = collectionGroup(db, 'story_segments')

			const q = query(
				collectionGroupRef,
				where('uid', '==', uid),
				where('publishedStatus', '==', 'PUBLISHED'),
			)

			const querySnapshot = await getCountFromServer(q)

			dispatch(slice.actions.getTotalPublishedMovesSuccess({
				total: querySnapshot.data().count,
			}))
		} catch (err) {
			logger.error(err)
			// dispatch(slice.actions.getPageFailure())
		}
	}
}

// ----------------------------------------------------------------------

interface SetPublishStatus {
	id: string
	threadId: string
	publishedStatus: TStorySegmentPublishedStatus
}
export function setPublishedStatus(props: SetPublishStatus) {
	return async (dispatch: Dispatch) => {
		try {
			const { id, publishedStatus, threadId } = props
			dispatch(slice.actions.setPublishedStatus({ id, publishedStatus }))
			const db = getFirestore()
			const docRef = doc(db, 'threads', threadId, 'story_segments', id)

			const querySnapshot = await getDoc(docRef)

			if (querySnapshot.exists()) {
				await setDoc(querySnapshot.ref, {
					publishedStatus,
					updatedAt: serverTimestamp(),
				}, { merge: true })
			}
		} catch (err) {
			logger.error(err)
			// dispatch(slice.actions.getPageFailure())
		}
	}
}

// ----------------------------------------------------------------------
