import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IDemoState, TAuthView, TTab } from 'src/@types/demo'
import { IViewableStory } from 'src/@types/storiesViewable'
import { logger } from 'src/services/logger'
import { TFeedItem } from 'src/types/Feed'
import { IPromptSuggestionWithUserInfo } from 'src/types/PromptSuggestions'
import { IReprompt, TPromptForSegment } from 'src/types/Prompts'
import { TPageView } from 'src/types/views'

type TOpenDrawerPayload = {
	view: TPageView
	peopleProfileUid?: string | null
	spaceId?: string | null
	promptSuggestion?: IPromptSuggestionWithUserInfo | null
	feedItem?: TFeedItem | null
}

type TOpenThreadsDrawerPayload = {
	showThreadsWithIds?: Array<string>
	// showThreadsWithUid?: string | null
	// showThreadId?: string | null
	// reprompt?: IReprompt | null
}

type TSetActiveTabPayload = {
	tab: TTab
}
// ----------------------------------------------------------------------

const initialState: IDemoState = {
	isMuted: false,
	isThemeMusicEnabled: false,
	isAuthorDrawerOpen: false,
	isActionsDrawerOpen: false,
	isInfoDrawerOpen: false,
	selectedStory: null,
	isSuggestingLogin: false,
	suggestedAuthView: null,
	signInCaption: null,
	isShowingInfoBar: false,
	infoBarCaption: null,
	informationDrawerContent: null,
	drawerContents: null,
	// showPromptsForUid: null,
	// showThreadsWithUid: null,
	peopleProfileUid: null,
	promptSuggestion: null,
	reprompt: null,
	activeTab: 'world',
	// showThreadsDrawer: false,
	showThreadsWithIds: null,
	feedItem: null,
}

const slice = createSlice({
	name: 'demo',
	initialState,
	reducers: {
		// MUTE
		mute(state) {
			state.isMuted = true
		},

		unmute(state) {
			state.isMuted = false
		},

		enableThemeMusic(state) {
			state.isThemeMusicEnabled = true
		},

		disableThemeMusic(state) {
			state.isThemeMusicEnabled = false
		},

		authorDrawerSetOpen(state, action) {
			state.isAuthorDrawerOpen = action.payload
		},

		actionsDrawerSetOpen(state, action) {
			state.isActionsDrawerOpen = action.payload
		},

		infoDrawerSetOpen(state, action) {
			state.informationDrawerContent = action.payload
			state.isInfoDrawerOpen = true
		},

		infoDrawerSetClosed(state) {
			state.informationDrawerContent = null
			state.isInfoDrawerOpen = false
		},

		setSelectedStory(state, action) {
			state.selectedStory = action.payload
		},

		setSuggestLogin(state, action) {
			const { caption = null, suggest } = action.payload
			state.isSuggestingLogin = suggest
			state.signInCaption = suggest ? caption : null
		},

		setShowInfoBar(state, action) {
			const { caption = null, show } = action.payload
			state.isShowingInfoBar = show
			state.infoBarCaption = show ? caption : null
		},

		setSuggestedAuthView(state, action) {
			state.suggestedAuthView = action.payload
		},

		openDrawer(state, action) {
			const {
				view, peopleProfileUid = null, spaceId = null, feedItem = null,
				promptSuggestion = null,
			}:TOpenDrawerPayload = action.payload

			state.informationDrawerContent = action.payload
			state.drawerContents = view
			// state.showThreadsWithUid = showThreadsWithUid
			state.peopleProfileUid = peopleProfileUid
			state.spaceProfileId = spaceId
			// state.showThreadId = showThreadId
			state.promptSuggestion = promptSuggestion
			state.feedItem = feedItem
		},

		closeDrawer(state) {
			state.drawerContents = null
			// state.showThreadsWithUid = null
			state.peopleProfileUid = null
		},

		openThreadsDrawer(state, action) {
			const {
				// showThreadsWithUid = null, showThreadId = null, reprompt = null,TOpenThreadsDrawerPayloa
				showThreadsWithIds,
				// reprompt = null,
			}:TOpenThreadsDrawerPayload = action.payload
			// state.showThreadsWithUid = showThreadsWithUid
			// state.showThreadId = showThreadId
			state.showThreadsWithIds = showThreadsWithIds ?? null
			// state.reprompt = reprompt
			// state.showThreadsDrawer = true
		},

		closeThreadsDrawer(state) {
			state.showThreadsWithIds = null
			// state.reprompt = null
		},

		setActiveTab(state, action) {
			const {
				tab,
			}:TSetActiveTabPayload = action.payload

			state.activeTab = tab
		},

	},
})

// Reducer
export default slice.reducer

export const { actions } = slice

// ----------------------------------------------------------------------

export function setMute() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.mute())
	}
}

// ----------------------------------------------------------------------

export function setUnMute() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.unmute())
	}
}

// ----------------------------------------------------------------------

export function setEnableThemeMusic() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.enableThemeMusic())
	}
}

// ----------------------------------------------------------------------

export function setDisableThemeMusic() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.disableThemeMusic())
	}
}

export function setAuthorDrawerOpen() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.authorDrawerSetOpen(true))
	}
}

export function setAuthorDrawerClosed() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.authorDrawerSetOpen(false))
	}
}

export function setSelectedStory(story: IViewableStory) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSelectedStory(story))
	}
}

interface SetSuggestLoginProps {
	suggest: boolean
	caption?: string | null
}
export function setSuggestLogin({ suggest, caption = null }: SetSuggestLoginProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSuggestLogin({ suggest, caption }))
	}
}

interface InfoBarProps {
	show: boolean
	caption?: string
}
export function setShowInfoBar(props: InfoBarProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setShowInfoBar(props))
	}
}

export function setSuggestedAuthView(view:TAuthView | null) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSuggestedAuthView(view))
	}
}

export function setActionsDrawerOpen() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.actionsDrawerSetOpen(true))
	}
}

export function setActionsDrawerClosed() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.actionsDrawerSetOpen(false))
	}
}

// ----------------------------------------------------------------------

export function setInfoDrawerOpen(content: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.infoDrawerSetOpen(content))
	}
}

export function setInfoDrawerClosed() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.infoDrawerSetClosed())
	}
}

// ----------------------------------------------------------------------

interface OpenDrawerProps {
	view:TPageView
	// showPromptsForUid?: string
	// showThreadsWithUid? : string
	peopleProfileUid?: string
	spaceId?: string
	// showPromptId?: string
	promptSuggestion?: IPromptSuggestionWithUserInfo
	feedItem?: TFeedItem

}

export function openDrawer(props: OpenDrawerProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.openDrawer(props))
	}
}

export function closeDrawer() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.closeDrawer())
	}
}

export function closeThreadsDrawer() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.closeThreadsDrawer())
	}
}

interface OpenThreadsDrawerProps {
	// showThreadsWithUid? : string
	showThreadsWithIds?: Array<string>
	// showThreadId?: string
	// reprompt?: IReprompt
}
export function openThreadsDrawer(props: OpenThreadsDrawerProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.openThreadsDrawer(props))
	}
}

interface SetActiveTabProps {
	tab: TTab
}
export function setActiveTab({ tab }: SetActiveTabProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setActiveTab({ tab }))
	}
}

// ----------------------------------------------------------------------
