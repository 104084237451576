import { createSlice } from '@reduxjs/toolkit'
// utils
// @types
// import { FeedState } from '../../@types/feed'
import { CustomizedTokenPost, IPostComment } from 'src/@types/customizedToken'
import _ from 'lodash'
import { getFunctions, httpsCallable } from 'firebase/functions'
import axios from '../../utils/axios'
import { store } from '../store'

// ----------------------------------------------------------------------

function objFromArray(array: any[], key = 'id') {
	return array.reduce((accumulator, current) => {
		accumulator[current[key]] = current
		return accumulator
	}, {})
}

type FeedState = {
  isLoading: {
    byId: Record<string, boolean>
  }
	isLoadingComments: {
    byId: Record<string, boolean>
  }
  error: Error | string | null
  feeds: {
    byId: Record<string, Array<CustomizedTokenPost>>
  }
  comments: {
    byId: Record<string, Array<IPostComment>>
  }
  activeFeedId: null | string
	hasChanged: boolean
  // participants: Participant[]
  // recipients: any[]
};

const initialState: FeedState = {
	isLoading: {
		byId: {},
	},
	isLoadingComments: {
		byId: {},
	},
	error: null,
	feeds: {
		byId: {},
	},
	comments: {
		byId: {},
	},
	activeFeedId: null,
	hasChanged: false,
}

const slice = createSlice({
	name: 'feed',
	initialState,
	reducers: {

		// START LOADING POSTS
		startLoading(state, action) {
			const feedId = action.payload ?? 'DEFAULT'
			state.isLoading.byId[feedId] = true
		},

		// START LOADING COMMENTS
		startLoadingComments(state, action) {
			const postId = action.payload
			state.isLoadingComments.byId[postId] = true
		},

		// HAS ERROR
		hasError(state, action) {
			// state.isLoading = false
			state.error = action.payload
		},

		// GET POST FIRST PAGE
		getPostsFirstPageSuccess(state, action) {
			const { feedId = 'DEFAULT', posts }: {feedId?: string; posts: Array<CustomizedTokenPost> } = action.payload

			state.feeds.byId[feedId] = posts

			// Update the comments state
			posts.forEach((post) => {
				const currentComments = state.comments.byId[post.id] ? state.comments.byId[post.id] : []
				const commentIds:Array<string> = currentComments.map((comment) => comment.id)
				const commentsFilteredForDups:Array<IPostComment> = post.comments.filter((comment) => !commentIds.includes(comment.id))

				state.comments.byId[post.id] = [
					...currentComments,
					...commentsFilteredForDups,
				]
			})

			state.hasChanged = posts.length > 0
			state.isLoading.byId[feedId] = false
		},

		// GET POST NEXT PAGE
		getPostsNextPageSuccess(state, action) {
			const { feedId = 'DEFAULT', posts }: {feedId?: string; posts: Array<CustomizedTokenPost> } = action.payload

			const postIds:Array<string> = state.feeds.byId[feedId].map((post) => post.id)
			const filteredForDups:Array<CustomizedTokenPost> = posts.filter((post) => !postIds.includes(post.id))

			if (filteredForDups.length !== 0) {
				state.feeds.byId[feedId] = [
					...state.feeds.byId[feedId],
					...filteredForDups,
				]

				// Update the comments state
				state.feeds.byId[feedId].forEach((post) => {
					const currentComments = state.comments.byId[post.id] ? state.comments.byId[post.id] : []
					const commentIds:Array<string> = currentComments.map((comment) => comment.id)
					const commentsFilteredForDups:Array<IPostComment> = post.comments.filter((comment) => !commentIds.includes(comment.id))

					state.comments.byId[post.id] = [
						...currentComments,
						...commentsFilteredForDups,
					]
				})

				state.hasChanged = true
			}

			state.isLoading.byId[feedId] = false
		},

		// GET COMMENTS NEXT PAGE
		getCommentsNextPageSuccess(state, action) {
			const { postId, comments }: {postId: string; comments: Array<IPostComment> } = action.payload

			// console.log('getCommentsNextPageSuccess')
			// console.log('state.comments.byId[postId]=', state.comments.byId[postId])
			const currentComments = state.comments.byId[postId] ? state.comments.byId[postId] : []
			const commentIds:Array<string> = currentComments.map((comment) => comment.id)
			const filteredForDups:Array<IPostComment> = comments.filter((comment) => !commentIds.includes(comment.id))

			// console.log('commentIds=', commentIds)
			// console.log('filteredForDups=', filteredForDups)

			// filteredForDups.forEach((newComment) => {
			// 	state.comments.byId[postId].push(newComment)
			// })
			state.comments.byId[postId] = [
				...currentComments,
			 	...filteredForDups,
			 ]

			state.isLoadingComments.byId[postId] = false
		},

		hasChanged(state, action) {
			state.hasChanged = action.payload as boolean
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getFirstPage(feedId?: string) {
	return async () => {
		// console.log('getFirstPage, news-fns-nextFeedPage')
		const { dispatch } = store
		dispatch(slice.actions.startLoading(feedId))
		dispatch(slice.actions.hasChanged(false))
		try {
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'news-fns-nextFeedPage')
			const requestData = {
				...(feedId && {
					customTokenId: feedId,
				}),
			}

			// console.log('getFirstPage requestData=', requestData)

			const posts:Array<CustomizedTokenPost> = (await fn(requestData))?.data as Array<CustomizedTokenPost>

			dispatch(slice.actions.getPostsFirstPageSuccess({
				feedId,
				posts,
			}))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------
interface NextPageProps {
	feedId?: string
	startAfterMilliseconds: number
	startAfterCustomTokenId: string
}

export function getNextPage({ feedId, startAfterMilliseconds, startAfterCustomTokenId }:NextPageProps) {
	return async () => {
		// console.log('getNextPage, feedId=', feedId)
		// console.log('getNextPage, news-fns-nextFeedPage')
		const { dispatch } = store

		dispatch(slice.actions.startLoading(feedId))
		dispatch(slice.actions.hasChanged(false))
		try {
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'news-fns-nextFeedPage')
			const requestData = {
				startAfterMilliseconds,
				startAfterCustomTokenId,
				...(feedId && {
					customTokenId: feedId,
				}),
			}

			// console.log('getNextPage requestData=', requestData)

			const posts:Array<CustomizedTokenPost> = ((await fn(requestData))?.data ?? []) as Array<CustomizedTokenPost>

			// console.log('getNextPage, posts.length=', posts.length)

			dispatch(slice.actions.getPostsNextPageSuccess({
				feedId,
				posts,
			}))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

interface CommentsNextPageProps {
	postId: string
	startAfterMilliseconds: number
	startAfterCustomTokenId: string
}

export function getCommentsNextPage({
	postId, startAfterMilliseconds, startAfterCustomTokenId,
}:CommentsNextPageProps) {
	return async () => {
		// console.log('getCommentsNextPage, postId=', postId)
		const { dispatch } = store

		dispatch(slice.actions.startLoadingComments(postId))

		try {
			const functions = getFunctions()
			const fn = httpsCallable(functions, 'posts-fns-nextCommentsPage')
			const requestData = {
				startAfterMilliseconds,
				startAfterCustomTokenId,
				postId,
			}

			// console.log('getNextPage requestData=', requestData)

			const comments:Array<IPostComment> = ((await fn(requestData))?.data ?? []) as Array<IPostComment>

			// console.log('getCommentsNextPage, comments.length=', comments.length)
			// console.log('getCommentsNextPage, comments=', comments)

			dispatch(slice.actions.getCommentsNextPageSuccess({
				postId,
				comments,
			}))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
