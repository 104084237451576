import { createSlice } from '@reduxjs/toolkit'

import { logger } from 'src/services/logger'
import {
	IPromptSuggestionTopic,
	 TPromptSuggestion2State,
	 TPromptSuggestionTopicQuery,
} from 'src/types/PromptSuggestions'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { IPerson } from 'src/@types/people'
import { promptSuggestionKey } from 'src/utils/prompts'
import { TThreadChoiceWithConfig } from 'src/types/Prompts'
import { defaultPromptSuggesstionTopic } from 'src/configs/PromptSuggestionTopics'
import { dispatch } from '../store'
// ----------------------------------------------------------------------

// type TSuccessPayload = {
// 	suggestions: Array<IPromptSuggestionWithUserInfo>
// 	toUid: string
// }

type TStartLoadingPayload = {
	key: string
}

type TFailureLoadingPayload = {
	key: string
}

type TChoiceSuccessPayload = {
	choices: Array<TThreadChoiceWithConfig>
	key: string
}

export type TSetTopicPayload = {
	topic: IPromptSuggestionTopic | null
}

export type TSetSuggestionPayload = {
	suggestion: string | null
}

const initialState: TPromptSuggestion2State = {
	choicesByPromptSuggestionId: {},
	topic: defaultPromptSuggesstionTopic,
	suggestion: null,
}

const slice = createSlice({
	name: 'prompt-suggestion2',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state, action) {
			const { key }:TStartLoadingPayload = action.payload

			state.choicesByPromptSuggestionId[key] = {
				choices: [],
				choiceLoadingError: false,
				isLoadingChoices: true,
			}
		},

		// HAS ERROR
		getFailure(state, action) {
			const { key }:TFailureLoadingPayload = action.payload

			state.choicesByPromptSuggestionId[key] = {
				choices: [],
				choiceLoadingError: true,
				isLoadingChoices: false,
			}
		},

		getSuccess(state, action) {
			const { choices, key }:TChoiceSuccessPayload = action.payload
			// const key = promptSuggestionKey({ text, uid })
			state.choicesByPromptSuggestionId[key] = {
				choices: [
					...state.choicesByPromptSuggestionId[key].choices,
					...choices,
				],
				choiceLoadingError: false,
				isLoadingChoices: false,
			}
		},

		// clearPromptSuggestion(state, action) {
		// 	const { uid }:TClearPayload = action.payload
		// 	const key = promptSuggestionKey({ text, uid })
		// 	state.choicesByPromptSuggestionId[key] = {
		// 		choices: [],
		// 		choiceLoadingError: false,
		// 		isLoadingChoices: true,
		// 	}
		// },

		setTopic(state, action) {
			const { topic }:TSetTopicPayload = action.payload
			state.topic = topic
		},

		setSuggestion(state, action) {
			const { suggestion }:TSetSuggestionPayload = action.payload
			state.suggestion = suggestion
		},

	},
})

export const {
	setTopic,
	setSuggestion,
} = slice.actions
// Reducer
export default slice.reducer

interface GetChoicesResponse {
	choices: Array<TThreadChoiceWithConfig>
}

/* interface GetChoicesProps {
	toUser: IPerson
	text: string
	relationId: string
}

export function getChoicesForPromptSuggestion(props: GetChoicesProps) {
	return async () => {
		const { text, toUser, relationId } = props
		dispatch(slice.actions.startLoading({ text, uid: toUser.uid }))
		try {
			logger.log('getChoicesFromPromptSuggestion', props)
			const functions = getFunctions()
			const requestData = {
				toUser,
				text,
				relationId,
			} as any

			const { data } = await httpsCallable<{}, GetChoicesResponse>(functions, 'users-fns-getSuggestedPromptChoices2')(requestData)

			dispatch(slice.actions.getSuccess({
				uid: toUser.uid,
				text,
				choices: data.choices,
			}))
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFailure({ uid: toUser.uid, text }))
		}
	}
} */

// ----------------------------------------------------------------------

// interface GetChoices3Props {
// 	toUser: IPerson
// 	fromUser: IPerson
// 	text: string
// 	relationId: string
// }

// export function getChoicesForPromptSuggestion3(props: GetChoices3Props) {
// 	return async () => {
// 		const {
// 			text, toUser, fromUser, relationId,
// 		} = props
// 		dispatch(slice.actions.startLoading(<TStartLoadingPayload>{ key, uid: toUser.uid }))
// 		try {
// 			logger.log('getChoicesForPromptSuggestion3', props)
// 			const functions = getFunctions()
// 			const requestData = {
// 				toUser,
// 				fromUser,
// 				text,
// 				relationId,
// 			} as any

// 			const { data } = await httpsCallable<{}, GetChoicesResponse>(functions, 'users-fns-getSuggestedPromptChoices3')(requestData)

// 			dispatch(slice.actions.getSuccess({
// 				uid: toUser.uid,
// 				text,
// 				choices: data.choices,
// 			}))
// 		} catch (error) {
// 			logger.error(error)
// 			dispatch(slice.actions.getFailure({ uid: toUser.uid, text }))
// 		}
// 	}
// }

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface GetChoices4Props {
	toUser: IPerson
	fromUser: IPerson
	text: string | null
	relationId: string
	key: string
	query: TPromptSuggestionTopicQuery
}

export function getChoicesForPromptSuggestion4(props: GetChoices4Props) {
	return async () => {
		const {
			key,
		} = props
		dispatch(slice.actions.startLoading(<TStartLoadingPayload>{ key }))
		try {
			logger.log('getChoicesForPromptSuggestion4', props)
			const functions = getFunctions()

			const { data } = await httpsCallable<{}, GetChoicesResponse>(functions, 'threads-fns-getSuggestedPromptChoices')(props)

			dispatch(slice.actions.getSuccess(<TChoiceSuccessPayload>{
				key,
				choices: data.choices,
			}))
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFailure(<TFailureLoadingPayload>{ key }))
		}
	}
}

// ----------------------------------------------------------------------

// interface SelectSuggestedPromptChoiceProps {
// 	text: string
// 	toUid: string
// }
// export function selectSuggestedPromptChoice(props: SelectSuggestedPromptChoiceProps) {
// 	return async () => {
// 		try {
// 			logger.log('selectSuggestedPromptChoice')
// 			const functions = getFunctions()
// 			const requestData = {
// 				...props,
// 			} as any

// 			await httpsCallable(functions, 'users-fns-submitPromptSuggestion')(requestData)
// 			// await httpsCallable(functions, 'users-fns-selectSuggestedPromptChoice')(requestData)
// 		} catch (error) {
// 			logger.error(error)
// 		}
// 	}
// }

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
