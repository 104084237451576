import React from 'react'
import { useLocation, Outlet } from 'react-router-dom'
// @mui
import {
	Box, Link, Container, Typography, Stack,
} from '@mui/material'
// components
import { COMPANY } from 'src/config'
import LogoJustIcon from '../../components/LogoIcon'
//
import MainFooter from './MainFooter'
import MainHeader from './MainHeader'

// ----------------------------------------------------------------------

export default function MainLayout() {
	const { pathname } = useLocation()

	const isHome = pathname === '/'

	return (
		<Stack sx={{ minHeight: 1 }}>
			<MainHeader />

			<Outlet />

			<Box sx={{ flexGrow: 1 }} />

			{/* 	{!isHome ? (
				<MainFooter />
			) : (
				<Box
					sx={{
						py: 5,
						textAlign: 'center',
						position: 'relative',
						bgcolor: 'background.default',
					}}
				>
					<Container>
						<LogoJustIcon sx={{ mb: 1, mx: 'auto' }} />

						<Typography variant="caption" component="p">
							©
							{' '}
							{COMPANY}
						</Typography>
					</Container>
				</Box>
			)} */}

			<Box
				sx={{
					py: 5,
					textAlign: 'center',
					position: 'relative',
					bgcolor: 'background.default',
				}}
			>
				<Container>
					<LogoJustIcon sx={{ mb: 1, mx: 'auto' }} />

					<Typography variant="caption" component="p">
						©
						{' '}
						{COMPANY}
					</Typography>
				</Container>
			</Box>

			{/* <HubSpot /> */}
		</Stack>
	)
}
