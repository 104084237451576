import { createSlice } from '@reduxjs/toolkit'
import { logger } from 'src/services/logger'
import {
	TChoiceMode,
	 TPromptChoiceDirection, TThreadChoiceWithConfig,
} from 'src/types/Prompts'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
	 TPromptChoiceThemeId, TPromptsChoicesSearchParams, TPromptsChoicesSearchParamsWithChoiceState, TPromptsChoicesState,
} from 'src/types/PromptChoices'
import {
	initialSearchParamsState,
} from 'src/configs/PromptChoices'
import { IPerson } from 'src/@types/people'
import { shuffle } from 'lodash'
import { dispatch } from '../store'
// ----------------------------------------------------------------------

type TSuccessPayload = {
	threadId: string
	searchKey: string
	choices: Array<TThreadChoiceWithConfig>
}

type TStartLoadingPayload = {
	threadId: string
	searchKey: string
}
type TFailureLoadingPayload = {
	threadId: string
	searchKey: string
}

type TResetPayload = {
	threadId: string
	searchKey: string
}
// type TSetThemePayload = {
// 	theme: string
// 	promptId: string
// }

type TSetThemePayload = {
	promptChoicesThemeId: TPromptChoiceThemeId | null
	threadId: string
}

type TSetDirectionPayload = {
	direction: TPromptChoiceDirection
	threadId: string
}

// type TSetEditModePayload = {
// 	editMode: TPromptChoiceEditMode
// 	threadId: string
// }

export type TSetSuggestionPayload = {
	suggestion: string | null
	suggestionCategoryId?: string | null
	threadId: string
}

export type TSetChoiceModePayload = {
	mode: TChoiceMode
	threadId: string
}

// type TUpdateSearchKeyPayload = {
// 	threadId: string
// }

// const exitPromptChoices:Array<TNewPromptChoice> = [
// 	{
// 		id: 'exit_1',
// 		text: 'I quit!',
// 		theme: exitPromptChoiceThemeLight,
// 	},
// 	{
// 		id: 'exit_2',
// 		text: 'I\'m so done',
// 		theme: exitPromptChoiceThemeLight,
// 	},
// 	{
// 		id: 'exit_3',
// 		text: 'I\'m exhausted',
// 		theme: exitPromptChoiceThemeLight,
// 	},
// ]

const initialState: TPromptsChoicesState = {
	byThreadId: {},
}

const slice = createSlice({
	name: 'promptChoices',
	initialState,
	reducers: {
		// START LOADING
		resetChoicesByKey(state, action) {
			const { threadId, searchKey }:TResetPayload = action.payload

			const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

			const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

			state.byThreadId[threadId] = {
				...(prevState ?? {}),
				searchParams: prevSearchParams,
				choiceState: {
					...prevState.choiceState,
					[searchKey]: {
						error: false,
						isLoaded: false,
						isLoading: false,
						choices: [],
					},
				},
				mode: 'suggest',
			}
		},

		startLoading(state, action) {
			const { threadId, searchKey }:TStartLoadingPayload = action.payload

			const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

			const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

			state.byThreadId[threadId] = {
				...(prevState ?? {}),
				searchParams: prevSearchParams,
				choiceState: {
					...prevState.choiceState,
					[searchKey]: {
						error: false,
						// isLoaded: prevState?.choiceState?.[searchKey]?.isLoaded ?? false,
						isLoaded: false,
						isLoading: true,
						choices: [
							...(prevState?.choiceState?.[searchKey]?.choices ?? []),
						],
					},
				},
				mode: 'suggest',
			}
		},

		// HAS ERROR
		getFailure(state, action) {
			const { threadId, searchKey }:TFailureLoadingPayload = action.payload

			const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

			const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

			state.byThreadId[threadId] = {
				...(prevState ?? {}),
				searchParams: prevSearchParams,
				choiceState: {
					...prevState.choiceState,
					[searchKey]: {
						error: true,
						isLoaded: true,
						isLoading: false,
						choices: [
							...(prevState.choiceState?.[searchKey]?.choices ?? []),
						],
					},
				},
			}
		},

		// GET PROMOTIONS
		getSuccess(state, action) {
			const { threadId, choices, searchKey }:TSuccessPayload = action.payload

			const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

			const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

			state.byThreadId[threadId] = {
				...(prevState ?? {}),
				searchParams: prevSearchParams,
				choiceState: {
					...prevState.choiceState,
					[searchKey]: {
						error: false,
						isLoaded: true,
						isLoading: false,
						choices: [
							...(prevState.choiceState?.[searchKey]?.choices ?? []),
							...choices,
						],
					},
				},
			}

			// logger.log({ ...state.choicesByPromptId })
		},

		setTheme(state, action) {
			const { promptChoicesThemeId, threadId }:TSetThemePayload = action.payload

			const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

			const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

			state.byThreadId[threadId] = {
				...(prevState ?? {}),
				searchParams: {
					...prevSearchParams,
					promptChoicesThemeId,
				},
				choiceState: {
					...(prevState?.choiceState ?? {}),
				},
			}
		},

		setDirection(state, action) {
			const { direction, threadId }:TSetDirectionPayload = action.payload

			const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

			const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

			// logger.log('setDirection, prevSearchParams=', { ...prevSearchParams })
			state.byThreadId[threadId] = {
				...(prevState ?? {}),
				searchParams: {
					...prevSearchParams,
					direction,
				},
				choiceState: {
					...(prevState?.choiceState ?? {}),
				},
			}
		},

		// setEditMode(state, action) {
		// 	const { editMode, threadId }:TSetEditModePayload = action.payload

		// 	const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

		// 	const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

		// 	// logger.log('setEditMode, prevSearchParams=', { ...prevSearchParams })
		// 	state.byThreadId[threadId] = {
		// 		searchParams: {
		// 			...prevSearchParams,
		// 			editMode,
		// 		},
		// 		choiceState: {
		// 			...(prevState?.choiceState ?? {}),
		// 		},
		// 	}
		// },

		setSuggestion(state, action) {
			const { suggestion, threadId, suggestionCategoryId = null }:TSetSuggestionPayload = action.payload

			const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

			const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

			// logger.log('setSuggestion, prevSearchParams=', { ...prevSearchParams })
			state.byThreadId[threadId] = {
				...(prevState ?? {}),
				searchParams: {
					...prevSearchParams,
					suggestion,
					suggestionCategoryId,
				},
				choiceState: {
					...(prevState?.choiceState ?? {}),
				},
			}
		},

		setChoiceMode(state, action) {
			const { mode, threadId }:TSetChoiceModePayload = action.payload

			const prevState: TPromptsChoicesSearchParamsWithChoiceState | undefined = state.byThreadId?.[threadId]

			const prevSearchParams: TPromptsChoicesSearchParams = { ...(prevState?.searchParams ?? initialSearchParamsState) }

			state.byThreadId[threadId] = {
				...(prevState ?? {}),
				searchParams: {
					...prevSearchParams,
				},
				choiceState: {
					...(prevState?.choiceState ?? {}),
				},
				mode,
			}
		},
	},
})

export const {
	setDirection,
	setTheme,
	// setEditMode,
	setSuggestion,
	resetChoicesByKey,
	setChoiceMode,
} = slice.actions

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetChoicesResponse {
	choices: Array<TThreadChoiceWithConfig>
}

// interface GetChoicesProps {
// 	relationId?: string
// 	prompt: Pick<IPrompt, 'to' | 'from' | 'prompt' | 'prePrompt' | 'postPrompt' | 'id'>
// 	user: Pick<IUser, 'firstName' | 'lastName' | 'gender'>
// 	suggestion?: string | null
// 	suggestionCategoryId?: string | null
// 	previousChoices?: Array<TNewPromptChoice>
// 	// theme?: string
// 	choicesTheme: IPromptChoiceTheme
// 	direction?: TPromptChoiceDirection
// 	isReprompt?: boolean
// 	summary: string | null
// }

// export function getChoices(props: GetChoicesProps) {
// 	return async () => {
// 		const {
// 			relationId = 4, prompt, user, suggestion, suggestionCategoryId, previousChoices = [],
// 			direction = 'continue', isReprompt = false,
// 			choicesTheme, summary,
// 		} = props
// 		logger.log('getChoices', props)
// 		dispatch(slice.actions.startLoading({ promptId: prompt.id }))
// 		try {
// 			/* if (choicesTheme.cmd === 'exit') {
// 				const keyedChoices:Array<TNewPromptChoice> = exitPromptChoices.map((choice) => ({
// 					...choice,
// 					id: uniqId(),
// 				}))
// 				dispatch(slice.actions.getSuccess({ promptId: prompt.id, choices: keyedChoices }))
// 				return
// 			} */

// 			const functions = getFunctions()
// 			const requestData = {
// 				relationId,
// 				previousChoices,
// 				prompt: {
// 					 postPrompt: prompt.postPrompt,
// 					 prePrompt: prompt.prePrompt,
// 					 prompt: prompt.prompt,
// 					 to: prompt.to,
// 					 from: prompt.from,
// 				},
// 				user: {
// 					firstName: user.firstName,
// 					lastName: user?.lastName,
// 					gender: user.gender,
// 				},
// 				direction,
// 				choicesTheme,
// 				...(suggestion && {
// 					suggestion,
// 				}),
// 				...(suggestionCategoryId && {
// 					suggestionCategoryId,
// 				}),
// 				isReprompt,
// 				summary,
// 			} as any

// 			const { data } = await httpsCallable<{}, GetChoicesResponse>(functions, 'users-fns-getPromptChoices')(requestData)

// 			dispatch(slice.actions.getSuccess({ promptId: prompt.id, choices: data.choices }))
// 		} catch (error) {
// 			logger.error(error)
// 			dispatch(slice.actions.getFailure({ promptId: prompt.id }))
// 		}
// 	}
// }

interface GetChoicesProps {
	relationId?: string
	threadId: string
	// message: string
	to: IPerson
	from: IPerson
	searchParams: TPromptsChoicesSearchParams
	// suggestion?: string | null
	// suggestionCategoryId?: string | null
	previousChoices?: Array<TThreadChoiceWithConfig>
	// promptChoicesThemeId: TPromptChoiceThemeId | null
	// direction?: TPromptChoiceDirection
	isReprompt?: boolean
	// threadSummary: IThreadSummary
	summary: string | null
	searchKey: string
}

export function getChoices(props: GetChoicesProps) {
	return async () => {
		const {
			relationId = 4,
			to,
			from, /* message, */
			threadId,
			// suggestion,
			// suggestionCategoryId,
			// direction = 'continue'
			// promptChoicesThemeId,
			previousChoices = [],
			searchParams,
			isReprompt = false,
			// threadSummary,
			// summary,
			searchKey,
			summary,
		} = props
		// logger.log('getChoices', props)
		// const searchKey = searchKeyHash({
		// 	...searchParams,
		// 	threadSummaryId: threadSummary?.id,
		// 	threadId,
		// })
		dispatch(slice.actions.startLoading({ searchKey, threadId }))
		try {
			// throw Error()

			const functions = getFunctions()
			const requestData = {
				threadId,
				relationId,
				previousChoices,
				to,
				from,
				...searchParams,
				isReprompt,
				...(!!summary && {
					summary,
				}),
			} as any

			logger.log('getChoices, requestData=', requestData)
			const { data } = await httpsCallable<{}, GetChoicesResponse>(functions, 'threads-fns-getChoices', { timeout: 15000 })(requestData)

			const shuffledChoices: Array<TThreadChoiceWithConfig> = shuffle(data.choices)
			logger.log('getChoices, response=', data)
			dispatch(slice.actions.getSuccess({ searchKey, threadId, choices: shuffledChoices }))
		} catch (error) {
			logger.error(error)
			dispatch(slice.actions.getFailure({ searchKey, threadId }))
		}
	}
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// interface GetChoicesV2Props {
// 	prompt: IPrompt
// 	user: Pick<IUser, 'firstName' | 'lastName'>
// 	suggestion?: string
// }

// export function getChoicesv2(props: GetChoicesV2Props) {
// 	const { prompt, user, suggestion } = props
// 	return async () => {
// 		dispatch(slice.actions.startLoadingChoices())
// 		try {
// 			logger.log('getChoicesv2', props)
// 			const functions = getFunctions()
// 			const requestData = {
// 				prompt,
// 				user,
// 				...(suggestion && {
// 					suggestion,
// 				}),
// 			} as any

// 			const { data } = await httpsCallable<{}, GetChoicesResponse>(functions, 'users-fns-getPromptChoices')(requestData)

// 			logger.log('data from getChoices=', data)
// 			const newPrompt:IPrompt = {
// 				...prompt,
// 				choices: [...prompt.choices, ...data.choices],
// 				choiceHistory: [...prompt.choiceHistory, ...data.choices],
// 			}

// 			// console.log('prompt plus new choices=', newPrompt)
// 			dispatch(slice.actions.getPromptChoicesSuccess({ prompt: newPrompt }))
// 		} catch (error) {
// 			logger.error(error)
// 			dispatch(slice.actions.failedLoadingChoices())
// 		}
// 	}
// }

// // ----------------------------------------------------------------------
