import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import uniq from 'lodash/uniq'

import { createSlice, Dispatch } from '@reduxjs/toolkit'
// utils
import {
	addDoc, arrayRemove, collection, doc, getDoc, getDocs, getFirestore, increment, query, setDoc, Timestamp, updateDoc, where,
} from 'firebase/firestore'
import {
	IModCard, IModPrompts, IModsBoard, IModsColumn, IModsState2,
} from 'src/@types/mods'
import { getFunctions, httpsCallable } from 'firebase/functions'

// @types
import { IKanbanState, IKanbanCard, IKanbanColumn } from '../../@types/kanban'

// ----------------------------------------------------------------------

const initialState: IModsState2 = {
	isLoading: false,
	isSaving: {},
	isCreatingMod: false,
	promptsIsLoading: false,
	error: null,
	previewModId: null,
	prompts: [],
	board: {
		mods: {},
		columns: {},
		columnOrder: [],
		version: 0,
	},
}

const slice = createSlice({
	name: 'mods2',
	initialState,
	reducers: {

		// START SAVING
		startSaving(state, action) {
			const modId = action.payload
			state.isSaving[modId] = true
		},

		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// START LOADING
		startCreatingMod(state) {
			state.isCreatingMod = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		// GET BOARD
		getBoardSuccess(state, action) {
			const board = action.payload
			const mods = keyBy(board.mods, 'id')
			const columns = keyBy(board.columns, 'id')
			const { columnOrder } = board

			state.board = {
				...state.board,
				mods,
				columns,
				columnOrder,
			}

			state.isLoading = false
		},

		// CREATE NEW COLUMN
		createColumnSuccess(state, action) {
			const newColumn = action.payload
			state.isLoading = false
			state.board.columns = {
				...state.board.columns,
				[newColumn.id]: newColumn,
			}
			state.board.columnOrder.push(newColumn.id)
		},

		persistCard(state, action) {
			const columns = action.payload
			console.log(
				columns,
			)
			state.board.columns = columns
			state.board.version += 1
		},

		// persistColumn(state, action) {
		// 	state.board.columnOrder = action.payload
		// },

		addMod(state, action) {
			const { mod, columnId } = action.payload

			state.board.mods[mod.id] = mod
			state.board.columns[columnId].modIds.unshift(mod.id)
			state.board.version += 1
			state.isCreatingMod = false
		},

		// UPDATE MOD
		updateMod(state, action) {
			const { mod } = action.payload
			state.board.mods[mod.id] = merge(state.board.mods[mod.id], mod)
			if (mod?.selectedActionIds) {
				state.board.mods[mod.id].selectedActionIds = mod.selectedActionIds
			}
			state.isSaving[mod.id] = false
			state.board.version += 1
		},

		deleteMod(state, action) {
			const { modId, columnId } = action.payload

			console.log(action.payload)
			console.log(state.board.columns)
			state.board.columns[columnId].modIds = state.board.columns[columnId].modIds.filter(
				(id) => id !== modId,
			)

			state.board.mods = omit(state.board.mods, [modId])
			state.board.version += 1
		},

		setPreviewModId(state, action) {
			state.previewModId = action.payload
		},

		clearPreviewModId(state) {
			state.previewModId = null
		},

		// START LOADING
		promptsStartLoading(state) {
			state.promptsIsLoading = true
			state.prompts = []
		},

		// GET MOD PROMPTS SUCCESS
		getPromptsSuccess(state, action) {
			state.promptsIsLoading = false
			const { prompts } = action.payload
			state.prompts = prompts
		},
		// UPDATE COLUMN
		// updateColumnSuccess(state, action) {
		// 	const column = action.payload

		// 	state.isLoading = false
		// 	state.board.columns[column.id] = column
		// },

		// DELETE COLUMN
		// deleteColumnSuccess(state, action) {
		// 	const { columnId } = action.payload
		// 	const deletedColumn = state.board.columns[columnId]

		// 	state.isLoading = false
		// 	state.board.columns = omit(state.board.columns, [columnId])
		// 	state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds])
		// 	state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId)
		// },
	},
})

// Reducer
export default slice.reducer

export const { actions } = slice

// ----------------------------------------------------------------------

// export function getBoard() {
// 	return async (dispatch: Dispatch) => {
// 		dispatch(slice.actions.startLoading())
// 		try {
// 			const response = await axios.get('/api/kanban/board')
// 			dispatch(slice.actions.getBoardSuccess(response.data.board))
// 		} catch (error) {
// 			dispatch(slice.actions.hasError(error))
// 		}
// 	}
// }

/* export function getBoardOrig() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const data = {
				board: {
					cards: [{
						id: 'deb02f04-9cf8-4f1e-97e0-2fbda84cc6b3',
						name: 'Call with sales of HubSpot',
						description: 'Duis condimentum lacus finibus felis pellentesque, ac auctor nibh fermentum. Duis sed dui ante. Phasellus id eros tincidunt, dictum lorem vitae, pellentesque sem. Aenean eu enim sit amet mauris rhoncus mollis. Sed enim turpis, porta a felis et, luctus faucibus nisi. Phasellus et metus fermentum, ultrices arcu aliquam, facilisis justo. Cras nunc nunc, elementum sed euismod ut, maximus eget nibh. Phasellus condimentum lorem neque. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce sagittis pharetra eleifend. Suspendisse potenti.',
						assignee: [{ id: '473d2720-341c-49bf-94ed-556999cf6ef7', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg', name: 'Lucian Obrien' }],
						due: [1681161412496, 1681247812496],
						attachments: [],
						comments: [],
						completed: true,
					}, {
						id: '98bf6e8b-becc-485b-9c3f-a7d09392c48d', name: 'Interview for the Asis. Sales Manager', description: 'We are looking for vue experience and of course node js strong knowledge', assignee: [{ id: '473d2720-341c-49bf-94ed-556999cf6ef7', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg', name: 'Deja Brady' }, { id: 'b8395203-887c-46f5-a85f-339b2d75c98b', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg', name: 'Harrison Stein' }, { id: '18e23ac9-c874-43e4-8163-2d37f15f3367', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg', name: 'Reece Chung' }, { id: 'a3be5485-03bf-47a6-b553-a9cf9f070ed8', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg', name: 'Lainey Davidson' }, { id: '048f6343-7a65-4873-a570-eb6ff4eb1ba3', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_6.jpg', name: 'Cristopher Cardenas' }], due: [1681161412496, 1681247812496], attachments: ['https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_2.jpg'], comments: [], completed: false,
					}, {
						id: '99fbc02c-de89-4be3-9515-f8bd12227d38', name: 'Change the height of the top bar because it looks too chunky', description: 'We nede to make it aggressive with pricing because it’s in their interest to acquire us', assignee: [], due: [null, null], attachments: [], comments: [], completed: true,
					}, {
						id: 'ab9cebca-6cb4-4847-aa17-3b261b3dd0fb', name: 'Integrate Stripe API', description: 'We nede to make it aggresive with pricing because it’s in their interest to acquire us', assignee: [{ id: 'b8395203-887c-46f5-a85f-339b2d75c98b', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg', name: 'Melanie Noble' }, { id: 'a3be5485-03bf-47a6-b553-a9cf9f070ed8', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_6.jpg', name: 'Chase Day' }], due: [null, null], attachments: ['https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_4.jpg'], comments: [], completed: false,
					}, {
						id: 'ebf0d26a-78e5-414f-986f-003d8fcd3154', name: 'Update the customer API for payments', description: 'We need to make it aggresive with pricing because it’s in their interest to acquire us', assignee: [{ id: '473d2720-341c-49bf-94ed-556999cf6ef7', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg', name: 'Shawn Manning' }], due: [null, null], attachments: [], comments: [], completed: true,
					}, {
						id: '9d98ce30-3c51-4de3-8537-7a4b663ee3af', name: 'Release minimals DS', description: 'Production', assignee: [{ id: '473d2720-341c-49bf-94ed-556999cf6ef7', avatar: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg', name: 'Soren Durham' }], due: [null, null], attachments: [], comments: [], completed: true,
					}],
					columns: [{ id: '8cd887ec-b3bc-11eb-8529-0242ac130003', name: 'Backlog', cardIds: ['deb02f04-9cf8-4f1e-97e0-2fbda84cc6b3', '98bf6e8b-becc-485b-9c3f-a7d09392c48d', '99fbc02c-de89-4be3-9515-f8bd12227d38'] }, { id: '23008a1f-ad94-4771-b85c-3566755afab7', name: 'Progress', cardIds: ['ab9cebca-6cb4-4847-aa17-3b261b3dd0fb', 'ebf0d26a-78e5-414f-986f-003d8fcd3154'] }, { id: '37a9a747-f732-4587-a866-88d51c037641', name: 'Q&A', cardIds: [] }, { id: '4ac3cd37-b3e1-466a-8e3b-d7d88f6f5d4f', name: 'Production', cardIds: ['9d98ce30-3c51-4de3-8537-7a4b663ee3af'] }],
					columnOrder: ['8cd887ec-b3bc-11eb-8529-0242ac130003', '23008a1f-ad94-4771-b85c-3566755afab7', '37a9a747-f732-4587-a866-88d51c037641', '4ac3cd37-b3e1-466a-8e3b-d7d88f6f5d4f'],
				},
			}
			dispatch(slice.actions.getBoardSuccess(data.board))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
} */

interface GetModsProps {
	customTokenId: string
}

export function getBoard({ customTokenId }:GetModsProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const db = getFirestore()

			const boardDocRef = doc(db, 'customizedTokens', customTokenId, 'customizations', 'board')
			const modsCollectionRef = collection(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'mods')
			const columnsCollectionRef = collection(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'columns')

			const boardDocSnap = await getDoc(boardDocRef)
			const columnOrder: Array<string> = boardDocSnap.exists() ? boardDocSnap.data()?.columnOrder : []

			const modsQuerySnapshot = await getDocs(query(modsCollectionRef, where('isDeleted', '!=', true)))
			const mods: Array<IModCard> = modsQuerySnapshot.docs.map((doc) => {
				// console.log(doc.id, ' => ', doc.data())
				const mod : IModCard = {
					id: doc.id,
					selectedActionIds: doc.data()?.selectedActionIds ?? [],
					...doc.data() as Omit<IModCard, 'id' | 'selectedActionIds'>,
				}
				return mod
			})

			const columnsQuerySnapshot = await getDocs(query(columnsCollectionRef))
			const columns: Array<IModsColumn> = columnsQuerySnapshot.docs.map((doc) => {
				const column : IModsColumn = {
					id: doc.id,
					...doc.data() as Omit<IModsColumn, 'id'>,
				}
				return column
			})

			// console.log('getBoard=', {
			// 	mods,
	 		// 	columns,
			// 	columnOrder,
			// })

			dispatch(slice.actions.getBoardSuccess({
				mods,
	 			columns,
				columnOrder,
			}))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

// export function createColumn(newColumn: { name: string }) {
// 	return async (dispatch: Dispatch) => {
// 		dispatch(slice.actions.startLoading())
// 		try {
// 			const response = await axios.post('/api/kanban/columns/new', newColumn)
// 			dispatch(slice.actions.createColumnSuccess(response.data.column))
// 		} catch (error) {
// 			dispatch(slice.actions.hasError(error))
// 		}
// 	}
// }

// ----------------------------------------------------------------------

interface CreateModProps {
	customTokenId: string
	columnId?: string
}

export function createMod({ customTokenId, columnId }: CreateModProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startCreatingMod())
		const db = getFirestore()

		let operatingColumndId = columnId
		if (!columnId) {
			const columnDocRef = await addDoc(collection(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'columns'), {
				modIds: [],
			})
			operatingColumndId = columnDocRef.id
			const newColumn: IModsColumn = {
				id: operatingColumndId,
				modIds: [],
			}

			const boardDocRef = doc(db, 'customizedTokens', customTokenId, 'customizations', 'board')
			await setDoc(boardDocRef, { columnOrder: [operatingColumndId] }, { merge: true })

			dispatch(slice.actions.createColumnSuccess(newColumn))
		}

		const newMod: Omit<IModCard, 'id'> = {
			promptId: '3',
			isEnabled: false,
			isDeleted: false,
			updatedAtMilliseconds: Timestamp.now().toMillis(),
			createdAtMilliseconds: Timestamp.now().toMillis(),
			version: increment(1),
			selectedActionIds: [],
		}

		const modDocRef = await addDoc(collection(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'mods'), newMod)

		const columnDocRef = doc(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'columns', operatingColumndId as string)

		const columnDocSnap = await getDoc(columnDocRef)

		const modIds: Array<string> = (columnDocSnap.data() as IModsColumn)?.modIds ?? []

		modIds.unshift(modDocRef.id)

		await setDoc(columnDocRef, { modIds }, { merge: true })

		const createdMod: IModCard = {
			id: modDocRef.id,
			...newMod,
		}
		console.log({ mod: createdMod, columnId: operatingColumndId })
		// dispatch(slice.actions.addTask({ card, columnId }))
		dispatch(slice.actions.addMod({ mod: createdMod, columnId: operatingColumndId }))
	}
}

// ----------------------------------------------------------------------

interface UpdateModsProps {
	customTokenId: string
	modId: string
	partialMod:Partial<IModCard>
}
export function updateMod({ modId, customTokenId, partialMod }:UpdateModsProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startSaving(modId))
		const db = getFirestore()
		const values: Partial<IModCard> = {
			...partialMod,
			updatedAtMilliseconds: Timestamp.now().toMillis(),
			version: increment(1),
		}

		console.log('values updted in db=', values)
		console.log('values updted in db, customTokenId=', customTokenId)
		console.log('values updted in db, modId=', modId)

		dispatch(slice.actions.updateMod({
			mod: {
				id: modId,
				...values,
		 },
		}))

		const modDocRef = doc(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'mods', modId)
		await setDoc(modDocRef, values, { merge: true })

	/* 	dispatch(slice.actions.updateMod({
			mod: {
				id: modId,
				...values,
		 },
		})) */
	}
}

// ----------------------------------------------------------------------

// export function updateColumn(columnId: string, column: IKanbanColumn) {
// 	return async (dispatch: Dispatch) => {
// 		dispatch(slice.actions.startLoading())
// 		try {
// 			const response = await axios.post('/api/kanban/columns/update', {
// 				columnId,
// 				column,
// 			})
// 			dispatch(slice.actions.updateColumnSuccess(response.data.column))
// 		} catch (error) {
// 			dispatch(slice.actions.hasError(error))
// 		}
// 	}
// }

// ----------------------------------------------------------------------

// export function deleteColumn(columnId: string) {
// 	return async (dispatch: Dispatch) => {
// 		dispatch(slice.actions.startLoading())
// 		try {
// 			await axios.post('/api/kanban/columns/delete', { columnId })
// 			dispatch(slice.actions.deleteColumnSuccess({ columnId }))
// 		} catch (error) {
// 			dispatch(slice.actions.hasError(error))
// 		}
// 	}
// }

// ----------------------------------------------------------------------

// export function persistColumn(newColumnOrder: string[]) {
// 	return (dispatch: Dispatch) => {
// 		dispatch(slice.actions.persistColumn(newColumnOrder))
// 	}
// }

// ----------------------------------------------------------------------

interface PersistCardProps {
	customTokenId: string
	columns: Record<string, IModsColumn>
}
export function persistCard({ customTokenId, columns }:PersistCardProps) {
	return async (dispatch: Dispatch) => {
		const db = getFirestore()

		await Promise.all(Object.entries(columns).map(async ([id, column]) => {
			const columnDocRef = doc(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'columns', column.id)
 			await setDoc(columnDocRef, { modIds: column.modIds }, { merge: true })
		}))

		dispatch(slice.actions.persistCard(columns))
	}
}

// ----------------------------------------------------------------------

// export function addTask({ card, columnId }: { card: Partial<IKanbanCard>; columnId: string }) {
// 	return (dispatch: Dispatch) => {
// 		dispatch(slice.actions.addTask({ card, columnId }))
// 	}
// }

// ----------------------------------------------------------------------

// export function deleteTask({ cardId, columnId }: { cardId: string; columnId: string }) {
// 	return (dispatch: Dispatch) => {
// 		dispatch(slice.actions.deleteTask({ cardId, columnId }))
// 	}
// }

// ----------------------------------------------------------------------

interface DeleteModsProps {
	customTokenId: string
	modId: string
	columnId: string
}

export function deleteMod({ modId, customTokenId, columnId }:DeleteModsProps) {
	return async (dispatch: Dispatch) => {
		// dispatch(slice.actions.startSaving())
		const db = getFirestore()
		const values: Partial<IModCard> = {
			isDeleted: true,
			updatedAtMilliseconds: Timestamp.now().toMillis(),
		}

		const modDocRef = doc(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'mods', modId)
		const columnDocRef = doc(db, 'customizedTokens', customTokenId, 'customizations', 'board', 'columns', columnId)

		await Promise.all([
			setDoc(modDocRef, values, { merge: true }),
			updateDoc(columnDocRef, { modIds: arrayRemove(modId) }),
		])

		dispatch(slice.actions.deleteMod({ modId, columnId }))
	}
}

// ----------------------------------------------------------------------
interface GetModPromptsResponse {
	prompts: Array<IModPrompts>
}

export function getPrompts() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.promptsStartLoading())
		const functions = getFunctions()
		const { data } = await httpsCallable<{}, GetModPromptsResponse>(functions, 'customTokens-fns-getModPrompts')({})
		dispatch(slice.actions.getPromptsSuccess({ prompts: data.prompts }))
	}
}

// ----------------------------------------------------------------------

export function setPreviewModId(modId: string) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.setPreviewModId(modId))
	}
}

// ----------------------------------------------------------------------

export function clearPreviewModId() {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.clearPreviewModId())
	}
}
