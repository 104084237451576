import { createSlice, Dispatch } from '@reduxjs/toolkit'
import _ from 'lodash'
import { IViewableStory } from 'src/@types/storiesViewable'
import {
	collection, getDocs, getFirestore, query, where,
} from 'firebase/firestore'

// ----------------------------------------------------------------------
type TCustomTokenId = string

type TStoriesState = {
	isLoading: boolean
	stories: Array<IViewableStory>
	isError: Error | string | null
}

type TCustomTokenState = {
	// isLoadingStories: boolean
	// isErrorLoadingStories: Error | string | null
  stories: {
    byId: Record<TCustomTokenId, TStoriesState>
  }
};

const initialState: TCustomTokenState = {
	// isLoadingStories: false,
	// isErrorLoadingStories: null,
	stories: {
		byId: {},
	},
}

const slice = createSlice({
	name: 'custom-token',
	initialState,
	reducers: {

		// START LOADING STORIES
		startLoading(state, action) {
			const { customTokenId } = action.payload
			state.stories.byId[customTokenId] = {
				stories: [],
				isLoading: true,
				isError: null,
			}
		},

		// HAS ERROR
		hasError(state, action) {
			const { customTokenId, error } = action.payload
			state.stories.byId[customTokenId] = {
				...state.stories.byId[customTokenId],
				isLoading: false,
				isError: error,
			}
		},

		// GET POST FIRST PAGE
		getStoriesSuccess(state, action) {
			// console.log('action.payload=', action.payload)
			const { customTokenId, stories } = action.payload
			state.stories.byId[customTokenId] = {
				stories,
				isLoading: false,
				isError: null,
			}
		},

	},
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

interface GetStoriesProps {
	customTokenId: string
}

export function getStories({ customTokenId }:GetStoriesProps) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading({ customTokenId }))
		try {
			// console.log('getStories')
			const db = getFirestore()
			const storiesCollectionRef = collection(db, 'stories_feed')
			const storiesQuerySnapshot = await getDocs(query(storiesCollectionRef, where('isDeleted', '==', false), where('isPublished', '==', true), where('customToken.id', '==', customTokenId)))
			const stories: Array<IViewableStory> = storiesQuerySnapshot.docs.map((doc):IViewableStory => {
				const story : IViewableStory = {
					id: doc.id,
					...doc.data() as Omit<IViewableStory, 'id'>,
				}
				return story
			})

			// console.log(stories)
			dispatch(slice.actions.getStoriesSuccess({ customTokenId, stories }))
		} catch (error) {
			console.log(error)
			dispatch(slice.actions.hasError({ customTokenId, error }))
		}
	}
}
