/* eslint-disable import/no-duplicates */
import React, { useCallback } from 'react'
import {
	createContext, ReactNode, useEffect, useReducer, useState, useMemo,
} from 'react'

import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import {
	addDoc,
	collection,
	deleteDoc,
	doc, DocumentData, getDoc, getFirestore, onSnapshot, serverTimestamp, setDoc,
} from 'firebase/firestore'
import {
	getAuth,
	signOut,
	signInWithPopup,
	UserCredential,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	GoogleAuthProvider,
	TwitterAuthProvider,
	FacebookAuthProvider,
	OAuthProvider,
	sendPasswordResetEmail,
	verifyPasswordResetCode,
	confirmPasswordReset,
	deleteUser,
	signInWithCredential,
	getAdditionalUserInfo,
	AdditionalUserInfo,
	OAuthCredential,
	AuthCredential,
	ProviderId,
	signInWithCustomToken,
	User,
} from 'firebase/auth'

// eslint-disable-next-line import/no-unresolved
// import { FirebaseApp, initializeApp } from 'firebase/app'
import axios from 'axios'
import { isEqual, isNil } from 'lodash'
import useMixpanel, { MixpanelEvents } from 'src/hooks/useMixpanel'
import { logger } from 'src/services/logger'
// import user from 'src/redux/slices/user'
import useInterval from 'src/hooks/utils/useInterval'
import { IUser } from 'src/types/userInfo'
import { toAuthUser } from 'src/utils/users'
// import { TGender } from 'src/types/gender'
import useNativeBridge from 'src/hooks/useNativeBridge'
import usePrevious from 'src/hooks/usePrevious'
// import { setLoggedIn } from 'src/redux/slices/user'
// import { useSelector } from 'src/redux/store'
import useLocalStorage from 'src/hooks/useLocalStorage'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
	ActionMap, AuthState, AuthUser, FirebaseContextType, NonceResponse, VerifyResponse,
} from '../@types/auth'
//
// import useWeb3 from '../hooks/useWeb3'
import { getApiUrl } from '../utils/firebase'
// import { INftLight, IUserNft } from '../types/tokens'
import activateFirebaseEmulator, { getFirebase } from '../services/firebase'
// ----------------------------------------------------------------------

// type TAuthBridgeEventMessage = { type: string; credentials: UserCredential }
type TAuthBridgeEventMessage = { type: string; rawNonce?: string; providerId: string; idToken: string/* ; accessToken: string */ }
interface IAllUserData {
	extendedData?: object
}

// const ADMIN_EMAILS = ['demo@minimals.cc']

const GET_NONCE_URL = getApiUrl('auth-api-getNonceToSign')
const VERIFY_URL = getApiUrl('auth-api-verifySignedMessage')

const initialState: AuthState = {
	isAuthenticated: false,
	isInitialized: false,
	// user: null,
}

enum Types {
  Initial = 'INITIALISE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean
    // user: AuthUser
  }
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

// const reducer = (state: AuthState, action: FirebaseActions) => {
// 	if (action.type === 'INITIALISE') {
// 		const { isAuthenticated, user } = action.payload
// 		return {
// 			...state,
// 			isAuthenticated,
// 			isInitialized: true,
// 			user,
// 		}
// 	}

// 	return state
// }

const reducer = (state: AuthState, action: FirebaseActions) => {
	if (action.type === 'INITIALISE') {
		const { isAuthenticated } = action.payload
		return {
			...state,
			isAuthenticated,
			isInitialized: true,
		}
	}

	return state
}

const AuthContext = createContext<FirebaseContextType | null>(null)
// const AuthContext = createContext<FirebaseContextType>({ ...initialState })

function AuthProvider({ children }: { children: ReactNode }) {
	// const {
	// 	signMessage, account, active, activate, deactivate, error,
	// } = useWeb3()

	// const [isLoggedIn, setIsLoggedIn] = useLocalStorage<string|null>('auth-isLoggedIn', null)
	const { mixpanel } = useMixpanel()
	const [profile, setProfile] = useState<IUser | undefined>(undefined)
	const [state, dispatch] = useReducer(reducer, initialState)

	const [authError, setAuthError] = useState<any | null>(null)
	const [authStatus, setAuthStatus] = useState<string | null>(null)
	const [nativeUserCredentials, setNativeUserCredentials] = useState<TAuthBridgeEventMessage | null>(null)
	const [hearbeatCount, setHeartbeatCount] = useState<number>(0)
	const [tempData, setTempData] = useState<{gender:string;firstName:string} | null>(null)
	// const { isLoggedIn } = useSelector((state) => state.user)

	const previousIsAuthenticatedState:boolean = usePrevious<boolean>(state.isAuthenticated)

	getFirebase()
	activateFirebaseEmulator()

	const AUTH = getAuth()

	// const previousCurrentUserUid = usePrevious(AUTH?.currentUser?.uid)

	const { requestLogout } = useNativeBridge()
	// const GITHUB_PROVIDER = new GithubAuthProvider()

	// const TWITTER_PROVIDER = new TwitterAuthProvider()

	const sendHeartbeat = useCallback(async ():Promise<void> => {
		if (!!AUTH.currentUser && !!AUTH.currentUser?.uid && !!profile) {
			logger.log('sending heartbeat')
			setHeartbeatCount((c) => c + 1)
			const db = getFirestore()
			const ref = doc(db, 'users', AUTH.currentUser.uid)
			await setDoc(ref, {
				lastSeen: serverTimestamp(),
			}, { merge: true })
		}
	}, [AUTH.currentUser, profile])

	// useInterval(() => {
	// 	sendHeartbeat()
	//  }, AUTH.currentUser ? 1000 * 60 : null) // every 1 min

	useInterval(() => {
		sendHeartbeat()
	 }, 1000 * 60) // every 1 min

	const createUserRecord = useCallback(async (user: User, firstName: string, gender: string):Promise<string> => {
		const { providerId, uid, email } = user
		const db = getFirestore()
		const ref = doc(db, 'users', uid)

		await setDoc(ref, {
			...(email && {
				email,
			}),
			firstName,
			gender,
			lastSeen: serverTimestamp(),
			mixpanelDistinctId: mixpanel.get_distinct_id(),
		})

		mixpanel.people.set({
			$name: `${firstName}`,
			$email: email ?? '',
			registeredOn: (new Date()).toISOString(),
			// isAnonymous,
			uid,
			providerId,
		})

		mixpanel.alias(uid)

		return uid
	}, [mixpanel])

	// useEffect(() => {
	// 	const doLogout = async () => {
	// 		const { ethereum } = window as any
	// 		if (ethereum && ethereum.on && !active && !error) {
	// 			/*
	// 			const handleConnect = () => {
	// 				console.log('Handling \'connect\' event')
	// 			// activate(injected)
	// 			} */
	// 			/* 	const handleChainChanged = (chainId: string | number) => {
	// 				console.log('Handling \'chainChanged\' event with payload', chainId)
	// 			// activate(injected)
	// 			} */

	// 			const handleAccountsChanged = async (accounts: string[]) => {
	// 				console.log('Handling \'accountsChanged\' event with payload', accounts)
	// 				// console.log(`auth.currentUser?.uid=${auth.currentUser?.uid.toLowerCase()}`)
	// 				// console.log(`accounts?.[0]=${accounts?.[0].toLowerCase()}`)
	// 				// if (!isEqual(AUTH.currentUser?.uid.toLowerCase(), accounts?.[0].toLowerCase())) {
	// 				// 	console.error('There seems to be a Wallet issue so logging the user out of Main Authentication System as a precaution,')
	// 				// 	await signOut(AUTH)
	// 				// }
	// 			}
	// 			/*
	// 			const handleNetworkChanged = (networkId: string | number) => {
	// 				console.log('Handling \'networkChanged\' event with payload', networkId)
	// 			// activate(injected)
	// 			} */

	// 			// ethereum.on('connect', handleConnect)
	// 			// ethereum.on('chainChanged', handleChainChanged)
	// 			ethereum.on('accountsChanged', handleAccountsChanged)
	// 			// ethereum.on('networkChanged', handleNetworkChanged)

	// 			return () => {
	// 				if (ethereum.removeListener) {
	// 					// ethereum.removeListener('connect', handleConnect)
	// 					// ethereum.removeListener('chainChanged', handleChainChanged)
	// 					ethereum.removeListener('accountsChanged', handleAccountsChanged)
	// 					// ethereum.removeListener('networkChanged', handleNetworkChanged)
	// 				}
	// 			}
	// 		}
	// 		return () => {}
	// 	}

	// 	doLogout()
	// }, [active, activate, error, AUTH])

	useEffect(() => {
		if (!!AUTH.currentUser && !!profile && (hearbeatCount === 0)) {
			sendHeartbeat()
		}
	}, [AUTH.currentUser, hearbeatCount, profile, sendHeartbeat])

	useEffect(() => {
		if (!state.isAuthenticated && !!previousIsAuthenticatedState) {
			requestLogout()
		}
	}, [state.isAuthenticated, previousIsAuthenticatedState, requestLogout])

	/*  useEffect(() => {
		const doLogout = async () => {
			console.log(`account=${account}`)
			console.log(`active=${active}`)
			await activate()
			if (isNil(account)) {
				console.error('There seems to be no Wallet connected so logging the user out of Main Authentication System as a precaution,')
				deactivate()
				await signOut(auth)
			}
		}
		doLogout()
	}, [account, active, auth, activate, deactivate]) */

	useEffect(
		() => AUTH.onAuthStateChanged(async (user) => {
			logger.log('AUTH.onAuthStateChanged, ', user)
			// console.log('user=', user)
			if (user) {
				dispatch({
					type: Types.Initial,
					payload: {
						isAuthenticated: true,
					},
				})

				// 	.then((doc) => {
				// dispatch(setLoggedIn(true))
				// const idToken: string = await user.getIdToken()

				// console.log('idToken=', idToken)
				// const docRef = doc(db, 'users', user.uid)
				// const userProfile: IUser | undefined = await getDoc(docRef)
				// 	.then((doc) => {
				// 		if (doc.exists()) {
				// 			const userProfile:IUser = {
				// 				uid: doc.id,
				// 				...doc.data() as Omit<IUser, 'uid'>,
				// 			}

				// 			setProfile(userProfile)
				// 			return userProfile
				// 		}
				// 		return undefined
				// 	})
				// 	.catch((error) => {
				// 		logger.error(error)
				// 		return undefined
				// 	})

				// if (userProfile) {
				// 	dispatch({
				// 		type: Types.Initial,
				// 		payload: {
				// 			isAuthenticated: true,
				// 			user: toAuthUser(user, userProfile),
				// 		},
				// 	})
				// }
			} else {
				dispatch({
					type: Types.Initial,
					payload: { isAuthenticated: false },
				})
			}
		}),
		[AUTH, dispatch],
	)

	useEffect(() => {
		if (!!AUTH.currentUser && !!state.isAuthenticated && !profile) {
			logger.log('starting listener for user changes=', AUTH.currentUser.uid)
			const db = getFirestore()
			const docRef = doc(db, 'users', AUTH.currentUser.uid)
			const unsubscribe = onSnapshot(docRef, (doc) => {
				if (doc.exists()) {
					// logger.log('listener, got new user info', JSON.stringify(doc.data()))
					setProfile({
						uid: doc.id,
						...doc.data() as Omit<IUser, 'uid'>,
					})
				}
			})

			getDoc(docRef).then((doc) => {
				logger.log('here')
				if (!doc.exists() && !!AUTH.currentUser) {
					// logger.log('yup')
					if (tempData) {
						// logger.log('for sure')
						createUserRecord(AUTH.currentUser, tempData.firstName, tempData.gender)
					}
				}
			})

			// return (() => unsubscribe())
		}
		// return (() => {})
	}, [AUTH.currentUser, createUserRecord, profile, state.isAuthenticated, tempData])

	// useEffect(() => {
	// 	if (AUTH.currentUser) {
	// 		setIsLoggedIn('true')
	// 	}
	// }, [AUTH.currentUser, setIsLoggedIn])

	/* 	useEffect(() => {
		if (auth.currentUser) {
			// const collectionRef = collection(db, 'users', auth.currentUser.uid, 'customizedTokens')
			const collectionRef = collection(db, 'customizedTokens')
			const q = query(
				collectionRef,
				where('uid', '==', auth.currentUser.uid),
			)
			const unsubscribe = onSnapshot(q, (results) => {
				setTokens(results.docs.map((d) => ({
					...d.data() as INftLight,
					id: d.id,
				} as IUserNft)))
			})
			return (() => {
				unsubscribe()
			})
		}
		return (() => {})
	}, [auth.currentUser, db]) */

	// const login = useCallback(async (email: string, password: string):Promise<UserCredential> => signInWithEmailAndPassword(auth, email, password), [auth])
	const login = useCallback(async (email: string, password: string):Promise<void> => {
		try {
			setAuthError(null)
			await signInWithEmailAndPassword(AUTH, email, password)
		} catch (err) {
			logger.error(err)
			setAuthError(err)
		}
	}, [AUTH])

	// const loginAnonymously = useCallback(async ():Promise<UserCredential> => signInAnonymously(AUTH), [AUTH])

	/* 	const loginWithWallet = useCallback(async (address: string):Promise<TLoginResult> => {
		try {
			console.log('fn loginWithWallet')
			if (isNil(address)) {
				throw new Error('No Wallet Address Provided')
			}

			const { data: nonceResponse } = await axios.post<NonceResponse>(
				GET_NONCE_URL,
				{
					address,
				},
			)

			// const isWhitelisted = nonceResponse?.status === 'WHITELISTED'

			// if (!isWhitelisted) {
			// 	return { userCredentials: undefined, isWhitelisted: false }
			// }

			// const sig = await library?.provider.getSigner(address).signMessage(nonceResponse.nonce)
			const sig = await signMessage(address, nonceResponse.nonce)

			const { data: verifiedResponse } = await axios.post<VerifyResponse>(
				VERIFY_URL,
				{ address, signature: sig },
			)

			const userCredentials = await signInWithCustomToken(AUTH, verifiedResponse.token)
			// return { userCredentials, isWhitelisted }
			return { userCredentials } as TLoginResult
		} catch (err) {
			console.log(`error in my belly = ${err}`)
			return { userCredentials: undefined }
		}
	}, [AUTH, signMessage]) */

	// const connectWallet = useCallback(async (address: string):Promise<string | undefined> => {
	// 	try {
	// 		if (isNil(address)) {
	// 			throw new Error('No Wallet Address Provided')
	// 		}

	// 		// console.log(config)

	// 		const jwt = await AUTH.currentUser?.getIdToken()
	// 		axios.defaults.headers.common = { Authorization: `Bearer ${jwt}` }
	// 		const { data: nonceResponse } = await axios.post<NonceResponse>(
	// 			GET_NONCE_URL,
	// 			{
	// 				address,
	// 			},
	// 		)

	// 		const sig = await signMessage(address, nonceResponse.nonce)

	// 		const { data } = await axios.post<VerifyResponse>(
	// 			VERIFY_URL,
	// 			{ address, signature: sig },
	// 		)

	// 		// console.log(data)
	// 		return data?.walletId
	// 	} catch (err) {
	// 		logger.error(err)
	// 	}

	// 	return undefined
	// }, [AUTH.currentUser, signMessage])

	const logout = useCallback(async () => {
		// console.log('signOut')
		await signOut(AUTH)
	}, [AUTH])

	// useEffect(() => {
	// 	logger.log(`AUTH?.currentUser?.uid=${AUTH?.currentUser?.uid}`)
	// 	logger.log(`previousCurrentUser=${previousCurrentUserUid}`)
	// 	// this condition means that redux indicates the user was previously logged in, but somehow now they are not logged in.
	// 	// const triggerCondition1:boolean = isLoggedIn && !AUTH?.currentUser?.uid
	// 	// const triggerCondition2:boolean = isLoggedIn && !AUTH?.currentUser?.uid
	// 	if (!AUTH?.currentUser?.uid && !!previousCurrentUserUid) {
	// 		console.log('Logout occured')
	// 		// so we send a message to native app
	// 		requestLogout()
	// 	}
	// }, [AUTH?.currentUser?.uid, previousCurrentUserUid, requestLogout])

	const deleteUserAccount = useCallback(async ():Promise<void> => {
		try {
			if (AUTH.currentUser) {
				const { uid } = AUTH.currentUser

				try {
					const functions = getFunctions()
					const fn = httpsCallable(functions, 'auth-fns-deleteAccount')
					await fn()
				} catch (error) {
					console.error(error)
				}

				mixpanel.track(MixpanelEvents.UserDeleted, {
					uid,
				})

				mixpanel.people.set({
					isDeleted: true,
				})

				// await deleteUser(AUTH.currentUser)
			}
		} catch (err) {
			logger.error(err)
		}
	}, [AUTH.currentUser, mixpanel])

	// -----------------------------------------------------

	/* interface CopyUserDataProps {
	dataSource: IAllUserData
	toUid: string
}

const copyUserData = useCallback(async (props: CopyUserDataProps): Promise<void> => {
	const { dataSource, toUid } = props

	await Promise.all([
		dataSource?.extendedData ? setDoc(doc(db, 'users', toUid, 'extended_user_collection', 'extended_user_data'), dataSource.extendedData, { merge: true }) : Promise.resolve(),
	])
}, [db]) */

	// --------------------------------------------------------

	/* const getAllUserData = useCallback(async (uid: string): Promise<IAllUserData> => {
	const docSnapExtendedUserDataIn = await getDoc(doc(db, 'users', uid, 'extended_user_collection', 'extended_user_data'))
	// console.log('docSnapExtendedUserDataIn=', docSnapExtendedUserDataIn)
	return {
		extendedData: docSnapExtendedUserDataIn.exists() ? docSnapExtendedUserDataIn.data() : undefined,
	}
}, [db])

// --------------------------------------------------------

const deleteAllUserData = useCallback(async (uid: string): Promise<void> => {
	const docRefExtendedUserData = doc(db, 'users', uid, 'extended_user_collection', 'extended_user_data')
	const docSnapExtendedUserData = await getDoc(docRefExtendedUserData)
	await Promise.all([
		docSnapExtendedUserData.exists() ? deleteDoc(docRefExtendedUserData) : Promise.resolve(),
	])
}, [db]) */

	// --------------------------------------------------------

	// const loginWithSocialAndRegisterWithEmail = useCallback(async (loginStyle: 'fb' | 'twitter' | 'google') => {
	// 	try {
	// 		setAuthError(null)
	// 		// const prevUser: User | null = AUTH.currentUser
	// 		// let prevUserData: IAllUserData | null = null
	// 		let userCredentials:UserCredential | null = null
	// 		let newUid: string | null = null
	// 		let additionalUserInfo:AdditionalUserInfo | null = null

	// 		// if (!!prevUser?.uid && prevUser.isAnonymous) {
	// 		// 	prevUserData = await getAllUserData(prevUser.uid)
	// 		// 	await deleteAllUserData(prevUser.uid)
	// 		// 	await deleteUserAccount()
	// 		// }

	// 		if (loginStyle === 'google') {
	// 			userCredentials = await signInWithPopup(AUTH, new GoogleAuthProvider())
	// 		} else if (loginStyle === 'twitter') {
	// 			userCredentials = await signInWithPopup(AUTH, new TwitterAuthProvider())
	// 		} else if (loginStyle === 'fb') {
	// 			userCredentials = await signInWithPopup(AUTH, new FacebookAuthProvider())
	// 		}

	// 		userCredentials = userCredentials as UserCredential
	// 		newUid = await createUserRecord(userCredentials, 'blahblah', 'male')
	// 		additionalUserInfo = getAdditionalUserInfo(userCredentials)

	// 	// if (!!additionalUserInfo?.isNewUser && additionalUserInfo.isNewUser && !!prevUserData) {
	// 	// 	// logger.log('will now copy user')
	// 	// 	await copyUserData({ dataSource: prevUserData, toUid: newUid })
	// 	// }
	// 	} catch (err) {
	// 		logger.error(err)
	// 		setAuthError(err)
	// 	}
	// // TODO: reinstate the data if there was  afiled attempt to login, etc
	// // if (prevUser?uid){
	// // 	setAllUserData(prevUser.uid, prevUserData)
	// // }
	// // }
	// }, [AUTH, createUserRecord])

	// --------------------------------------------------------

	// const loginWithGoogle = useCallback(async () => {
	// 	mixpanel.track(MixpanelEvents.SocialAuthClicked, {
	// 		providerId: 'google.com',
	// 	})
	// 	loginWithSocialAndRegisterWithEmail('google')

	// // if (AUTH.currentUser) {
	// // 	const provider = new GoogleAuthProvider()
	// // 	const userCredentials = await linkWithPopup(AUTH.currentUser, provider)
	// // 	if (userCredentials.user.)
	// // 	await createUserRecord(userCredentials)
	// // } else {
	// // 	logger.warn('no current user, so using regular auth path')
	// // 	await loginWithGoogleWithoutLink()
	// // }
	// }, [loginWithSocialAndRegisterWithEmail, mixpanel])

	// // const loginWithTwitterWithoutLink = useCallback(async () => {
	// // 	const userCredentials:UserCredential = await signInWithPopup(AUTH, new TwitterAuthProvider())
	// // 	await createUserRecord(userCredentials)
	// // }, [AUTH, createUserRecord])

	// const loginWithTwitter = useCallback(async () => {
	// 	mixpanel.track(MixpanelEvents.SocialAuthClicked, {
	// 		provider: 'Twitter',
	// 	})
	// 	await loginWithSocialAndRegisterWithEmail('twitter')
	// }, [loginWithSocialAndRegisterWithEmail, mixpanel])

	// // const loginWithFaceBookWithoutLink = useCallback(async () => {
	// // 	const userCredentials:UserCredential = await signInWithPopup(AUTH, new FacebookAuthProvider())
	// // 	await createUserRecord(userCredentials)
	// // }, [AUTH, createUserRecord])

	// const loginWithFaceBook = useCallback(async () => {
	// 	mixpanel.track(MixpanelEvents.SocialAuthClicked, {
	// 		provider: 'Facebook',
	// 	})
	// 	await loginWithSocialAndRegisterWithEmail('fb')
	// // if (AUTH.currentUser) {
	// // 	const provider = new FacebookAuthProvider()
	// // 	const userCredentials = await linkWithPopup(AUTH.currentUser, provider)
	// // 	await createUserRecord(userCredentials)
	// // } else {
	// // 	logger.warn('no current user, so using regular auth path')
	// // 	await loginWithFaceBookWithoutLink()
	// // }
	// }, [loginWithSocialAndRegisterWithEmail, mixpanel])

	// const registerWithoutLink = useCallback(async (email: string, password: string, name: string): Promise<void> => {
	// 	const userCredentials:UserCredential = await createUserWithEmailAndPassword(AUTH, email, password)
	// 	await createUserRecord(userCredentials, name)
	// }, [AUTH, createUserRecord])

	// new flow due to use of anonymous auth
	// const register = useCallback(async (email: string, password: string, name: string): Promise<void> => {
	// 	if (AUTH.currentUser) {
	// 		const credential = EmailAuthProvider.credential(email, password)
	// 		const userCredentials = await linkWithCredential(AUTH.currentUser, credential)
	// 		await createUserRecord(userCredentials, name)
	// 	} else {
	// 		logger.warn('No User found in Memory, so taking regulat registration path')
	// 		await registerWithoutLink(email, password, name)
	// 	}
	// }, [AUTH.currentUser, createUserRecord, registerWithoutLink])

	const register = useCallback(async (email: string, password: string, firstName: string, gender:string): Promise<void> => {
		try {
			setAuthError(null)
			await createUserWithEmailAndPassword(AUTH, email, password)
			setTempData({ gender, firstName })
		} catch (err) {
			logger.error(err)
			setAuthError(err)
		}
	}, [AUTH])

	const resetPassword = useCallback(async (email: string) => {
		await sendPasswordResetEmail(AUTH, email)
	}, [AUTH])

	const confirmNewPassword = useCallback(async (email: string, code: string, newPassword:string):Promise<void> => {
		await confirmPasswordReset(AUTH, code, newPassword)
	}, [AUTH])

	const verifyUsersPasswordResetCode = useCallback(async (actionCode:string):Promise<string> => {
		const email = await verifyPasswordResetCode(AUTH, actionCode)
		return email
	}, [AUTH])

	// --------------------------------------------------------

	useEffect(() => {
		if (!!nativeUserCredentials && !!AUTH && !AUTH.currentUser) {
			try {
				const {
					providerId, rawNonce, idToken,
				} = nativeUserCredentials

				if (['apple.com', ProviderId.GOOGLE].includes(providerId)) {
					let credential: AuthCredential | null = null
					if (providerId === 'apple.com') {
						const provider = new OAuthProvider(providerId)
						setAuthStatus((s) => `${s}, apple provider, rawNonce=${rawNonce}`)
						setAuthStatus((s) => `${s}, apple provider, idToken=${idToken}`)
						credential = provider.credential({
							idToken,
							rawNonce,
						})
					} else if (providerId === ProviderId.GOOGLE) {
						setAuthStatus((s) => `${s}, google provider, idToken=${idToken}`)
						credential = GoogleAuthProvider.credential(idToken)
					}

					if (credential) {
						signInWithCredential(AUTH, credential).then(() => {
							setAuthStatus((s) => `${s}, signed in!!`)
						}).catch((err) => {
							setAuthError(JSON.stringify(err))
							logger.error(err)
						})
					}
				} else if (['custom'].includes(providerId)) {
					setAuthStatus((s) => `${s}, custom token provider, idToken=${idToken}`)
					signInWithCustomToken(AUTH, idToken).then(() => {
						setAuthStatus((s) => `${s}, signed in!!`)
					}).catch((err) => {
						setAuthError(JSON.stringify(err))
						logger.error(err)
					})
				}
			} catch (err) {
				setAuthError(JSON.stringify(err))
				logger.error(err)
			}
		}
	}, [AUTH, nativeUserCredentials])

	// --------------------------------------------------------

	 useEffect(() => {
		setAuthStatus('setting native auth listener...')
		const handler = async (ev: any) => {
			// logger.log('GOT MESSAGE in Auth Bridge')
			setAuthStatus((s) => `${s}, authBridgeMessage=${ev?.data}`)

			// we can only receive a string from ReactNative postMessage
			if (typeof ev?.data !== 'string') return

			try {
				const obj:TAuthBridgeEventMessage = JSON.parse(ev?.data)
				const {
					type, providerId, rawNonce, idToken,
				} = obj

				if (type === 'user') {
					if ((providerId === 'apple.com') && !!idToken && !!rawNonce) {
						setAuthStatus((s) => `${s}, got all legit apple credentials`)
						setNativeUserCredentials(obj)
					} else if ((providerId === ProviderId.GOOGLE) && !!idToken) {
						setAuthStatus((s) => `${s}, got all legit google credentials`)
						setNativeUserCredentials(obj)
					} else if ((providerId === 'custom') && !!idToken) {
						setAuthStatus((s) => `${s}, got all legit custom token credentials`)
						setNativeUserCredentials(obj)
					}
				}
			} catch (err) {
				setAuthError(err)
				// logger.error(err)
			}
		}

		window.addEventListener('message', handler)

		if (window?.ReactNativeWebView) {
			try {
				const message = 'postMessage to RN WebView with "isReady"'
				logger.log(message)
				setAuthStatus((s) => `${s}, ${message}`)
				window.ReactNativeWebView.postMessage(JSON.stringify({
					type: 'cmd',
					message: 'appIsReady',
				}))
			} catch (err) {
				setAuthError(err)
			}
		}

		// Don't forget to remove addEventListener
		// return () => {
		// 	logger.log('removing native auth listener')
		// 	window.removeEventListener('message', handler)
		// }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { currentUser } = AUTH

	const user:AuthUser = useMemo(() => (!!currentUser && !!profile ? {
		uid: currentUser.uid,
		email: profile?.email,
		isAnonymous: currentUser.isAnonymous,
		avatarUrl: profile?.avatarUrl,
		firstName: profile.firstName,
		lastName: profile?.lastName || '',
		// role: ADMIN_EMAILS.includes(email ?? '') ? 'admin' : 'user',
		// phoneNumber: currentUser?.phoneNumber || profile?.phoneNumber || '',
		handle: profile.handle,
		handleLowerCase: profile.handleLowerCase,
		notifications: profile?.notifications,
		// birthday: profile?.birthday || null,
		birthday: null,
		gender: profile.gender,
		isAdmin: profile?.isAdmin ?? false,
		// country: country || '',
		// address: address || '',
		// state: userLocationState || '',
		// city: city || '',
		// zipCode: zipCode || '',
		about: profile?.about || null,
	} : null), [currentUser, profile])

	const value = useMemo(() => ({
		...state,
		method: 'firebase',
		user,
		login,
		// connectWallet,
		register,
		// loginWithGoogle,
		// loginWithFaceBook,
		// loginWithTwitter,
		logout,
		resetPassword,
		confirmNewPassword,
		verifyUsersPasswordResetCode,
		deleteUserAccount,
		updateProfile: () => {},
		// tokens,
		// walletAccount: account,
		authError,
		authStatus,
		setAuthError,
	// loginAnonymously,
	} as FirebaseContextType), [state, user, login, register, logout, resetPassword, confirmNewPassword, verifyUsersPasswordResetCode, deleteUserAccount, authError, authStatus])

	return (
		<AuthContext.Provider
			value={value}
		>
			{children}
		</AuthContext.Provider>
	)
}

export { AuthContext, AuthProvider }
